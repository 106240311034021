import React, { useEffect, useState } from 'react';
import * as Styled from 'src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardPatient/components/OxStaffDashboardPatientTreatmentNotes/OxStaffDashboardPatientTreatmentNotes.styled';
import { TAppointmentSummary, TTreatmentGoal, TTreatmentNote } from 'src/services/api/api.types';
import { OxLink } from 'src/components/OxLink';
import { ERoutes } from 'src/config/enums';
import { DateTime } from 'luxon';
import { getTreatmentButtonMessage } from 'src/utils/treatmentNoteUtils';
import { useStore } from 'react-redux';
import { numberSuffix } from 'src/utils/numberSuffix';
import { getTreatmentAppointmentSummary, getTreatmentGoals } from 'src/services/api/treatment';
import { useValidateResponse } from 'src/hooks/useValidateResponse';

type TProps = {
    treatmentNotes: TTreatmentNote[];
    clinicId: string;
};

export const OxStaffDashboardPatientTreatmentNotes = ({
    treatmentNotes,
    clinicId
}: TProps): JSX.Element => {
    const { validateResponse } = useValidateResponse();
    const store = useStore();
    const state = store.getState();
    const { roles } = state.user;

    const [appointmentSummaries, setAppointmentSummaries] = useState<
        (TAppointmentSummary & { id: string })[]
    >([]);

    const [treatmentGoals, setTreatmentGoals] = useState<TTreatmentGoal[]>([]);

    useEffect(() => {
        let unmounted = false;

        if (treatmentNotes.length > 0) {
            treatmentNotes?.forEach((treatmentNote) => {
                getTreatmentAppointmentSummary({
                    clinicId,
                    appointmentId: treatmentNote.appointmentId
                })
                    .then((data) => validateResponse(data))
                    .then(
                        (data) =>
                            !unmounted &&
                            setAppointmentSummaries((prev) => {
                                prev.push(Object.assign(data, { id: treatmentNote.appointmentId }));
                                return [...prev];
                            })
                    )
                    .catch(({ error }) => console.error(error.message));
            });
        }

        getTreatmentGoals()
            .then((data) => validateResponse(data))
            .then((data) => !unmounted && setTreatmentGoals(data))
            .catch((e) => console.error(e.message));

        return (): void => {
            unmounted = true;
        };
    }, [treatmentNotes]);
    return (
        <Styled.Notes>
            <Styled.Title>Treatment Notes</Styled.Title>
            {treatmentNotes?.length > 0 ? (
                <Styled.Table>
                    <thead>
                        <tr>
                            <Styled.THeadCell align="left">Doctor Name</Styled.THeadCell>
                            <Styled.THeadCell>Appointment Date/time</Styled.THeadCell>
                            <Styled.THeadCell>Goal</Styled.THeadCell>
                            <Styled.THeadCell>Outcome</Styled.THeadCell>
                            <Styled.THeadCell />
                        </tr>
                    </thead>
                    <tbody>
                        {treatmentNotes.map((treatmentNote) => {
                            const { practitionerName, appointmentTime, goal } =
                                appointmentSummaries.find(
                                    (summary) => summary.id === treatmentNote.appointmentId
                                ) ?? ({} as TAppointmentSummary);
                            const appointmentTimeObj = DateTime.fromISO(
                                appointmentTime ?? ''
                            ).setZone(state?.user?.clinic?.timezone ?? 'Europe/London');
                            return (
                                <tr key={treatmentNote.id}>
                                    <Styled.TCell align="left">{practitionerName}</Styled.TCell>
                                    <Styled.TCell>
                                        {appointmentTime
                                            ? appointmentTimeObj.toFormat(
                                                  `HH:mm - d'${numberSuffix(
                                                      appointmentTimeObj.day
                                                  )}' MMMM yyyy`
                                              )
                                            : ''}
                                    </Styled.TCell>

                                    <Styled.TCell>
                                        {goal?.title ??
                                            treatmentGoals.find((goal) =>
                                                goal.outcomes
                                                    ?.map((outcome) => outcome.id)
                                                    .includes(treatmentNote.outcome.id ?? 0)
                                            )?.title}
                                    </Styled.TCell>
                                    <Styled.TCell>{treatmentNote.outcome.title}</Styled.TCell>

                                    <Styled.TCell align="right">
                                        {!!treatmentNote.appointmentId && (
                                            <OxLink
                                                to={ERoutes.PanelStaffDashboardTreatmentNotes}
                                                state={{
                                                    appointmentId: treatmentNote.appointmentId,
                                                    clinicId
                                                }}
                                            >
                                                {getTreatmentButtonMessage({
                                                    roles: roles,
                                                    treatmentNote: treatmentNote,
                                                    displayId: true
                                                })}
                                            </OxLink>
                                        )}
                                    </Styled.TCell>
                                </tr>
                            );
                        })}
                    </tbody>
                </Styled.Table>
            ) : (
                <Styled.NoNotes>No Treatment Notes Created</Styled.NoNotes>
            )}
            {/*<Styled.PrintMessage>*/}
            {/*  NB. The Download/Print link will only appear when the invoice has been*/}
            {/*  correctly created i.e. it is associated with an appointment in Cliniko.*/}
            {/*</Styled.PrintMessage>*/}
        </Styled.Notes>
    );
};
