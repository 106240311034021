import * as Styled from './OxReferralSource.styled';

import { AlertContext, EAlertVariant } from 'src/context/AlertContext';
import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import React, { useContext, useState } from 'react';

import { OxButton } from 'src/components/OxButton';
import { OxSelect } from 'src/components/OxSelect';
import { postReferral } from 'src/services/api/reception';

type TRefreshmentsProps = {
    patientId: string;
    clinicId: number;
    onNext: () => void;
};

export const OxReferralSource = ({
    onNext,
    clinicId,
    patientId
}: TRefreshmentsProps): JSX.Element => {
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState({});
    const { showAlert } = useContext(AlertContext);

    // 'options.value' values need to exactly match the 'Internal Value' in HubSpot:
    // This unique identifier will be used when syncing with integration partners.
    // Once created it can't be edited.
    const options = [
        {
            label: 'Digital',
            value: 'Digital'
        },
        {
            label: 'Event',
            value: 'Event'
        },
        {
            label: 'External Partner',
            value: 'External Partner'
        },
        {
            label: 'Ouronyx Referral',
            value: 'Ouronyx Referral'
        },
        {
            label: 'Patient Referral',
            value: 'Patient Referral'
        },
        {
            label: 'PR & Influencers',
            value: 'PR & Influencers'
        }
    ];

    const onProceed = async (): Promise<void> => {
        setLoading(true);
        if (!selectedItem) {
            setLoading(false);
            return;
        }

        try {
            const result = await postReferral(
                { patientId: patientId, clinicId: clinicId },
                { source: selectedItem.value }
            );

            if (result.error) {
                showAlert({
                    type: EAlertVariant.Error,
                    header: 'Error',
                    title: 'Something went wrong'
                });
            } else {
                onNext();
            }
        } catch (err) {
            setLoading(false);
            showAlert({
                type: EAlertVariant.Error,
                header: 'Error',
                title: 'Something went wrong'
            });
        }
    };

    return (
        <Styled.ContentContainer>
            <Styled.Title>Referral Source</Styled.Title>
            <Styled.Info>Please let us know where you came from</Styled.Info>
            <OxThemedInput theme={EInputTheme.BackgroundWhite}>
                <Styled.Inputs>
                    <OxSelect
                        items={options}
                        label="Please Select"
                        title="Please Select"
                        labelName="label"
                        valueName="value"
                        required
                        onValueChange={setSelectedItem}
                    />

                    <OxThemedInput theme={EInputTheme.BackgroundGold}>
                        <OxButton
                            onClick={onProceed}
                            icon
                            loading={loading}
                            debounceClick
                            debounceClickDeps={[selectedItem, patientId, onNext]}
                        >
                            SUBMIT
                        </OxButton>
                    </OxThemedInput>
                </Styled.Inputs>
            </OxThemedInput>
        </Styled.ContentContainer>
    );
};
