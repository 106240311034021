import * as Styled from './OxGoalsSelect.styled';

import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import React, { useState } from 'react';

import { OxButton } from 'src/components/OxButton';
import { OxSelect } from 'src/components/OxSelect';

type TTGoalsSelectProps = {
    treatmentType: string;
    treatmentTypeChange: (value: string) => void;
    onNext: () => void;
};

export const OxGoalsSelect = ({
    treatmentType,
    treatmentTypeChange,
    onNext
}: TTGoalsSelectProps): JSX.Element => {
    const [errorMessage, setErrorMessage] = useState<string>('');

    // 'options.value' values need to exactly match the 'Internal Value' in HubSpot:
    // This unique identifier will be used when syncing with integration partners.
    // Once created it can't be edited.
    const options = [
        {
            label: 'Hair Treatment',
            value: 'Hair Treatment'
        },
        {
            label: 'Skin Treatment',
            value: 'Skin Treatment'
        }
    ];

    const handleOnChange = (option) => {
        if (typeof option !== 'undefined') {
            treatmentTypeChange(option.value);
        } else {
            treatmentTypeChange('');
        }
    };

    const onProceed = () => {
        setErrorMessage('');
        if (treatmentType) {
            onNext();
        } else {
            setErrorMessage('Please select a treatment type');
        }
    };

    return (
        <Styled.ContentContainer>
            <Styled.Title>Treatment Type</Styled.Title>
            <Styled.Info>Please select the type of treatment.</Styled.Info>
            <OxThemedInput theme={EInputTheme.BackgroundWhite}>
                <Styled.Inputs>
                    <OxSelect
                        items={options}
                        label="Please Select"
                        title="Please Select"
                        labelName="label"
                        valueName="value"
                        required
                        onValueChange={handleOnChange}
                    />

                    <OxThemedInput theme={EInputTheme.BackgroundGold}>
                        <OxButton onClick={onProceed} icon>
                            SUBMIT
                        </OxButton>
                        {errorMessage && <Styled.Error>{errorMessage}</Styled.Error>}
                    </OxThemedInput>
                </Styled.Inputs>
            </OxThemedInput>
        </Styled.ContentContainer>
    );
};
