import React, { useContext, useEffect, useState } from 'react';
import { EOxInputType, OxInput } from 'src/components/OxInput';
import * as Styled from './OxTreatmentNotesProductsUsedBarcodeInput.styled';
import { isBotox } from 'src/utils/treatmentNoteUtils';
import { TreatmentNoteContext } from 'src/context/TreatmentNoteContext';
import { OxAddRemoveButton } from 'src/components/OxAddRemoveButton';

type TProps = {
    inputKey: string;
    keys: string[];
    index: number;
    id: number;
    getExistingBatchesById: (
        productId: number
    ) => { id: number; barcode: string; batchCode: string; unit: string }[];
    onBatchBlur: (
        productId: number,
        index: number,
        barcode: string,
        setInvalid: React.Dispatch<React.SetStateAction<boolean>>
    ) => Promise<void>;
    batchLimit: number;
    addToBatch: (id: number) => void;
    removeBatch: (keyToRemove: string, productId: number, existingBatchId?: number) => void;
};

export const OxTreatmentNotesProductsUsedBarcodeInput = ({
    inputKey,
    keys,
    index,
    id,
    getExistingBatchesById,
    onBatchBlur,
    batchLimit,
    addToBatch,
    removeBatch
}: TProps): JSX.Element => {
    const { locked } = useContext(TreatmentNoteContext);
    const [invalid, setInvalid] = useState<boolean>(false);

    const sharedInputParams = {
        type: EOxInputType.Text,
        transformValue: (value: string): string => value.replace(/\s/g, ''),
        suppressCustomValidationMessage: true,
        required: true,
        isInvalid: invalid,
        disabled: locked
    };

    useEffect(() => {
        if (getExistingBatchesById(id)[index]?.barcode) {
            onBatchBlur(id, index, getExistingBatchesById(id)[index]?.barcode, setInvalid);
        }
    }, []);

    const barcodeVal = locked
        ? { value: getExistingBatchesById(id)[index]?.barcode }
        : { initialValue: getExistingBatchesById(id)[index]?.barcode };
    const batchVal = locked
        ? { value: getExistingBatchesById(id)[index]?.batchCode }
        : { initialValue: getExistingBatchesById(id)[index]?.batchCode };

    return (
        <Styled.BatchInputContainer isFirst={index === 0}>
            <OxInput
                type={EOxInputType.Hidden}
                name={`batches.${id}0${index + 1}.product`}
                value={id}
            />
            <Styled.BatchInput
                placeholder="Barcode"
                name={`batches.${id}0${index + 1}.barcode`}
                {...barcodeVal}
                onBlur={(e: Event): void => {
                    const target = e.target as HTMLInputElement;
                    setInvalid(false);
                    if (!target.value) return;
                    onBatchBlur(id, index, target.value, setInvalid);
                }}
                {...sharedInputParams}
            />
            <Styled.BatchInput
                placeholder="Batch"
                name={`batches.${id}0${index + 1}.batchCode`}
                {...batchVal}
                {...sharedInputParams}
            />
            {!locked && !isBotox(id) && (
                <>
                    {index === keys.length - 1 && keys.length < batchLimit ? (
                        <OxAddRemoveButton onClick={(): void => addToBatch(id)} />
                    ) : (
                        <OxAddRemoveButton
                            remove
                            onClick={(): void =>
                                removeBatch(inputKey, id, getExistingBatchesById(id)[index]?.id)
                            }
                        />
                    )}
                </>
            )}
        </Styled.BatchInputContainer>
    );
};
