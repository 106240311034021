import { EFontWeight, EFonts, ESizes } from 'src/config/enums';
import { fluidSizing, toVw } from 'src/utils';
import styled, { css } from 'styled-components';

import { OxLink } from 'src/components/OxLink';
import { createTextStyle } from 'src/utils/createTextStyle';

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        background-color: ${theme.colors.basic.lightOatmeal};
        ${fluidSizing([
            { prop: 'width', values: [null, 260, 320] },
            { prop: 'padding-right', values: [25, 25, 40] },
            { prop: 'padding-left', values: [25, 50, 75] },
            { prop: 'padding-bottom', values: [25, null] }
        ])}
        ${theme.breakpoints.only('xs')} {
            width: 100%;
        }
        ${theme.breakpoints.up('s')} {
        }

        @media print {
            display: none;
        }
    `
);

export const ContainerInner = styled.div(
    ({ theme }) => css`
        position: relative;
        ${fluidSizing([{ prop: 'padding-top', values: [20, 35, 40] }])}

        ${theme.breakpoints.up('s')} {
            position: sticky;
            ${fluidSizing([
                {
                    prop: 'top',
                    values: [null, ESizes.NavbarHeight, ESizes.NavbarHeight]
                }
            ])}
        }
    `
);

export const MenuWrapper = styled.ul(
    ({ theme }) => css`
        overflow: hidden;
        li {
            transition: opacity 400ms;
        }
        ${theme.breakpoints.only('xs')} {
            padding-top: ${toVw(12, 'xs')};
            &.collapsed {
                height: ${toVw(45, 'xs')};

                li:not(:first-of-type) {
                    opacity: 0;
                }
            }
        }
    `
);

export const Link = styled(OxLink)<{ subItem: boolean }>(
    ({ subItem, theme }) => css`
        color: ${theme.colors.primary.main};
        display: flex;
        ${!subItem &&
        css`
            ${createTextStyle(EFonts.Hatton, EFontWeight.Normal, [17, 16, 18], [21, 20, 22])};
        `}
        ${subItem &&
        css`
            ${createTextStyle(EFonts.Hatton, EFontWeight.Normal, [14, 14, 14], [21, 20, 22])};
            ${fluidSizing([{ prop: 'padding-left', values: [10, 10, 10] }])};
        `}

    ${fluidSizing([{ prop: 'margin-bottom', values: [22, 22, 22] }])};
        &.active {
            font-weight: ${EFontWeight.SemiBold};
        }
    `
);

export const ExpanderWrapper = styled.button(
    ({ theme }) => css`
        position: absolute;
        top: ${toVw(48 / 2, 'xs')};
        right: 0;
        min-height: ${toVw(35, 'xs')};
        transform: translateY(-50%);
        color: ${theme.colors.primary.main};
        text-transform: uppercase;
        ${createTextStyle(EFonts.Montserrat, EFontWeight.SemiBold, [10, 0, 0], [20, 0, 0])}
        ${theme.breakpoints.up('s')} {
            display: none;
        }
    `
);
