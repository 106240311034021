import * as StyledCommon from '../../OxStaffDashboardPsychologicalAttributes.styled';
import * as Styled from './OxStaffDashboardPsychologicalAttributesFears.styled';

import React from 'react';

type TProps = {
    fears: string[];
};

export const OxStaffDashboardPsychologicalAttributesFears = (props: TProps): JSX.Element => {
    return (
        <>
            {props.fears.length > 0 && (
                <Styled.ItemContainer>
                    <StyledCommon.Number>
                        <Styled.NumberInner total={props.fears.length}>
                            <svg
                                width="123"
                                height="123"
                                viewBox="0 0 123 123"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="61.5" cy="61.5" r="61.5" fill="#0B0600" />
                                <path
                                    d="M42 86.2C42.68 82.16 43.76 78.82 45.24 76.18C46.76 73.5 48.4 71.4 50.16 69.88C51.96 68.32 54.54 66.34 57.9 63.94C60.42 62.14 62.46 60.52 64.02 59.08C65.58 57.6 66.78 56 67.62 54.28C68.5 52.56 68.94 50.64 68.94 48.52C68.94 45.44 68.12 42.96 66.48 41.08C64.84 39.2 62.64 38.26 59.88 38.26C58.04 38.26 56.4 38.64 54.96 39.4C53.56 40.12 52.46 41.12 51.66 42.4C50.86 43.68 50.46 45.08 50.46 46.6C50.46 48.4 51 49.96 52.08 51.28C53.16 52.56 54.68 53.56 56.64 54.28L50.34 59.62C47.9 58.34 46.04 56.78 44.76 54.94C43.48 53.06 42.84 50.98 42.84 48.7C42.84 46.3 43.68 44.22 45.36 42.46C47.08 40.7 49.32 39.36 52.08 38.44C54.84 37.48 57.74 37 60.78 37C63.9 37 66.86 37.48 69.66 38.44C72.5 39.4 74.8 40.86 76.56 42.82C78.32 44.78 79.2 47.16 79.2 49.96C79.2 52.76 78.44 55.12 76.92 57.04C75.44 58.92 73.72 60.4 71.76 61.48C69.84 62.56 67.1 63.88 63.54 65.44C59.82 66.84 56.84 68.14 54.6 69.34C52.36 70.54 50.42 72.02 48.78 73.78C47.14 75.54 45.74 77.78 44.58 80.5L62.22 80.32C64.86 80.28 67.02 80.18 68.7 80.02C70.42 79.86 72.2 79.5 74.04 78.94C75.88 78.34 77.98 77.44 80.34 76.24L77.22 86.2H42Z"
                                    fill="white"
                                />
                            </svg>
                        </Styled.NumberInner>
                    </StyledCommon.Number>
                    <Styled.Header total={props.fears.length}>Fears</Styled.Header>
                    <StyledCommon.ItemContent>
                        <Styled.Fears total={props.fears.length}>
                            {props.fears.map((fear, index) => (
                                <Styled.Fear
                                    key={index}
                                    index={index + 1}
                                    total={props.fears.length}
                                >
                                    <Styled.FearText index={index + 1} total={props.fears.length}>
                                        {fear}
                                    </Styled.FearText>
                                </Styled.Fear>
                            ))}
                        </Styled.Fears>
                    </StyledCommon.ItemContent>
                </Styled.ItemContainer>
            )}
        </>
    );
};
