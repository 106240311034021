import * as Styled from './OxStaffDashboardRoot.styled';

import React, { PropsWithChildren, useEffect, useRef } from 'react';

import { OxStaffDashboardMenu } from '../OxStaffDashboardMenu';
import { WindowLocation } from '@reach/router';

type TProps = {
    noPadding?: boolean;
    location: WindowLocation | undefined;
    noPadding?: boolean;
};

export const OxStaffDashboardRoot = ({
    location,
    children,
    noPadding
}: PropsWithChildren<TProps>): JSX.Element => {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (containerRef.current) {
            window.scrollTo(0, containerRef.current.offsetTop - 100);
        }
    }, []);

    return (
        <Styled.Container ref={containerRef}>
            <OxStaffDashboardMenu location={location} />
            <Styled.DashboardContent noPadding={noPadding}>{children}</Styled.DashboardContent>
        </Styled.Container>
    );
};
