import { EColors, EFontWeight, EFonts } from 'src/config/enums';
import styled, { css } from 'styled-components';

import { OxLink } from 'src/components/OxLink';
import { createTextStyle } from 'src/utils/createTextStyle';
import { fluidSizing } from 'src/utils';

export const Container = styled.div`
    ${fluidSizing([
        { prop: 'padding-left ', values: [25, 50, 60] },
        { prop: 'padding-right ', values: [25, 50, 60] }
    ])}
`;

export const Heading = styled.h3`
    ${createTextStyle(EFonts.Hatton, EFontWeight.SemiBold, [22, 22, 22], [34, 34, 34])}
    ${fluidSizing([{ prop: 'margin-bottom', values: [20, 20, 20] }])}
`;

export const Filters = styled.div(
    ({ theme }) => css`
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid ${theme.colors.primary.main};
        color: ${theme.colors.primary.main};
        button,
        a {
            text-transform: uppercase;
        }
        ${fluidSizing([{ prop: 'padding-bottom', values: [20, 20, 20] }])};
    `
);

export const FilterItem = styled.button<{ active: boolean }>(
    ({ active }) => css`
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [12, 12, 12], [20, 20, 20])}
        ${active &&
        css`
            font-weight: ${EFontWeight.SemiBold};
        `}
    ${fluidSizing([{ prop: 'padding-right', values: [15, 15, 15] }])};
    `
);

export const FilterViewAll = styled(OxLink)`
    ${createTextStyle(EFonts.Montserrat, EFontWeight.SemiBold, [10, 10, 10], [20, 20, 20])}
`;

export const ChartContainer = styled.div`
    ${fluidSizing([{ prop: 'height', values: [250, 250, 250] }])}
    .recharts-cartesian-axis-line {
        stroke: ${EColors.OuronyxLogo};
    }
`;

export const TotalInfoBox = styled.div<{ variant: string }>(
    ({ variant, theme }) => css`
        position: relative;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            background-color: ${variant === 'earning'
                ? theme.colors.primary.main
                : theme.colors.basic.disabledTile};
            border-radius: 100%;
            ${fluidSizing([
                { prop: 'width', values: [30, 30, 30] },
                { prop: 'height', values: [30, 30, 30] },
                { prop: 'top', values: [-8, -8, -8] }
            ])};
        }
        span {
            ${fluidSizing([{ prop: 'padding-left', values: [40, 40, 40] }])};
            ${createTextStyle(EFonts.Montserrat, EFontWeight.SemiBold, [13, 13, 13], [16, 16, 16])}
        }
        ${fluidSizing([{ prop: 'margin-top', values: [25, 25, 25] }])};
    `
);

export const TickWrapper = styled.g`
    text {
        /* transform: translateX(-50%); */
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [13, 13, 13], [17, 17, 17])}
    }
    circle {
        fill: ${EColors.OuronyxLogo};
    }
`;
