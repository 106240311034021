import styled from 'styled-components';
import { fluidSizing } from 'src/utils';
import { createTextStyle } from 'src/utils/createTextStyle';
import { EFonts, EFontWeight } from 'src/config/enums';

export const Container = styled.div`
    ${fluidSizing([
        { prop: 'margin-bottom', values: [10, 20, 40] },
        { prop: 'margin-left', values: [10, 20, 40] },
        { prop: 'margin-right', values: [10, 20, 40] }
    ])}

    ${createTextStyle(EFonts.Montserrat, EFontWeight.Normal, [10, 10, 10], [12, 12, 12])}
`;
