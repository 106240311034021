import { EFontWeight, EFonts } from 'src/config/enums';
import { fluidSizing, toVw } from 'src/utils';
import styled, { css } from 'styled-components';

import { OxAnimatedWrapper } from 'src/components/OxAnimatedWrapper';
import { OxButton } from 'src/components/OxButton';
import { OxContainer } from 'src/components/OxContainer';
import { createTextStyle } from 'src/utils/createTextStyle';
import { transparentize } from 'polished';

export const Container = styled(OxContainer)(
    ({ theme }) => css`
        height: 100%;
        display: grid;
        ${theme.breakpoints.only('xs')} {
            padding-left: 0;
            padding-right: 0;
            grid-template:
                'StepHeader'
                'StepContent'
                'StepFooter';
            grid-template-rows: ${toVw(80, 'xs')} 1fr ${toVw(80, 'xs')};
        }
        ${theme.breakpoints.only('s')} {
            grid-template:
                'StepHeader StepContent'
                'StepSummary StepContent'
                'StepFooter StepContent';
            grid-template-rows: auto 1fr auto;
            grid-template-columns: 35% auto;
            grid-column-gap: 10%;
        }
        ${theme.breakpoints.up('m')} {
            grid-template:
                'StepHeader StepContent'
                'StepSummary StepContent'
                'StepFooter StepContent';
            grid-template-rows: auto 1fr auto;
            grid-template-columns: ${toVw(320, 'm')} ${toVw(440, 'm')};
            justify-content: space-between;

            @media (max-height: 800px) {
                padding-top: ${toVw(30, 'm')} !important;
                padding-bottom: ${toVw(30, 'm')} !important;
            }
        }
        ${fluidSizing([
            { prop: 'padding-top', values: [25, 60, 60, 50] },
            { prop: 'padding-bottom', values: [0, 60, 60, 50] }
        ])}
    `
);

export const Header = styled.div(
    ({ theme }) => css`
        grid-area: StepHeader;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        ${theme.breakpoints.only('xs')} {
            align-items: center;
            text-align: center;
        }
        & > *:first-child {
            color: ${theme.colors.primary.main};
        }
        ${theme.breakpoints.only('xs')} {
            padding-left: ${toVw(40, 'xs')};
            padding-right: ${toVw(40, 'xs')};
        }
    `
);

export const Summary = styled.div(
    ({ theme }) => css`
        grid-area: StepSummary;
        ${theme.breakpoints.only('xs')} {
            display: none;
        }
        ${theme.breakpoints.up('s')} {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }
    `
);

export const Footer = styled.div(
    ({ theme }) => css`
        grid-area: StepFooter;
        transition: 300ms;
        button {
            width: 100%;
        }

        ${theme.breakpoints.only('xs')} {
            display: flex;
            align-items: center;
            padding-left: ${toVw(28, 'xs')};
            padding-right: ${toVw(28, 'xs')};
            background-color: ${transparentize(0.5, theme.colors.basic.white)};
            border-top: 1px solid ${transparentize(0.8, theme.colors.basic.black)};
        }
    `
);

export const Content = styled.div`
    grid-area: StepContent;
    position: relative;
    & > div:nth-child(3) {
        overflow-y: auto;
    }
`;

export const TitleWrapper = styled.div`
    position: relative;
    width: 100%;
    p {
        ${createTextStyle(EFonts.Hatton, EFontWeight.Medium, [30, 35, 40], [35, 38, 46])}
    }
    ${fluidSizing([
        { prop: 'height', values: [38, 38, 38] },
        { prop: 'margin-top', values: [12, 12, 12] }
    ])}
`;

export const NextButton = styled(OxButton)<{ hide: boolean }>(
    ({ hide }) => css`
        position: relative;
        overflow: hidden;
        transition: 0.8s;
        ${hide &&
        css`
            visibility: hidden;
            opacity: 0;
        `}
    `
);

export const ButtonAnimation = styled(OxAnimatedWrapper)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    ${fluidSizing([
        { prop: 'padding-left', values: [15, 15, 15] },
        { prop: 'padding-right', values: [15, 15, 15] }
    ])};
`;
