import * as Styled from './OxStaffDashboardNoShow.styled';

import React, { useContext } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { OxStaffDashboardReturn } from 'src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardReturn';
import { ERoutes } from 'src/config/enums';
import { OxStaffDashboardPageSectionHeader } from 'src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardPageSectionHeader';
import { OxStaffDashboardRoot } from 'src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardRoot';
import { OxStaffDashboardContainer } from 'src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardContainer/OxStaffDashboardContainer';
import {
    EAppointmentsType,
    OxStaffDashboardAppointments
} from 'src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardAppointments';
import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import { OxForm } from 'src/components/OxForm';
import { appointmentNoShowCharge } from 'src/services/api/appointmentNoShow';
import { AlertContext, EAlertVariant } from 'src/context/AlertContext';
import { useValidateResponse } from 'src/hooks/useValidateResponse';
import { OxPageHelmet } from 'src/components/OxPageHelmet';

type TProps<RouteComponentProps> = RouteComponentProps & unknown;

export const OxStaffDashboardNoShow: React.FC<TProps<RouteComponentProps>> = ({ location }) => {
    const { validateResponse } = useValidateResponse();
    const { showAlert } = useContext(AlertContext);

    const onFormSubmit = async (data: FormData): Promise<void> => {
        const ids = data.getAll('not_arrived') as string[];

        const response = await appointmentNoShowCharge({ ids });
        try {
            validateResponse(response);
        } catch (e) {
            showAlert(e.error);
            showAlert({
                type: EAlertVariant.Error,
                header: 'Failure',
                message: e.error.message ?? 'Something went wrong when submitting no-show charges'
            });
            return;
        }

        showAlert({
            type: EAlertVariant.Success,
            header: 'Success',
            title: 'No-show appointments have been charged',
            hideAfterConfirm: true,
            onlyConfirm: true,
            onConfirm: async () => {
                await navigate(ERoutes.PanelStaffDashboard);
            }
        });
    };

    return (
        <OxStaffDashboardRoot location={location}>
            <OxPageHelmet title="No Show" />
            <OxStaffDashboardContainer>
                <OxStaffDashboardReturn to={ERoutes.PanelStaffDashboard} />
                <OxStaffDashboardPageSectionHeader removeBottomBorder={true}>
                    Staff No-show Payment Charge
                </OxStaffDashboardPageSectionHeader>
                <OxForm onFormSubmit={onFormSubmit}>
                    <OxStaffDashboardAppointments
                        type={EAppointmentsType.LOCATION}
                        showFilters={false}
                        noPreConsultations={true}
                        extrasElement={false}
                    />
                    <OxThemedInput theme={EInputTheme.BackgroundGold}>
                        <Styled.Button type="submit" icon>
                            Charge Payments For No-Shows
                        </Styled.Button>
                    </OxThemedInput>
                </OxForm>
            </OxStaffDashboardContainer>
        </OxStaffDashboardRoot>
    );
};
