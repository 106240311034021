import React, { useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './OxStaffDashboardPatientAppointments.styled';
import {
    TAppointment,
    TTreatmentAestheticianNote,
    TTreatmentNote
} from 'src/services/api/api.types';
import { getClinikoUrlByClinic } from 'src/services/api/api.core';
import { ERoles, ERoutes } from 'src/config/enums';
import { DateTime } from 'luxon';
import {
    getTreatmentButtonMessage,
    shouldHideAestheticianNotesButton,
    shouldHideTreatmentNotesButton
} from 'src/utils/treatmentNoteUtils';
import { useStore } from 'react-redux';
import { applyValues } from 'src/utils/applyValues';
import { getTreatmentAesthetician, getTreatmentNotes } from 'src/services/api/treatment';
import { OxLink } from 'src/components/OxLink';
import { useValidateResponse } from 'src/hooks/useValidateResponse';
import { TUserState } from 'src/panel/store/types';
import { sameDay } from 'src/utils/dateTime';
import { WebsiteDataContext } from 'src/context/WebsiteDataContext';

type TProps = {
    patientId: string;
    appointments: TAppointment[];
};

export const OxStaffDashboardPatientAppointments = ({
    patientId,
    appointments
}: TProps): JSX.Element => {
    const { validateResponse } = useValidateResponse();
    const store = useStore();
    const { user } = store.getState() as { user: TUserState };
    const websiteDataContext = useContext(WebsiteDataContext);

    const [treatmentNotes, setTreatmentNotes] = useState<TTreatmentNote[]>([]);
    const [treatmentAesthetician, setTreatmentAesthetician] = useState<
        TTreatmentAestheticianNote[]
    >([]);

    const doctors = websiteDataContext.doctors;

    useEffect(() => {
        let unmounted = false;
        if (
            user?.roles?.includes(ERoles.Nurse) ||
            user?.roles?.includes(ERoles.Practitioner) ||
            user?.roles?.includes(ERoles.Receptionist)
        ) {
            getTreatmentNotes({ patientId })
                .then((data) => validateResponse(data))
                .then((data) => !unmounted && setTreatmentNotes(data))
                .catch((e) => console.error(e.title));
        }

        if (
            user?.roles?.includes(ERoles.SkincareConsultant) ||
            user?.roles?.includes(ERoles.Nurse) ||
            user?.roles?.includes(ERoles.Practitioner) ||
            user?.roles?.includes(ERoles.Receptionist)
        ) {
            appointments.map((appointment) => {
                const appointmentId = appointment?.id;
                getTreatmentAesthetician({ appointmentId, patientId })
                    .then(validateResponse)
                    .then((data) => {
                        const dataArray = treatmentAesthetician;
                        dataArray[appointmentId] = data;
                        setTreatmentAesthetician(dataArray);
                    })
                    .catch((e) => console.error(e.error.message));
            });
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const appointmentRows: JSX.Element[] = useMemo(() => {
        const todayDateObj = DateTime.local();
        return appointments.map((appointment) => {
            const startDateObj = DateTime.fromISO(appointment.startsAt ?? '');

            return (
                <tr key={appointment.id}>
                    <Styled.TCell align="left">
                        <OxLink
                            target="_blank"
                            to={getClinikoUrlByClinic({
                                endpoint: `appointments/${appointment.id}`,
                                clinicId: user?.clinicId ?? ''
                            })}
                        >
                            {appointment.id}
                        </OxLink>
                    </Styled.TCell>
                    <Styled.TCell>{appointment.description}</Styled.TCell>
                    <Styled.TCell>{appointment.practitionerName}</Styled.TCell>

                    <Styled.TCell>{startDateObj.toFormat('HH:mm dd MMM yyyy')}</Styled.TCell>
                    <Styled.TCell align="right">
                        {(startDateObj < todayDateObj || sameDay(todayDateObj, startDateObj)) &&
                            !shouldHideTreatmentNotesButton(
                                user?.roles,
                                user?.clinic ?? ({} as TClinic),
                                appointment.id,
                                appointment,
                                null,
                                null,
                                null,
                                doctors
                            ) && (
                                <OxLink
                                    to={ERoutes.PanelStaffDashboardTreatmentNotes}
                                    state={{
                                        appointmentId: appointment.id,
                                        clinicId: appointment.clinicId
                                    }}
                                >
                                    <div>
                                        {getTreatmentButtonMessage({
                                            roles: user?.roles ?? [],
                                            treatmentNote: treatmentNotes?.find(
                                                (note) => note.appointmentId === appointment.id
                                            ),
                                            displayId: true
                                        })}
                                    </div>
                                </OxLink>
                            )}
                        {(user?.roles?.includes(ERoles.SkincareConsultant) ||
                            user?.roles?.includes(ERoles.Practitioner) ||
                            user?.roles?.includes(ERoles.Nurse)) &&
                            !shouldHideAestheticianNotesButton(appointment, doctors) && (
                                <OxLink
                                    to={applyValues(
                                        ERoutes.PanelStaffDashboardViewAestheticianTreatment,
                                        {
                                            patientId
                                        }
                                    )}
                                    state={{
                                        appointmentId: appointment.id,
                                        practitionerId: user.id
                                    }}
                                >
                                    {(treatmentAesthetician?.[appointment.id]?.length ?? 0) ===
                                    0 ? (
                                        <>Enter Aesthetician Notes</>
                                    ) : (
                                        <>View/Edit Aesthetician Notes</>
                                    )}
                                </OxLink>
                            )}
                        {user?.roles?.includes(ERoles.Receptionist) &&
                            !shouldHideAestheticianNotesButton(appointment, doctors) && (
                                <OxLink
                                    to={applyValues(
                                        ERoutes.PanelStaffDashboardViewAestheticianTreatment,
                                        {
                                            patientId
                                        }
                                    )}
                                    state={{
                                        appointmentId: appointment.id,
                                        practitionerId: user.id,
                                        readOnly: true
                                    }}
                                >
                                    {treatmentAesthetician?.[appointment.id]?.length > 0 && (
                                        <>View Aesthetician Notes</>
                                    )}
                                </OxLink>
                            )}
                    </Styled.TCell>
                </tr>
            );
        });
    }, [appointments, user, treatmentNotes, treatmentAesthetician]);

    return (
        <Styled.Invoices>
            <Styled.Title>Appointments</Styled.Title>
            {appointments?.length > 0 ? (
                <Styled.Table>
                    <thead>
                        <tr>
                            <Styled.THeadCell align="left">Appointment #</Styled.THeadCell>
                            <Styled.THeadCell>Type</Styled.THeadCell>
                            <Styled.THeadCell>Staff</Styled.THeadCell>
                            <Styled.THeadCell>Date</Styled.THeadCell>
                            {(!!user?.roles?.includes(ERoles.Nurse) ||
                                !!user?.roles?.includes(ERoles.Practitioner) ||
                                !!user?.roles?.includes(ERoles.Receptionist)) && (
                                <Styled.THeadCell align="right">Treatment Notes</Styled.THeadCell>
                            )}
                            {!!user?.roles?.includes(ERoles.SkincareConsultant) && (
                                <Styled.THeadCell align="right">Observations</Styled.THeadCell>
                            )}
                        </tr>
                    </thead>
                    <tbody>{appointmentRows}</tbody>
                </Styled.Table>
            ) : (
                <Styled.NoAppointments>No Appointments Found</Styled.NoAppointments>
            )}
        </Styled.Invoices>
    );
};
