import * as Styled from './OxTreatmentNote.styled';

import React, { useContext, useEffect, useRef, useState, KeyboardEvent } from 'react';

import { navigate } from '@reach/router';
import { OxSpinner } from 'src/components/OxSpinner';
import { OxTreatmentNotesInfo } from './components/OxTreatmentNotesInfo';
import { OxTreatmentNotesZones } from './components/OxTreatmentNotesZones';
import { OxTreatmentNotesGeneralNotes } from './components/OxTreatmentNotesGeneralNotes';
import { OxTreatmentNotesProductsUsed } from './components/OxTreatmentNotesProductsUsed';
import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import { EOxInputType, OxInput } from 'src/components/OxInput';
import {
    TAppointmentSummary,
    TTreatmentGoal,
    TTreatmentNote,
    TTreatmentNotePost
} from 'src/services/api/api.types';
import {
    TreatmentNoteContext,
    TTreatmentNoteContext,
    TTreatmentNoteProductData
} from 'src/context/TreatmentNoteContext';
import {
    finaliseTreatmentNote,
    getTreatmentAppointmentSummary,
    getTreatmentGoals,
    patchTreatmentNote,
    postTreatmentNote
} from 'src/services/api/treatment';
import { AlertContext, EAlertVariant } from 'src/context/AlertContext';
import { formDataToObject } from 'src/utils/formDataToObject';
import { ERoles, ERoutes, ETreatmentNoteStatus } from 'src/config/enums';
import { useStore } from 'react-redux';
import { OxStaffDashboardPageSectionHeader } from 'src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardPageSectionHeader';
import { useValidateResponse } from 'src/hooks/useValidateResponse';

type TProps = {
    appointmentId: string | number;
    clinicId: string;
    multipleExistingTreatmentNotes?: boolean;
    existingNote?: TTreatmentNote;
};

export const OxTreatmentNote: React.FC<TProps> = ({
    appointmentId,
    clinicId,
    multipleExistingTreatmentNotes,
    existingNote
}) => {
    const { validateResponse } = useValidateResponse();
    const store = useStore();
    const { user } = store.getState();
    const formRef = useRef();

    const { showAlert } = useContext(AlertContext);
    const [loading, setLoading] = useState<number>(0);
    const [appointmentSummary, setAppointmentSummary] = useState<TAppointmentSummary>();
    const [treatmentGoals, setTreatmentGoals] = useState<TTreatmentGoal[]>([]);
    const [productData, setProductData] = useState<TTreatmentNoteProductData[]>([]);
    const [outcomeId, setOutcomeId] = useState<number | undefined>();

    const matchDataItems = (a: TTreatmentNoteProductData, b: TTreatmentNoteProductData): boolean =>
        a.zoneId === b.zoneId && a.injectionPointId === b.injectionPointId;

    const setProductDatum = (newProductDatum: TTreatmentNoteProductData): void => {
        setProductData((prev) => {
            if (
                !prev.find((item: TTreatmentNoteProductData) =>
                    matchDataItems(item, newProductDatum)
                )
            ) {
                prev.push(newProductDatum);
            } else {
                return [
                    ...prev.map((item) => {
                        if (matchDataItems(item, newProductDatum)) {
                            item.id = newProductDatum.id;
                            item.name = newProductDatum.name;
                            newProductDatum.volume && (item.volume = newProductDatum.volume);
                        }
                        return item;
                    })
                ];
            }

            return [...prev];
        });
    };

    const setProductDatumVolume = (
        zoneId: number,
        injectionPointId: number,
        volume: number
    ): void => {
        setProductData((prev) => {
            const index = prev.findIndex((datum) =>
                matchDataItems(datum, { zoneId, injectionPointId })
            );

            if (index >= 0) {
                prev[index].volume = volume;
            } else {
                prev.push({
                    zoneId,
                    injectionPointId,
                    volume,
                    id: undefined,
                    name: undefined
                });
            }

            return [...prev];
        });
    };

    const clearProductData = (zoneId: number | undefined): void => {
        if (!zoneId) {
            setProductData([]);
        } else {
            setProductData((prev) => [...prev.filter((item) => item.zoneId !== zoneId)]);
        }
    };

    // const locked =
    //   existingNote &&
    //   (existingNote.status === ETreatmentNoteStatus.Locked ||
    //     (!user.roles.includes(ERoles.Practitioner) &&
    //       existingNote.status === ETreatmentNoteStatus.Final));

    //Temporary new locked
    const locked =
        existingNote &&
        [ETreatmentNoteStatus.Locked, ETreatmentNoteStatus.Final].includes(existingNote.status);

    const treatmentNotesContextValue: TTreatmentNoteContext = {
        multipleExistingTreatmentNotes,
        existingNote,
        locked,
        appointmentSummary,
        productData,
        setProductDatum,
        setProductDatumVolume,
        clearProductData,
        outcomeId,
        setOutcomeId: (id: number) => setOutcomeId(id),
        treatmentGoals
        // zones: []
    };

    const stopSubmitOnEnter = (e: KeyboardEvent): void => {
        e.key === 'Enter' && e.preventDefault();
    };

    const onFormSubmit = async (): Promise<void> => {
        const formData = new FormData(formRef.current);
        try {
            const dataObj = formDataToObject(formData) as TTreatmentNotePost;
            //Filter out items without product or volume set
            dataObj.items = dataObj.items.filter((item) => !!item.product && !!item.volume);
            let response;

            dataObj.clinicId = clinicId;

            if (!existingNote) {
                response = await postTreatmentNote(dataObj);
            } else {
                response = await patchTreatmentNote(existingNote.id, dataObj);
            }
            validateResponse(response);

            if (response && response['error']) {
                showAlert({
                    type: EAlertVariant.Error,
                    header: 'Error',
                    title: 'Something went wrong',
                    message: `Unable to create or update treatment note: ${response['error']}`
                });
                return;
            }

            if (!multipleExistingTreatmentNotes) {
                await navigate(ERoutes.PanelStaffDashboard);
            }
        } catch (e) {
            showAlert(e.error);
            console.error(e);
        }
    };

    const finalise = async (): Promise<void> => {
        if (!existingNote) return;

        try {
            const response = await finaliseTreatmentNote(existingNote.id);
            validateResponse(response);
            if (!multipleExistingTreatmentNotes) {
                await navigate(ERoutes.PanelStaffDashboard);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        existingNote?.outcome && setOutcomeId(existingNote.outcome.id);
        existingNote &&
            setProductData(
                existingNote.items.map((item) => {
                    const data: TTreatmentNoteProductData = {
                        zoneId: item.zone.id,
                        injectionPointId: item.injectionPoint.id,
                        volume: parseFloat(item.volume),
                        name: item.product.name,
                        id: item.product.id
                    };

                    return data;
                })
            );
    }, [existingNote]);

    useEffect(() => {
        let unmounted = false;

        setLoading((prev) => prev + 1);
        getTreatmentGoals()
            .then((data) => validateResponse(data))
            .then((data) => !unmounted && setTreatmentGoals(data))
            .catch((e) => console.error(e))
            .finally(() => !unmounted && setLoading((prev) => prev - 1));

        if (appointmentId) {
            setLoading((prev) => prev + 1);
            getTreatmentAppointmentSummary({
                appointmentId,
                clinicId
            })
                .then((data) => validateResponse(data))
                .then((data) => {
                    if (!unmounted) {
                        setAppointmentSummary(data);

                        if ((data.goal?.outcomes.length ?? 0) > 0) {
                            // Spec requires outcome to be set from dropdown
                            // Even if it's specified from the BE
                            // setOutcomeId(data.goal.outcomes[0].id);
                        }
                    }
                })
                .catch((e) => {
                    showAlert({
                        type: EAlertVariant.Error,
                        header: 'Error',
                        title: 'Something went wrong',
                        message: 'Unable to fetch appointment summary'
                    });
                    console.error(e.message);
                })
                .finally(() => !unmounted && setLoading((prev) => prev - 1));
        }

        return (): void => {
            unmounted = true;
        };
    }, []);

    return (
        <TreatmentNoteContext.Provider value={treatmentNotesContextValue}>
            {loading > 0 && (
                <OxSpinner
                    style={{
                        marginTop: '10%',
                        marginLeft: '33%',
                        position: 'absolute'
                    }}
                />
            )}
            <Styled.Form onFormSubmit={onFormSubmit} ref={formRef} onKeyPress={stopSubmitOnEnter}>
                {({ submitButtonProps }: any): JSX.Element => (
                    <OxThemedInput theme={EInputTheme.GoldAlternative}>
                        <Styled.Section>
                            <OxStaffDashboardPageSectionHeader>
                                Treatment Notes (
                                <Styled.SectionHeaderStatus>
                                    {existingNote ? existingNote.status : 'New'}
                                </Styled.SectionHeaderStatus>
                                ){existingNote ? ' - ' + existingNote.treatmentNumber : ''}
                            </OxStaffDashboardPageSectionHeader>
                            <OxTreatmentNotesInfo loading={!!loading} />
                        </Styled.Section>

                        {outcomeId && (
                            <>
                                <Styled.Section>
                                    <OxStaffDashboardPageSectionHeader>
                                        Zones
                                    </OxStaffDashboardPageSectionHeader>
                                    <OxTreatmentNotesZones clinicId={parseInt(clinicId)} />
                                </Styled.Section>

                                <Styled.Section>
                                    <OxStaffDashboardPageSectionHeader>
                                        General Notes
                                    </OxStaffDashboardPageSectionHeader>
                                    <OxTreatmentNotesGeneralNotes />
                                </Styled.Section>

                                {productData.filter((item) => !!item.name).length > 0 && (
                                    <>
                                        <Styled.Section>
                                            <OxStaffDashboardPageSectionHeader>
                                                Products Used in Treatments
                                            </OxStaffDashboardPageSectionHeader>
                                            <OxTreatmentNotesProductsUsed clinicId={clinicId} />
                                        </Styled.Section>

                                        {!!appointmentSummary && (
                                            <>
                                                {/**
                                                 * Only render these inputs once appointmentSummary is fetched,
                                                 * as shouldn't swap between controlled + uncontrolled inputs
                                                 * */}
                                                <OxInput
                                                    type={EOxInputType.Hidden}
                                                    name="patientId"
                                                    value={appointmentSummary?.patientId}
                                                />
                                            </>
                                        )}
                                        <OxInput
                                            type={EOxInputType.Hidden}
                                            name="outcome"
                                            value={outcomeId}
                                        />
                                        <OxInput
                                            type={EOxInputType.Hidden}
                                            name="appointmentId"
                                            value={appointmentId}
                                        />
                                        {!treatmentNotesContextValue.locked && (
                                            <OxThemedInput theme={EInputTheme.BackgroundGold}>
                                                <Styled.SubmitButton
                                                    type="submit"
                                                    {...submitButtonProps}
                                                    icon
                                                >
                                                    Save {!existingNote ? 'Draft' : 'Changes'}
                                                </Styled.SubmitButton>
                                                {!!existingNote &&
                                                    existingNote.status ===
                                                        ETreatmentNoteStatus.Draft &&
                                                    user?.roles.includes(ERoles.Practitioner) && (
                                                        <Styled.SubmitButton
                                                            type="button"
                                                            onClick={finalise}
                                                            icon
                                                        >
                                                            Save as Final
                                                        </Styled.SubmitButton>
                                                    )}
                                            </OxThemedInput>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </OxThemedInput>
                )}
            </Styled.Form>
        </TreatmentNoteContext.Provider>
    );
};
