import React from 'react';
import * as Styled from './OxWelcomeInternalContentWrapper.styled';
import { OxBackButton } from 'src/components/OxBackButton';

type TWelcomeInternalContentWrapper = {
    header: string;
    onBack: () => void;
};

export const OxWelcomeInternalContentWrapper = ({
    children,
    ...props
}: SCProps<'div', TWelcomeInternalContentWrapper>): JSX.Element => {
    return (
        <Styled.Container {...props}>
            <Styled.Header>
                <OxBackButton onClick={props.onBack} />
                {!!props.header && <Styled.HeaderName>{props.header}</Styled.HeaderName>}
                <OxBackButton />
            </Styled.Header>
            {children}
        </Styled.Container>
    );
};
