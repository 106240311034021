import * as Styled from './OxPanelShareConsent.styled';

import React, { useContext, useEffect, useState } from 'react';
import { OxSignature } from 'src/components/OxSignature';
import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import { getShareConsent, postShareConsent } from 'src/services/api/treatmentplan';
import { ERoutes } from 'src/config/enums';
import { navigate } from 'gatsby';
import { AlertContext, EAlertVariant } from 'src/context/AlertContext';
import { useValidateResponse } from 'src/hooks/useValidateResponse';
import { applyValues } from 'src/utils';
import { TShareConsent } from 'src/services/api/api.types';
import { getUrl } from 'src/services/api/api.core';

type TProps = {
    patientId?: string;
    allowConsent?: boolean;
};

export const OxPanelShareConsent = ({ patientId, allowConsent }: TProps): JSX.Element => {
    const { validateResponse } = useValidateResponse();
    const { showAlert } = useContext(AlertContext);

    const [shareConsent, setShareConsent] = useState<TShareConsent>();
    const [alreadyConsented, setAlreadyConsented] = useState<boolean>(false);

    const submit = (signature: string): void => {
        patientId &&
            postShareConsent({ patientId, imageData: signature, type: 'treatment_note' })
                .then((data) => validateResponse(data))
                .then(() => {
                    navigate(
                        applyValues(ERoutes.PanelStaffDashboardPatientById, {
                            patientId: patientId
                        })
                    );
                })
                .catch((e) => {
                    showAlert({
                        type: EAlertVariant.Error,
                        header: 'Error',
                        title: 'An error occurred when trying to post consent, if the problem persists please contact us.'
                    });
                    console.error(e);
                });
    };

    const handleBackButtonClick = (): void => {
        navigate(-1);
    };

    useEffect(() => {
        let unmounted = false;
        const asyncInit = async (): Promise<void> => {
            patientId &&
                getShareConsent({ patientId })
                    .then((data) => validateResponse(data))
                    .then((data) => {
                        if (!unmounted) {
                            for (const element of data) {
                                for (const item of element) {
                                    if (item.type === 'treatment_note') {
                                        setAlreadyConsented(true);
                                        setShareConsent(item);
                                        break;
                                    }
                                }
                            }
                        }
                    })
                    .catch((e) => {
                        showAlert({
                            type: EAlertVariant.Error,
                            header: 'Error',
                            title: 'An error occurred when trying to fetch consent, if the problem persists please contact us.'
                        });
                        console.error(e);
                    });
        };

        asyncInit();
        return (): void => {
            unmounted = true;
        };
    }, []);

    return (
        <Styled.Container>
            <Styled.Header>
                <Styled.BackButton onClick={handleBackButtonClick} />
                Consent to share
            </Styled.Header>
            {!!allowConsent && (
                <Styled.SignatureContainer>
                    <Styled.SectionHeader>Consent</Styled.SectionHeader>
                    {alreadyConsented && shareConsent ? (
                        <Styled.SignatureImage image={getUrl(shareConsent?.signature)} />
                    ) : (
                        <>
                            <Styled.SignatureCopy>
                                Please sign in the box below to confirm your consent to sharing your
                                notes.
                            </Styled.SignatureCopy>
                            <OxThemedInput theme={EInputTheme.BackgroundGold}>
                                <OxSignature
                                    captureTriggerTitle="Consent to Share"
                                    onCapture={submit}
                                />
                            </OxThemedInput>
                        </>
                    )}
                </Styled.SignatureContainer>
            )}
        </Styled.Container>
    );
};
