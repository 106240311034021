import * as Styled from './OxDashboardMenu.styled';

import React, { SyntheticEvent, useContext, useState } from 'react';

import { ERoutes } from 'src/config/enums';
import { logoutFlow } from 'src/panel/store/auth/authActions';
import { navigate, WindowLocation } from '@reach/router';
import { useDispatch } from 'react-redux';
import scrollToId from 'src/utils/scrollToId';
import { TUser } from 'src/services/api/api.types';
import { BookingFlowContext } from 'src/context/BookingFlowContext';

type TMenuItem = {
    title: string;
    to?: string;
    subItem?: boolean;
    logout?: boolean;
    state?: object | TUser | null;
    hide?: boolean;
    id?: string;
    children?: React.ReactNode;
};

type TProps = {
    menuItems: TMenuItem[];
    variant: 'staff' | 'customer';
    location?: WindowLocation | undefined;
};

export const OxDashboardMenu = (props: TProps): JSX.Element => {
    const bookingFlowContext = useContext(BookingFlowContext);
    const [expanded, setExpanded] = useState(false);
    const dispatch = useDispatch();

    const onLinkClick = (e: SyntheticEvent, element: TMenuItem): void => {
        if (element.logout) {
            e.preventDefault();
            dispatch(logoutFlow(bookingFlowContext));
            navigate(
                props.variant === 'customer' ? ERoutes.PanelCustomerLogin : ERoutes.PanelStaffLogin
            );
            return;
        }
        if (element.id) {
            if (!element.to || element.to === props?.location?.pathname) {
                scrollToId(element.id);
            }
        }
        setExpanded(false);
    };

    const getLinkTo = (element: TMenuItem): string | null => {
        return element.to !== location.pathname
            ? element.to + (element.id ? `#${element.id}` : '')
            : null;
    };

    return (
        <Styled.Container>
            <Styled.ContainerInner>
                <Styled.ExpanderWrapper onClick={(): void => setExpanded((prev) => !prev)}>
                    {expanded ? 'Close' : 'Options'}
                </Styled.ExpanderWrapper>
                <Styled.MenuWrapper className={!expanded ? 'collapsed' : ''}>
                    {props.menuItems
                        .filter((item) => !item.hide)
                        .map((element, index) => (
                            <li key={index} onClick={(e): void => onLinkClick(e, element)}>
                                <Styled.Link
                                    to={getLinkTo(element)}
                                    subItem={element.subItem}
                                    state={element.state}
                                >
                                    {element.title}
                                    {element.children}
                                </Styled.Link>
                            </li>
                        ))}
                </Styled.MenuWrapper>
            </Styled.ContainerInner>
        </Styled.Container>
    );
};
