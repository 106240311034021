import styled, { css } from 'styled-components';
import { fluidSizing } from 'src/utils';
import { OxLink } from 'src/components/OxLink';
import { createTextStyle } from 'src/utils/createTextStyle';
import { EFonts, EFontWeight, ESizes } from 'src/config/enums';
import { TArchetype } from 'src/services/api/api.types';

export const Extra = styled.div`
    ${createTextStyle(EFonts.Montserrat, EFontWeight.Light, [12, 12, 12], [16, 16, 16])}
    ${fluidSizing([{ prop: 'margin-bottom', values: [10, 10, 10] }])}
`;

export const Archetype = styled.span<{ archetype: TArchetype }>(
    ({ theme, archetype }) => css`
        background: ${archetype in theme.colors.archetype && theme.colors.archetype[archetype]};
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 50%;
        ${fluidSizing([
            { prop: 'height', values: [20, 20, 20] },
            { prop: 'width', values: [20, 20, 20] },
            {
                prop: 'right',
                values: [ESizes.GutterXS, ESizes.GutterS, ESizes.GutterM]
            },
            { prop: 'top', values: [20, 35, 40] }
        ])};
    `
);

export const Links = styled.div`
    display: flex;
    flex-direction: column;
`;

export const A = styled.a``;

export const Link = styled(OxLink)`
    ${fluidSizing([{ prop: 'margin-bottom', values: [5, 5, 5] }])}
    button {
        width: 100%;
        min-width: 0;
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [9, 9, 9], [9, 9, 9])}
    }
`;
