import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { EOxInputType } from 'src/components/OxInput';
import * as Styled from './OxTreatmentNotesProductsUsedVolumeInput.styled';
import { isBotox } from 'src/utils/treatmentNoteUtils';
import { TreatmentNoteContext } from 'src/context/TreatmentNoteContext';

type TProps = {
    index: number;
    id: number;
    calculatedBotoxVolume?: number;
    calculatedNonBotoxVolume?: number;
    getExistingBatchesById: (productId: number) => { id: number; barcode: string; unit: string }[];
};

export const OxTreatmentNotesProductsUsedVolumeInput = ({
    index,
    id,
    calculatedBotoxVolume,
    calculatedNonBotoxVolume,
    getExistingBatchesById
}: TProps): JSX.Element => {
    const { locked, existingNote } = useContext(TreatmentNoteContext);

    // These have to be 2 separate elements because of how the value prop behaves
    // even if it is passed undefined you then cannot override the value
    const sharedProps = {
        name: `batches.${id}0${index + 1}.unit`,
        suppressCustomValidationMessage: true,
        disabled: locked,
        isFirst: index === 0
    };

    const botoxVolume = (): number => {
        if (locked && getExistingBatchesById(id)[index]?.unit) {
            return parseInt(getExistingBatchesById(id)[index]?.unit);
        }

        if (calculatedBotoxVolume && calculatedBotoxVolume > 0) {
            return calculatedBotoxVolume;
        }

        return 0;
    };

    const nonBotoxVolume = (): number => {
        if (calculatedNonBotoxVolume && calculatedNonBotoxVolume > 0) {
            return Math.ceil(calculatedNonBotoxVolume);
        }

        return parseInt(getExistingBatchesById(id)[index]?.unit ?? '1');
    };

    const [enteredNonBotoxVolume, setEnteredNonBotoxVolume] = useState<number>(nonBotoxVolume());

    useEffect(() => {
        setEnteredNonBotoxVolume((prev) => {
            if (prev < nonBotoxVolume() && !getExistingBatchesById(id)[index]) {
                return nonBotoxVolume();
            }

            return prev;
        });
    }, [calculatedNonBotoxVolume]);

    /**
     * when existing note gets loaded in, re-set the volume
     * as this needs to be taken from the existing note
     */
    useEffect(() => {
        setEnteredNonBotoxVolume((prev) => {
            if (
                getExistingBatchesById(id)[index] &&
                prev < parseInt(getExistingBatchesById(id)[index].unit)
            ) {
                return parseInt(getExistingBatchesById(id)[index].unit);
            }

            return prev;
        });
    }, [existingNote]);

    return (
        <>
            {!isBotox(id) && (
                <Styled.VolumeInput
                    type={EOxInputType.Number}
                    value={enteredNonBotoxVolume}
                    onChange={(e: ChangeEvent): void => {
                        const target = e.target as HTMLInputElement;
                        setEnteredNonBotoxVolume(parseInt(target.value));
                    }}
                    min={nonBotoxVolume()}
                    {...sharedProps}
                />
            )}
            {isBotox(id) && (
                <>
                    <Styled.VolumeInput
                        type={EOxInputType.Hidden}
                        value={botoxVolume()}
                        {...sharedProps}
                    />
                    <Styled.BotoxVolume>
                        {botoxVolume() > 0 && `${botoxVolume()}u`}
                    </Styled.BotoxVolume>
                </>
            )}
        </>
    );
};
