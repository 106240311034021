import { EFontWeight, EFonts } from 'src/config/enums';
import styled, { css } from 'styled-components';

import { OxDashboardContentWrapper } from '../../../OxDashboardContentWrapper';
import { createTextStyle } from 'src/utils/createTextStyle';
import { fluidSizing } from 'src/utils';

export const Container = styled(OxDashboardContentWrapper)``;

export const Header = styled.div(
    ({ theme }) => css`
        border-bottom: 1px solid ${theme.colors.basic.black};
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Normal, [12, 12, 12], [20, 20, 20])}
        p {
            display: flex;
            ${fluidSizing([{ prop: 'margin-bottom', values: [15, 15, 15] }])}
        }
        span {
            color: ${theme.colors.primary.main};
            font-weight: ${EFontWeight.SemiBold};
            ${fluidSizing([{ prop: 'margin-left', values: [5, 5, 5] }])}
        }
    `
);

export const Text = styled.div`
    ${createTextStyle(EFonts.Montserrat, EFontWeight.Light, [13, 13, 13], [19, 19, 19])}
`;

export const Paragraph = styled.p`
    ${fluidSizing([{ prop: 'padding-top', values: [20, 20, 20] }])}
    ${createTextStyle(EFonts.Montserrat, EFontWeight.Light, [12, 12, 12], [20, 20, 20])}
`;

export const AttachmentSectionHeader = styled.h3`
    text-transform: uppercase;

    ${fluidSizing([{ prop: 'margin-bottom', values: [20, 20, 20] }])}
    ${createTextStyle(EFonts.Montserrat, EFontWeight.SemiBold, [14, 14, 14], [20, 20, 20])};
`;

export const Attachment = styled.div(
    () => css`
        ${fluidSizing([{ prop: 'padding-bottom', values: [20, 20, 20] }])}
    `
);

export const FileNameWrapper = styled.div(
    ({ theme }) => css`
        display: flex;
        align-items: center;
        border-bottom: 1px solid ${theme.colors.basic.black};
        ${fluidSizing([{ prop: 'margin-bottom', values: [10, 10, 10] }])}
        ${fluidSizing([{ prop: 'padding-bottom', values: [10, 10, 10] }])}
    `
);

export const FileName = styled.h2(
    ({ theme }) => css`
        color: ${theme.colors.primary.main};
        flex: 1;
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Normal, [12, 12, 12], [20, 20, 20])};
    `
);

export const FileDownload = styled.div(
    ({ theme }) => css`
        color: ${theme.colors.primary.main};
        text-transform: upperCase;
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Normal, [10, 10, 10], [20, 20, 20])};
    `
);

export const FileDetail = styled.div(
    () => css`
        display: flex;
        align-items: center;
        ${fluidSizing([{ prop: 'padding-bottom', values: [5, 5, 5] }])}
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Normal, [11, 11, 11], [11, 11, 11])}
    `
);

export const FileDetailLabel = styled.div(
    () => css`
        align-self: start;
        ${fluidSizing([{ prop: 'padding-right', values: [10, null, null] }])}
        ${fluidSizing([{ prop: 'width', values: [null, 90, 90] }])}
    ${createTextStyle(EFonts.Montserrat, EFontWeight.Light, [11, 11, 11], [11, 11, 11])}
    `
);

export const FileDetailInfo = styled.div``;
