// import * as Styled from "./OxTreatmentNotesGeneralNotes.styled";

import React, { useContext } from 'react';

import { OxTextAreaWysiwyg } from 'src/components/OxTextAreaWysiwyg';
import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import { TreatmentNoteContext } from 'src/context/TreatmentNoteContext';

export const OxTreatmentNotesGeneralNotes = (): JSX.Element => {
    const { locked, existingNote } = useContext(TreatmentNoteContext);

    return (
        <>
            {/*<Styled.Text>*/}
            {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
            {/*</Styled.Text>*/}
            {/**
             * Change of rendering depending on whether existing note input is set or not,
             * as shouldn't swap between controlled + uncontrolled inputs
             * */}
            <OxThemedInput theme={EInputTheme.GoldTransparentAlternative2}>
                <OxTextAreaWysiwyg
                    name="freetext"
                    disabled={locked}
                    value={existingNote?.freetext}
                />
            </OxThemedInput>
        </>
    );
};
