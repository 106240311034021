import * as Styled from './OxLoginFlowStage1.styled';

import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import { EOxLoginFlowVariant, TOxLoginFlowStageProps } from '../../OxLoginFlow';
import {
    clearAuthError,
    passwordAuthFlow,
    setAuthProcessStatus
} from 'src/panel/store/auth/authActions';
import { useDispatch, useStore } from 'react-redux';

import { EAuthProcessStatus } from 'src/panel/store/types';
import { EOxInputType } from 'src/components/OxInput';
import { ERoutes } from 'src/config/enums';
import React from 'react';
import { getAuthError } from 'src/panel/store/auth/authSelectors';
import { navigate } from '@reach/router';
import { OnyxError } from 'src/helpers/OnyxError';
import { EAlertVariant } from 'src/context/AlertContext';
import { isUserAdmin } from 'src/helpers/user';

export const OxLoginFlowStage1 = ({
    goToNextStage,
    setCurrentFlow,
    stage: { title, returnUrl, email }
}: TOxLoginFlowStageProps): JSX.Element => {
    const dispatch = useDispatch();
    const store = useStore();

    const onFormSubmit = async (data: FormData): Promise<void> => {
        const result = await dispatch(
            passwordAuthFlow({
                username: data.get('email') as string,
                password: data.get('password') as string
            })
        );

        if (result.success) {
            if (result.loggedIn) {
                if (returnUrl) {
                    navigate(returnUrl);
                } else {
                    const { user } = store.getState();
                    if (await isUserAdmin(user)) {
                        navigate(ERoutes.PanelStaffLanding);
                    } else {
                        navigate(ERoutes.PanelCustomerDashboard);
                    }
                }

                dispatch(setAuthProcessStatus(EAuthProcessStatus.Inaction));
            } else {
                goToNextStage();
            }
            return;
        }
        const error = getAuthError(store.getState());
        if (error.length > 0) {
            dispatch(clearAuthError());
            dispatch(setAuthProcessStatus(EAuthProcessStatus.Inaction));
            throw new OnyxError({
                type: EAlertVariant.Error,
                header: 'ERROR ALERT: OLFS1PA',
                title: 'Login Failed',
                message:
                    'Please check your details and try again, if the problem persists please contact us.'
            });
        }
    };

    return (
        <Styled.Container className="login1" onFormSubmit={onFormSubmit}>
            {({ submitButtonProps }) => (
                <>
                    <Styled.Title>{title}</Styled.Title>
                    <div>
                        <OxThemedInput theme={EInputTheme.GoldTransparent}>
                            <Styled.Input
                                initialValue={email}
                                type={EOxInputType.Email}
                                name="email"
                                placeholder="Email Address*"
                                required
                            />
                            <Styled.Input
                                type={EOxInputType.Password}
                                name="password"
                                placeholder="Password*"
                                required
                            />
                        </OxThemedInput>
                        <Styled.ForgottenPassword
                            onClick={(): void =>
                                setCurrentFlow(EOxLoginFlowVariant.ForgottenPassword)
                            }
                        >
                            Forgotten Password?
                        </Styled.ForgottenPassword>
                        <OxThemedInput theme={EInputTheme.BackgroundGold}>
                            <Styled.Button type="submit" icon {...submitButtonProps}>
                                LOGIN
                            </Styled.Button>
                        </OxThemedInput>
                    </div>
                </>
            )}
        </Styled.Container>
    );
};
