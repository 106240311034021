import * as Styled from './OxStaffDashboardPsychologicalAttributes.styled';

import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { OxStaffDashboardPsychologicalAttributesFears } from './components/OxStaffDashboardPsychologicalAttributesFears';
import { OxStaffDashboardPsychologicalAttributesSocialGoals } from './components/OxStaffDashboardPsychologicalAttributesSocialGoals';
import { OxStaffDashboardPsychologicalAttributesHeader } from './components/OxStaffDashboardPsychologicalAttributesHeader';
import { OxSpinner } from 'src/components/OxSpinner';
import { getPractitionerPsychologicalAttributes } from 'src/services/api/practitioner';
import { navigate } from 'gatsby';
import { ERoutes } from 'src/config/enums';
import {
    TArchetype,
    TPsychologicalAnswer,
    TPsychologicalAttributes,
    TRespondent
} from 'src/services/api/api.types';
import { psychologicalGetArchetype } from 'src/services/api/psychological';
import { OxIcon } from 'src/components/OxIcon';
import { OxPageHelmet } from 'src/components/OxPageHelmet';

type TProps<RouteComponentProps> = RouteComponentProps & {
    patientId?: string;
};

export const OxStaffDashboardPsychologicalAttributes: React.FC<TProps<RouteComponentProps>> = ({
    patientId,
    location
}) => {
    const [respondent, setRespondent] = useState<TRespondent>({});
    const [fears, setFears] = useState<string[]>([]);
    const [socialGoals, setSocialGoals] = useState<string[]>([]);
    const [archetype, setArchetype] = useState<TArchetype>('');

    const parseAndSetPsychologicalAttributesData = (
        data: TPsychologicalAttributes[] | null
    ): void => {
        const zeroedOrderedData: {
            fears: string[];
            socialGoals: string[];
            hairGoals: string[];
            hairFears: string[];
        } = { fears: [], socialGoals: [], hairGoals: [], hairFears: [] };

        data?.forEach((dataItem: TPsychologicalAttributes) => {
            if (
                [
                    'social_goals',
                    'personal_fears',
                    'hair_social_goals',
                    'hair_personal_fears'
                ].includes(dataItem?.survey?.name ?? '')
            ) {
                const orderedData: string[] = [];
                dataItem.answers?.forEach((answer: TPsychologicalAnswer) => {
                    orderedData[answer.orderedAnswer] = answer.statement.label;
                });

                if (dataItem.survey?.name === 'personal_fears')
                    orderedData.forEach((item) => zeroedOrderedData.fears.push(item));

                if (dataItem.survey?.name === 'social_goals')
                    orderedData.forEach((item) => zeroedOrderedData.socialGoals.push(item));

                if (dataItem.survey?.name === 'hair_social_goals')
                    orderedData.forEach((item) => zeroedOrderedData.hairGoals.push(item));

                if (dataItem.survey?.name === 'hair_personal_fears')
                    orderedData.forEach((item) => zeroedOrderedData.hairFears.push(item));
            }
        });

        // Having to use the live data instead of state as doesn't
        // seem to be set by the time this runs
        if (zeroedOrderedData.fears.length === 0 && zeroedOrderedData.socialGoals.length === 0) {
            navigate(ERoutes.ArchetypesSurvey);
            return;
        }

        setFears(zeroedOrderedData.fears.reverse());
        setSocialGoals(zeroedOrderedData.socialGoals);

        const firstSurvey = data?.pop();

        if (firstSurvey?.respondent?.id) {
            setRespondent(firstSurvey.respondent);
            psychologicalGetArchetype({
                respondentId: firstSurvey.respondent.id
            }).then((data) => {
                setArchetype(data as TArchetype);
            });
        }
    };

    useEffect(() => {
        const asyncInit = async (): Promise<void> => {
            if (location?.state?.psychologicalAttributes) {
                parseAndSetPsychologicalAttributesData(location.state.psychologicalAttributes);
            } else if (patientId) {
                getPractitionerPsychologicalAttributes({ patientId }).then(
                    parseAndSetPsychologicalAttributesData
                );
            } else {
                navigate(ERoutes.PanelStaffDashboard);
            }
        };

        asyncInit();
    }, []);

    return (
        <Styled.Container>
            <OxPageHelmet title="Psychological Attributes" />
            {fears.length === 0 && socialGoals.length === 0 && (
                <OxSpinner
                    style={{
                        marginTop: '25%',
                        marginLeft: '45%'
                    }}
                />
            )}
            {(fears.length > 0 || socialGoals.length > 0) && (
                <>
                    <OxStaffDashboardPsychologicalAttributesHeader
                        respondent={respondent}
                        archetype={archetype}
                    />
                    <Styled.Return>
                        <OxIcon name="arrow-2" />
                        <Styled.Label to={ERoutes.PanelStaffDashboard}>RETURN</Styled.Label>
                    </Styled.Return>
                    <Styled.Content>
                        <OxStaffDashboardPsychologicalAttributesSocialGoals
                            socialGoals={socialGoals}
                        />
                        <OxStaffDashboardPsychologicalAttributesFears fears={fears} />
                    </Styled.Content>
                </>
            )}
        </Styled.Container>
    );
};
