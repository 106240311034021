import * as Styled from './OxCustomerDashboardPaymentMethod.styled';

import React from 'react';
import { TStripePaymentMethod } from 'src/services/api/api.types';
import { graphql, useStaticQuery } from 'gatsby';

type TProps = {
    method: TStripePaymentMethod;
    setEditingMethodId: React.Dispatch<React.SetStateAction<string>>;
};

export const OxCustomerDashboardPaymentMethod = ({
    method,
    setEditingMethodId
}: TProps): JSX.Element => {
    const data = useStaticQuery(
        graphql`
            query {
                countries: allCountriesJson {
                    nodes {
                        text
                        value
                    }
                }
            }
        `
    );

    return (
        <Styled.Container>
            <Styled.Heading>
                <Styled.Card>{`${method.card.brand} ending in ${method.card.last4}`}</Styled.Card>
                <Styled.Button onClick={() => setEditingMethodId(method.id)}>Edit</Styled.Button>
            </Styled.Heading>
            <Styled.Body>
                <Styled.Expiry>
                    Expiry:{' '}
                    <Styled.ExpiryDate>{`${method.card.exp_month
                        .toString()
                        .padStart(2, '0')}/${method.card.exp_year
                        .toString()
                        .slice(-2)}`}</Styled.ExpiryDate>
                </Styled.Expiry>
                <Styled.Address>
                    {method.billing_details?.address?.postal_code}
                    <br />
                    {
                        data.countries.nodes.find(
                            (country) => country.value === method?.billing_details?.address?.country
                        )?.text
                    }
                </Styled.Address>
            </Styled.Body>
        </Styled.Container>
    );
};
