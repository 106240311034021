import * as Styled from './OxLoginFlowForgottenPassword.styled';

import { EAnimatedWrapperAnimation, OxAnimatedWrapper } from 'src/components/OxAnimatedWrapper';
import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import { EOxLoginFlowVariant, TOxLoginFlowStageProps } from '../../OxLoginFlow';
import React, { useState } from 'react';

import { EOxInputType } from 'src/components/OxInput';
import { userPasswordResetRequest } from 'src/services/api';
import { EAlertVariant } from 'src/context/AlertContext';
import { OnyxError } from 'src/helpers/OnyxError';

export const OxLoginFlowForgottenPassword = (props: TOxLoginFlowStageProps): JSX.Element => {
    const [requestSent, setRequestSent] = useState(false);
    const onFormSubmit = async (data: FormData): Promise<void> => {
        const result = await userPasswordResetRequest({
            email: data.get('email') as string
        });
        if (result) {
            setRequestSent(true);
            throw new OnyxError({
                type: EAlertVariant.Error,
                sentryIgnore: true,
                header: 'Password Reset',
                title: 'Reset Requested',
                message:
                    'If there is an account associated with your email address a reset password link will be sent to your email.'
            });
        }
    };

    return (
        <Styled.Container onFormSubmit={onFormSubmit}>
            {({ submitButtonProps }) => (
                <>
                    <Styled.Title>Forgotten Password</Styled.Title>
                    <OxThemedInput theme={EInputTheme.GoldTransparent}>
                        <Styled.Input
                            type={EOxInputType.Email}
                            name="email"
                            placeholder="Email Address*"
                            required
                        />
                    </OxThemedInput>
                    <div>
                        <Styled.Paragraph>
                            A reset password link will be sent to your email.
                        </Styled.Paragraph>
                        <OxThemedInput theme={EInputTheme.BackgroundGold}>
                            <Styled.Button type="submit" icon {...submitButtonProps}>
                                REQUEST
                            </Styled.Button>
                        </OxThemedInput>
                        <Styled.BackToLogin
                            onClick={(): void =>
                                props.setCurrentFlow(EOxLoginFlowVariant.LoginFlow)
                            }
                        >
                            Login
                        </Styled.BackToLogin>
                    </div>
                </>
            )}
        </Styled.Container>
    );
};
