import * as Styled from './OxStaffDashboardTreatmentObservations.styled';

import React, { useContext, useEffect, useRef, useState } from 'react';

import { navigate, RouteComponentProps, WindowLocation } from '@reach/router';
import { OxStaffDashboardRoot } from 'src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardRoot';
import { OxPageHelmet } from 'src/components/OxPageHelmet';
import { OxStaffDashboardPageSectionHeader } from 'src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardPageSectionHeader';
import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import {
    getTreatmentObservations,
    patchTreatmentObservations,
    postTreatmentObservations
} from 'src/services/api/treatment';
import { useValidateResponse } from 'src/hooks/useValidateResponse';
import {
    TTreatmentObservationNotes,
    TTreatmentObservationNotesGet
} from 'src/services/api/api.types';
import { AlertContext, EAlertVariant } from 'src/context/AlertContext';
import { OxForm } from 'src/components/OxForm';
import { formDataToObject } from 'src/utils/formDataToObject';
import { OxTextArea } from 'src/components/OxTextArea';
import { OxButton } from 'src/components/OxButton';

type TState = {
    appointmentId: number;
    clinicId: string;
};

type TProps<RouteComponentProps> = RouteComponentProps & {
    location: WindowLocation & {
        state: TState;
    };
};

export const OxStaffDashboardTreatmentObservations: React.FC<
    RouteComponentProps<TProps<RouteComponentProps>>
> = ({ location }) => {
    const { validateResponse } = useValidateResponse();
    const { showAlert } = useContext(AlertContext);
    const { appointmentId, clinicId } = location?.state ?? {
        appointmentId: 0,
        clinicId: ''
    };
    const formRef = useRef();

    const [existingContent, setExistingContent] = useState<TTreatmentObservationNotesGet>();

    const onTreatmentObservationSubmit = async (): Promise<void> => {
        const formData = new FormData(formRef.current);
        const dataObj = formDataToObject(formData) as TTreatmentObservationNotes;

        let response;

        if (!existingContent?.id) {
            dataObj.appointmentId = appointmentId;
            dataObj.clinicId = clinicId;
            response = await postTreatmentObservations(dataObj);
        } else {
            response = await patchTreatmentObservations(existingContent.id, dataObj);
        }

        if (response && response['error']) {
            showAlert({
                type: EAlertVariant.Error,
                header: 'Error',
                title: 'Something went wrong',
                message: `Unable to create or update treatment note: ${response['error']}`
            });
            return;
        } else {
            showAlert({
                type: EAlertVariant.Success,
                header: 'Success',
                title: 'Treatment Observation Notes submitted successfully'
            });
        }
    };

    useEffect(() => {
        let unmounted = false;

        getTreatmentObservations({ appointmentId, clinicId })
            .then(validateResponse)
            .then((data) => {
                if (!unmounted) {
                    setExistingContent(data);
                }
            })
            .catch((e) => console.error(e.error.message));

        return (): void => {
            unmounted = true;
        };
    }, []);

    return (
        <OxStaffDashboardRoot location={location}>
            <OxPageHelmet title="Treatment Observations" />
            <Styled.BackButton
                onClick={(): void => {
                    navigate(-1);
                }}
            />
            <Styled.Container>
                <OxStaffDashboardPageSectionHeader>
                    Treatment Observations
                </OxStaffDashboardPageSectionHeader>
                <OxForm onFormSubmit={onTreatmentObservationSubmit} ref={formRef}>
                    <OxThemedInput theme={EInputTheme.BackgroundWhite}>
                        <OxTextArea
                            name="content"
                            placeholder="Treatment Observations"
                            value={existingContent?.content}
                            onValueChange={(val: string) => {
                                setExistingContent((prevState) => {
                                    return {
                                        ...prevState,
                                        content: val
                                    };
                                });
                                return val;
                            }}
                        />
                    </OxThemedInput>
                    <OxThemedInput theme={EInputTheme.BackgroundGoldAlternative}>
                        <OxButton icon type="submit">
                            Submit Observation Notes
                        </OxButton>
                    </OxThemedInput>
                </OxForm>
            </Styled.Container>
        </OxStaffDashboardRoot>
    );
};
