import * as Styled from './OxStaffDashboardReturn.styled';

import React from 'react';

type TProps = {
    to?: string;
    onClick?: () => void;
};

export const OxStaffDashboardReturn = ({ to, onClick }: TProps) => {
    return <Styled.Return to={to} onClick={onClick} />;
};
