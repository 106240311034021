import { EFontWeight, EFonts } from 'src/config/enums';

import { OxButton } from 'src/components/OxButton';
import { createTextStyle } from 'src/utils/createTextStyle';
import { fluidSizing } from 'src/utils';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.43);
    width: 100%;
    a {
        width: 100%;
    }
    ${fluidSizing([
        { prop: 'width', values: [null, 312, 400] },
        { prop: 'padding', values: [30, 30, 30] },
        { prop: 'margin-bottom', values: [15, null, null] }
    ])}
`;

export const Title = styled.div`
    ${createTextStyle(EFonts.Hatton, EFontWeight.Normal, [27, 27, 27], [34, 34, 34])}
    ${fluidSizing([{ prop: 'margin-bottom', values: [20, 20, 20] }])}
`;

export const Description = styled.p`
    ${createTextStyle(EFonts.Montserrat, EFontWeight.Light, [13, 13, 13], [19, 19, 19])}
    ${fluidSizing([{ prop: 'margin-bottom', values: [20, 20, 20] }])}
`;

export const ViewButton = styled(OxButton)`
    width: 100%;
`;
