import * as Styled from './OxStaffDashboardShareConsent.styled';

import React from 'react';

import { RouteComponentProps, WindowLocation } from '@reach/router';
import { OxPageHelmet } from 'src/components/OxPageHelmet';
import { OxPanelShareConsent } from 'src/panel/components/OxPanelShareConsent';

type TProps = {
    patientId: string;
    location: WindowLocation | undefined;
};
export const OxStaffDashboardShareConsent: React.FC<RouteComponentProps<TProps>> = ({
    patientId,
    location
}) => {
    const { allowConsent } = location?.state as {
        allowConsent: boolean;
    };

    return (
        <Styled.Container>
            <OxPageHelmet title="Consent to share" />
            <OxPanelShareConsent patientId={patientId} allowConsent={allowConsent} />
        </Styled.Container>
    );
};
