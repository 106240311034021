import { EApiEndpoint } from 'src/config/enums';
import {
    TAppointmentSummary,
    TBotoxVial,
    TError,
    TTreatmentAestheticianNote,
    TTreatmentAestheticianNotes,
    TTreatmentGoal,
    TTreatmentNote,
    TTreatmentNotePost,
    TTreatmentObservationNotes,
    TTreatmentZone
} from './api.types';
import { apiGetCall, apiPatchCall, apiPostCall } from './api.core';
import { applyValues } from 'src/utils/applyValues';

export const getTreatmentAppointmentSummary = async (values: {
    clinicId: string;
    appointmentId: string | number;
}): Promise<TAppointmentSummary | null> => {
    return await apiGetCall(applyValues(EApiEndpoint.TreatmentAppointmentSummary, values), {
        credentials: true
    });
};

export const getTreatmentZones = async (): Promise<TTreatmentZone[] | null> => {
    return await apiGetCall(EApiEndpoint.TreatmentZone, {
        credentials: true,
        cacheable: true
    });
};

export const getTreatmentGoals = async (): Promise<TTreatmentGoal[] | null> => {
    return await apiGetCall(EApiEndpoint.TreatmentGoal, {
        credentials: true,
        cacheable: true
    });
};

export const getTreatmentNotes = async (params: {
    appointmentId?: string;
    patientId?: string;
}): Promise<TTreatmentNote[] | null> => {
    return await apiGetCall(EApiEndpoint.TreatmentNote, {
        params: {
            appointmentId: params?.appointmentId,
            patientId: params?.patientId
        },
        credentials: true
    });
};

export const postTreatmentNote = async (
    data: TTreatmentNotePost
): Promise<(TTreatmentNote & TError) | null> => {
    return await apiPostCall(EApiEndpoint.TreatmentNote, JSON.stringify(data), {
        credentials: true
    });
};

export const patchTreatmentNote = async (
    noteId: number,
    data: TTreatmentNotePost
): Promise<(TTreatmentNote & TError) | null> => {
    return await apiPatchCall(
        applyValues(EApiEndpoint.TreatmentNotePatch, { noteId }),
        JSON.stringify(data),
        {
            credentials: true
        }
    );
};

export const finaliseTreatmentNote = async (noteId: number): Promise<TTreatmentNote | null> => {
    return await apiPostCall(applyValues(EApiEndpoint.TreatmentNoteFinalise, { noteId }), '', {
        credentials: true
    });
};

export const getTreatmentObservations = async (params: {
    appointmentId?: number;
    clinicId?: string;
}): Promise<TTreatmentNote[] | null> => {
    return await apiGetCall(applyValues(EApiEndpoint.TreatmentObservationsGet, params), {
        credentials: true
    });
};

export const patchTreatmentObservations = async (
    id: number,
    data: TTreatmentObservationNotes
): Promise<(TTreatmentNote & TError) | null> => {
    return await apiPatchCall(
        applyValues(EApiEndpoint.TreatmentObservationsPatch, { id }),
        JSON.stringify(data),
        {
            credentials: true
        }
    );
};

export const postTreatmentObservations = async (
    data: TTreatmentObservationNotes
): Promise<(TTreatmentNote & TError) | null> => {
    return await apiPostCall(EApiEndpoint.TreatmentObservations, JSON.stringify(data), {
        credentials: true
    });
};

export const getTreatmentAesthetician = async (params: {
    appointmentId?: number;
    patientId: number;
}): Promise<[TTreatmentAestheticianNote] | null> => {
    return await apiGetCall(applyValues(EApiEndpoint.TreatmentAestheticianGet, params), {
        credentials: true
    });
};

export const postTreatmentAesthetician = async (
    data: TTreatmentAestheticianNotes
): Promise<(TTreatmentAestheticianNote & TError) | null> => {
    return await apiPostCall(EApiEndpoint.TreatmentAesthetician, JSON.stringify(data), {
        credentials: true
    });
};

export const getBotoxVialData = async (data: {
    barcode: string;
    clinicId: string;
}): Promise<(TBotoxVial & TError) | null> => {
    return await apiGetCall(applyValues(EApiEndpoint.TreatmentNoteBotoxVolume, data), {
        credentials: true
    });
};
