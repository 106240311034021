import * as Styled from './OxTreatmentNotesProductsUsed.styled';

import React, { ReactFragment, useContext, useEffect, useState } from 'react';

import { TBotoxVial, TDearProduct, TTreatmentProduct } from 'src/services/api/api.types';
import { TreatmentNoteContext, TTreatmentNoteProductData } from 'src/context/TreatmentNoteContext';
import { randomString } from 'src/utils/randomString';
import { AlertContext, EAlertVariant, TAlertData } from 'src/context/AlertContext';
import { getBotoxVialData } from 'src/services/api/treatment';
import { isBotox } from 'src/utils/treatmentNoteUtils';
import { OxTreatmentNotesProductsUsedBarcodeInput } from 'src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardTreatmentNotes/components/OxTreatmentNote/components/OxTreatmentNotesProductsUsed/components/OxTreatmentNotesProductsUsedBarcodeInput';
import { OxTreatmentNotesProductsUsedVolumeInput } from 'src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardTreatmentNotes/components/OxTreatmentNote/components/OxTreatmentNotesProductsUsed/components/OxTreatmentNotesProductsUsedVolumeInput';
import { getProduct } from 'src/services/api/dear';
import { useValidateResponse } from 'src/hooks/useValidateResponse';
import { ETreatmentNoteStatus } from 'src/config/enums';

type TBatchData = {
    id: number;
    name: string;
    batchKeys: string[];
    barcodes: string[];
};

type TVolumeData = {
    [index: number]: number;
};

type TProps = {
    clinicId: string;
};

const columnTitles = ['Product', 'Ouronyx Reference', 'Qty / Units'];
const batchLimit = 5;

let checkBotoxRowsTimeout: number | undefined;
let checkNonBotoxRowsTimeout: number | undefined;
let barcodesFetched: string[] = [];

/**
 * Here be botox related dragons/wagons/spaghetti
 * @constructor
 */
export const OxTreatmentNotesProductsUsed = ({ clinicId }: TProps): JSX.Element => {
    const { validateResponse } = useValidateResponse();
    /**
     * ~~~~~~~~~~ SHARED LOGIC ~~~~~~~~~~
     */
    const { showAlert } = useContext(AlertContext);
    const generateBatchKey = (): string => `${Date.now()}${randomString(5)}`;
    const { existingNote, productData } = useContext(TreatmentNoteContext);

    /**
     * usedProducts contains a full list of product zones, injection points, and the products associated with them
     */
    const usedProducts = productData.filter((data) => !!data.name);

    /**
     * Helper function to filter used products for unique product data
     * @param val
     * @param index
     */
    const filterUniqueUsedProducts = (val: TTreatmentNoteProductData, index: number): boolean =>
        index === usedProducts.findIndex((item) => item.id === val.id);

    /**
     * We want to filter out duplicate products while constructing the batch data
     * So we filter from usedProducts where the current index does not equal
     * the first index found in usedProducts where ids match
     */
    const [batchData, setBatchData] = useState<TBatchData[]>(
        usedProducts.filter(filterUniqueUsedProducts).map(({ name, id }) => ({
            id,
            name,
            batchKeys: [generateBatchKey()],
            barcodes: []
        }))
    );

    const addToProductBatch = (product: TBatchData, id: number): TBatchData => {
        if (product.id === id && product.batchKeys.length < batchLimit) {
            product.batchKeys = [...product.batchKeys, generateBatchKey()];
        }
        return product;
    };

    const addToBatchInsideSetBatchData = (prev: TBatchData[], id: number): TBatchData[] => {
        return [...prev.map((product) => addToProductBatch(product, id))];
    };

    const addToBatch = (id: number): void => {
        setBatchData((prev) => addToBatchInsideSetBatchData(prev, id));
    };

    const removeProductBatch = (
        product: TBatchData,
        keyToRemove: string,
        productId: number
    ): TBatchData => {
        if (product.id === productId) {
            const indexToRemove = product.batchKeys.findIndex((value) => value === keyToRemove);
            product.batchKeys = product.batchKeys.filter((key) => key !== keyToRemove);
            product.barcodes = product.barcodes.filter(
                (_barcode, index) => index !== indexToRemove
            );
        }
        return product;
    };

    const removeBatchInsideSetBatchData = (
        prev: TBatchData[],
        keyToRemove: string,
        productId: number,
        existingBatchId?: number
    ): TBatchData[] => {
        const newBatchData = [...prev].map((product) =>
            removeProductBatch(product, keyToRemove, productId)
        );

        if (existingNote && existingBatchId) {
            existingNote.batches = [
                ...existingNote.batches.filter((item) => item.id !== existingBatchId)
            ];
        }

        // Should be impossible to get to 0 length
        // if (newBatchData.length === 0) {
        //   newBatchData = [generateBatchKey()];
        // }

        return newBatchData;
    };

    const removeBatch = (
        keyToRemove: string,
        productId: number,
        existingBatchId?: number
    ): void => {
        setBatchData((prev) =>
            removeBatchInsideSetBatchData(prev, keyToRemove, productId, existingBatchId)
        );
    };

    const getExistingBatchesById = (
        productId: number
    ): { id: number; barcode: string; batchCode: string; unit: string }[] => {
        return (existingNote?.batches ?? [])
            .filter(({ product: { id: existingId } }) => productId === existingId)
            .map(({ id, barcode, batchCode, unit }) => ({
                id,
                barcode,
                batchCode,
                unit
            }));
    };

    const [productDataByBarcode, setProductDataByBarcode] = useState<{
        [barcode: string]: TDearProduct;
    }>({});

    const getProductDataByBarcode = (barcode: string): void => {
        if (!productDataByBarcode[barcode]) {
            getProduct({ barcode, clinicId })
                .then((response) => validateResponse(response))
                .then((data) => {
                    setProductDataByBarcode((prev) => {
                        prev[barcode] = data;
                        return { ...prev };
                    });
                })
                .catch((e) => {
                    console.error(e);
                });
        }
    };

    const onBatchBlur = async (
        productId: number,
        index: number,
        barcode: string,
        setInvalid: React.Dispatch<React.SetStateAction<boolean>>
    ): Promise<void> => {
        /**
         * If barcode unchanged, do nothing
         */
        if (batchData.find((item) => item.id === productId)?.barcodes[index] === barcode) return;

        setBatchData((prev) => {
            return prev.map((item) => {
                if (item.id === productId) {
                    item.barcodes[index] = barcode;
                }

                return item;
            });
        });

        if (!isBotox(productId)) {
            getProduct({ barcode, clinicId })
                .then((response) => validateResponse(response))
                .catch((e) => {
                    setInvalid(true);
                    // exclude locked notes
                    if (
                        ![ETreatmentNoteStatus.Locked, ETreatmentNoteStatus.Final].includes(
                            existingNote?.status ?? ('' as ETreatmentNoteStatus)
                        )
                    ) {
                        showAlert({
                            type: EAlertVariant.Error,
                            header: 'Alert',
                            title: 'Invalid Barcode',
                            message: e.error.message
                        });
                    }
                });
        }
    };

    /**
     * Everytime zone productData updates,
     * we want to check if we need to add any new products to the table
     */
    useEffect(() => {
        // Filter out products no longer used
        // Then add new products
        setBatchData((prev) => [
            ...prev.filter((batchDatum) =>
                usedProducts.find((product) => product.id === batchDatum.id)
            ),
            ...usedProducts
                .filter(
                    (product) =>
                        !prev.map((batchDatum) => batchDatum.id).find((id) => id === product.id)
                )
                .map(
                    ({ name, id }) =>
                        ({
                            name,
                            id,
                            batchKeys: [generateBatchKey()],
                            barcodes: []
                        } as TBatchData)
                )
        ]);
    }, [productData]);

    const [totalBotoxVolume, setTotalBotoxVolume] = useState<number>(0);
    const [totalNonBotoxVolume, setTotalNonBotoxVolume] = useState<TVolumeData>({});

    /**
     * When an existing note changes (because existingNotes are loaded dynamically)
     * we want to load this data in to the state
     */
    useEffect(() => {
        if (existingNote) {
            setBatchData(
                existingNote.batches.map(({ product: { id, name } }) => {
                    const existingBatches = existingNote.batches.filter(
                        ({ product }) => product.id === id
                    );
                    const barcodes: string[] = existingBatches.map(({ barcode }) => barcode);

                    return {
                        id,
                        name,
                        batchKeys: [...Array(existingBatches.length)].map(() => generateBatchKey()),
                        barcodes
                    };
                })
            );

            setTotalBotoxVolume(
                existingNote.batches
                    .filter(({ product: { id } }) => isBotox(id))
                    .reduce((prev, curr) => prev + (parseInt(curr.unit) ?? 0), 0)
            );

            const nonBotoxVolume: TVolumeData = {};

            existingNote.batches
                .filter(({ product: { id } }) => !isBotox(id))
                .forEach((batch) => {
                    nonBotoxVolume[batch.product.id] = parseInt(batch.unit);
                });

            setTotalNonBotoxVolume(nonBotoxVolume);
        }
    }, [existingNote]);

    /**
     * ~~~~~~~~~~ NON BOTOX LOGIC ~~~~~~~~~~
     */
    useEffect(() => {
        const nonBotoxVolume: TVolumeData = {};

        productData
            .filter(({ id }) => !isBotox(id))
            .forEach((element) => {
                if (element.volume && element.volume > 0 && element.id) {
                    nonBotoxVolume[element.id] = element.volume + (nonBotoxVolume[element.id] ?? 0);
                }
            });
        /**
         * feel like this is going to come back to bite me, but
         * when product rows initialise, volumes come through as 0,
         * which really messes things up
         */
        Object.keys(nonBotoxVolume).length > 0 && setTotalNonBotoxVolume(nonBotoxVolume);
    }, [productData, existingNote]);

    const getNonBotoxVolume = (id: number, barcode: string | undefined): number | undefined => {
        if (isBotox(id) || !barcode) return undefined;
        const productData = productDataByBarcode[barcode];

        if (!productData) return 1;

        const unitOfMeasure = productData.unitOfMeasure.match(/\d*\.?\d*/);
        const vialSize = parseFloat(unitOfMeasure ? unitOfMeasure[0] : '1');

        return parseFloat((totalNonBotoxVolume[id] / vialSize).toFixed(2));
    };

    /**
     * ~~~~~~~~~~ BOTOX LOGIC ~~~~~~~~~~
     */
    const [botoxVialData, setBotoxVialData] = useState<TBotoxVial[]>([]);

    useEffect(() => {
        const botoxVolume = productData
            .filter(({ id }) => isBotox(id))
            .reduce((prev, curr) => prev + (curr.volume ?? 0), 0);
        /**
         * feel like this is going to come back to bite me, but
         * when product rows initialise, volumes come through as 0,
         * which really messes things up
         */
        botoxVolume > 0 && setTotalBotoxVolume(botoxVolume);
    }, [productData]);

    const getBotoxVialDataByBarcode = (barcode: string): TBotoxVial | undefined => {
        // If the note is locked we want to take the botox vial amount from the batch
        // Otherwise we'll create new rows incorrectly
        const existingNoteProductUnit: number | false | undefined =
            [ETreatmentNoteStatus.Locked, ETreatmentNoteStatus.Final].includes(
                existingNote?.status ?? ('' as ETreatmentNoteStatus)
            ) && existingNote?.batches.find((batch) => batch.barcode === barcode)?.unit;

        if (existingNoteProductUnit && existingNoteProductUnit > 0) {
            return {
                remaining: existingNoteProductUnit,
                barcode,
                total: existingNoteProductUnit,
                used: 0,
                damaged: 0
            };
        }

        const vialData = botoxVialData.find((vial) => vial.barcode === barcode);

        if (!vialData && !barcodesFetched.includes(barcode)) {
            barcodesFetched.push(barcode);
            getBotoxVialData({ barcode, clinicId })
                .then(validateResponse)
                .then((vialData) => vialData && setBotoxVialData((prev) => [...prev, vialData]))
                .catch((e) => {
                    const alertData = {
                        type: EAlertVariant.Error,
                        header: 'Error',
                        title: 'An error occurred while checking this barcode',
                        message: e.error.message
                    } as TAlertData;

                    setBotoxVialData((prev) => [
                        ...prev,
                        {
                            barcode,
                            total: 0,
                            used: 0,
                            damaged: 0,
                            remaining: 0
                        }
                    ]);

                    showAlert(alertData);
                });
        }

        return vialData;
    };

    const getRemainingBotoxVolumeInVial = (barcode: string): number => {
        return getBotoxVialDataByBarcode(barcode)?.remaining ?? 0;
    };

    let unassignedBotoxVolume: number;

    try {
        unassignedBotoxVolume =
            batchData
                .find((product) => isBotox(product.id))
                ?.barcodes?.reduce((prev, curr) => {
                    if (!getBotoxVialDataByBarcode(curr)) {
                        throw new Error('Barcodes have not been fetched from API yet');
                    }
                    return prev - getRemainingBotoxVolumeInVial(curr);
                }, totalBotoxVolume) ?? 0;
    } catch (e) {
        unassignedBotoxVolume = 0;
    }

    const getBotoxVolume = (id: number, index: number): number | undefined => {
        if (!isBotox(id)) return undefined;

        const product = batchData.find((product) => product.id === id);

        if (!product || !product.barcodes[index]) return unassignedBotoxVolume;

        try {
            product.barcodes.forEach((barcode) => {
                if (!getBotoxVialDataByBarcode(barcode)) {
                    throw new Error('Barcodes have not been fetched from API yet');
                }
            });
        } catch (e) {
            // console.error(e.message);
            return undefined;
        }

        const remainingVolumeToBeAllocated = product.barcodes
            .slice(0, index)
            .reduce((prev, curr) => prev - getRemainingBotoxVolumeInVial(curr), totalBotoxVolume);

        return Math.min(
            remainingVolumeToBeAllocated,
            getRemainingBotoxVolumeInVial(product.barcodes[index])
        );
    };

    const checkCorrectNumberOfBotoxBatchRows = (): void => {
        const botoxBatchData = batchData?.find((usedProduct) => isBotox(usedProduct.id));

        if (!botoxBatchData) return;

        setBatchData((prevBatchData) => {
            return prevBatchData.map((product) => {
                if (!isBotox(product.id)) return product;

                if (
                    unassignedBotoxVolume > 0 &&
                    product.barcodes.length >= product.batchKeys.length &&
                    !!product.barcodes[product.barcodes.length - 1]
                ) {
                    product = addToProductBatch(product, product.id);
                }

                if (product.batchKeys.length === 0) {
                    product = addToProductBatch(product, product.id);
                }

                product.batchKeys.forEach((batchKey, index) => {
                    if (
                        getBotoxVolume(product.id, index) !== undefined &&
                        (getBotoxVolume(product.id, index) ?? -1) <= 0
                    ) {
                        product = removeProductBatch(product, batchKey, product.id);
                    }

                    if (unassignedBotoxVolume <= 0 && product.barcodes[index] === undefined) {
                        product = removeProductBatch(product, batchKey, product.id);
                    }
                });

                return product;
            });
        });
    };

    /**
     * Every time entered botox data updates we want to run through and
     * make sure we have vial data for every barcode
     */
    useEffect(() => {
        batchData.forEach((item) =>
            item.barcodes.forEach((barcode) =>
                isBotox(item.id)
                    ? getBotoxVialDataByBarcode(barcode)
                    : getProductDataByBarcode(barcode)
            )
        );
    }, [batchData]);

    /**
     * Every time zone productData, or fetched botoxVialData updates,
     * we want to re-calculate each row's volume data
     */
    useEffect(() => {
        checkBotoxRowsTimeout && clearTimeout(checkBotoxRowsTimeout);
        checkBotoxRowsTimeout = setTimeout(checkCorrectNumberOfBotoxBatchRows, 500);
    }, [botoxVialData, totalBotoxVolume, existingNote]);

    /**
     * We need to reset variables which sit outside of react when component is unmounted
     */
    useEffect(() => {
        return (): void => {
            barcodesFetched = [];
            checkBotoxRowsTimeout && clearTimeout(checkBotoxRowsTimeout);
            checkNonBotoxRowsTimeout && clearTimeout(checkNonBotoxRowsTimeout);
        };
    }, []);
    /**
     * ~~~~~~~~~~ RENDERERS ~~~~~~~~~~
     */
    const getBarcodeInputs = ({ id }: TTreatmentProduct): ReactFragment => {
        const keys = batchData.find((productUsed) => productUsed.id === id)?.batchKeys ?? [];

        return keys.map((key, index) => (
            <OxTreatmentNotesProductsUsedBarcodeInput
                key={key}
                inputKey={key}
                keys={keys}
                index={index}
                id={id}
                getExistingBatchesById={getExistingBatchesById}
                onBatchBlur={onBatchBlur}
                batchLimit={batchLimit}
                addToBatch={addToBatch}
                removeBatch={removeBatch}
            />
        ));
    };

    const getVolumeInputs = ({ id }: TTreatmentProduct): ReactFragment => {
        const product = batchData.find((product) => product.id === id);
        const keys = product?.batchKeys ?? [];
        return keys.map((key, index) => (
            <OxTreatmentNotesProductsUsedVolumeInput
                key={key + (getBotoxVolume(id, index) ?? '')}
                id={id}
                index={index}
                getExistingBatchesById={getExistingBatchesById}
                calculatedBotoxVolume={getBotoxVolume(id, index)}
                calculatedNonBotoxVolume={getNonBotoxVolume(id, product?.barcodes[index])}
            />
        ));
    };

    const rows: [string, ReactFragment, ReactFragment][] = usedProducts
        .filter(filterUniqueUsedProducts)
        .map((product) => [
            product.name ?? '',
            getBarcodeInputs(product as TTreatmentProduct),
            getVolumeInputs(product as TTreatmentProduct)
        ]);

    return <Styled.Table columnTitles={columnTitles} rows={rows} />;
};
