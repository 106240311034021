import * as Styled from './OxCustomerDashboardSavedPaymentDetails.styled';

import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { getUserMethods } from 'src/services/api/stripe';
import { TStripePaymentMethod } from 'src/services/api/api.types';
import { OxSpinner } from 'src/components/OxSpinner';
import { OxCustomerDashboardPaymentMethod } from 'src/panel/components/OxPanelCustomerDashboard/components/OxCustomerDashboardSavedPaymentDetails/components/OxCustomerDashboardPaymentMethod';
import { OxBackButton } from 'src/components/OxBackButton';
import { OxCustomerDashboardEditPaymentMethod } from 'src/panel/components/OxPanelCustomerDashboard/components/OxCustomerDashboardSavedPaymentDetails/components/OxCustomerDashboardEditPaymentMethod';
import { useValidateResponse } from 'src/hooks/useValidateResponse';
import { OxPageHelmet } from 'src/components/OxPageHelmet';

export const OxCustomerDashboardSavedPaymentDetails: React.FC<RouteComponentProps> = () => {
    const { validateResponse } = useValidateResponse();
    const [paymentMethods, setPaymentMethods] = useState<TStripePaymentMethod[]>([]);
    const [editingMethodId, setEditingMethodId] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        let unmounted = false;
        setLoading(true);

        getUserMethods()
            .then(validateResponse)
            .then((methods) => !unmounted && methods.data && setPaymentMethods(methods.data))
            .catch((e) => console.error(e))
            .finally(() => setLoading(false));

        return (): void => {
            unmounted = true;
        };
    }, []);

    const onComplete = (): void => {
        setLoading(true);

        getUserMethods()
            .then(validateResponse)
            .then((methods) => {
                methods.data && setPaymentMethods(methods.data);
                setEditingMethodId('');
            })
            .catch((e) => console.error(e))
            .finally(() => setLoading(false));
    };

    return (
        <Styled.Container title="Saved Payment Details">
            <OxPageHelmet title="Saved Payment Details" />
            {loading && (
                <OxSpinner
                    addTranslate
                    style={{
                        left: '50%',
                        top: '50%',
                        position: 'absolute',
                        opacity: 0.8
                    }}
                />
            )}
            {!editingMethodId &&
                paymentMethods.length > 0 &&
                paymentMethods.map((method) => (
                    <OxCustomerDashboardPaymentMethod
                        key={method.id}
                        method={method}
                        setEditingMethodId={setEditingMethodId}
                    />
                ))}
            {!editingMethodId && paymentMethods.length === 0 && (
                <Styled.Text>
                    There are currently no payment methods saved against your account.
                </Styled.Text>
            )}
            {!!editingMethodId && (
                <>
                    <OxBackButton onClick={() => setEditingMethodId('')} />
                    <OxCustomerDashboardEditPaymentMethod
                        method={paymentMethods.find((method) => method.id === editingMethodId)}
                        onComplete={onComplete}
                    />
                </>
            )}
        </Styled.Container>
    );
};
