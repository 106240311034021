import styled, { css } from 'styled-components';
import { fluidSizing } from 'src/utils';

export const Container = styled.div<{ reducedWidth?: boolean }>(
    ({ reducedWidth }) => css`
        ${fluidSizing([
            { prop: 'padding-left ', values: [25, 50, 60] },
            { prop: 'padding-right ', values: [25, 50, 60] },
            { prop: 'margin-bottom', values: [25, 25, 25] },
            { prop: 'margin-top', values: [25, 25, 25] },
            {
                prop: 'max-width',
                values: reducedWidth ? [300, 300, 300] : [null, null, null]
            }
        ])}
    `
);
