import styled, { css } from 'styled-components';
import { fluidSizing } from 'src/utils';
import { OxIcon } from 'src/components/OxIcon';
import { EFonts, EFontWeight } from 'src/config/enums';
import { createTextStyle } from 'src/utils/createTextStyle';

export const Container = styled.div`
    display: flex;
    position: relative;
    user-select: none;
    flex-direction: row;
    ${fluidSizing([{ prop: 'margin-bottom', values: [22, 22, 22] }])}
`;

export const InputWithIconContainer = styled.div(
    ({ theme }) => css`
        position: relative;
        border: 1px solid ${theme.colors.primary.main};
        border-radius: 100%;
        ${fluidSizing([
            { prop: 'height', values: [16, 16, 16] },
            { prop: 'width', values: [16, 16, 16] },
            { prop: 'margin-right', values: [12, 12, 12] }
        ])}
    `
);

export const CheckmarkIcon = styled(OxIcon)(
    ({ theme }) => css`
        position: absolute;
        top: 15%;
        left: 15%;
        color: ${theme.colors.basic.white};
        ${fluidSizing([
            { prop: 'width', values: [10, 10, 10] },
            { prop: 'height', values: [11, 11, 11] }
        ])}
    `
);

export const Label = styled.label(
    ({ theme }) => css`
        display: flex;
        position: relative;
        align-items: center;
        color: ${theme.colors.primary.main};
        cursor: pointer;
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [13, 13, 13], [19, 19, 19])}
    `
);

export const Input = styled.input(
    ({ theme }) => css`
        visibility: hidden;
        opacity: 0;
        position: absolute;
        width: 0;
        height: 0;
        &:checked + ${Label} > div {
            background-color: ${theme.colors.primary.main};
        }
    `
);
