import styled, { css } from 'styled-components';
import { createTextStyle } from 'src/utils/createTextStyle';
import { EFonts, EFontWeight } from 'src/config/enums';
import { fluidSizing } from 'src/utils';
import { TArchetype } from 'src/services/api/api.types';

export const Container = styled.div<{ hide: boolean }>(
    ({ theme, hide }) => css`
        display: ${hide ? 'none' : 'flex'};
        flex-direction: column;
        background: ${theme.colors.secondary.main};
        position: relative;
        ${fluidSizing([
            { prop: 'padding-right', values: [10, 10, 10] },
            { prop: 'padding-left', values: [10, 10, 10] },
            { prop: 'padding-bottom', values: [5, 5, 5] },
            { prop: 'padding-top', values: [5, 5, 5] }
        ])}
    `
);

export const PatientName = styled.div(
    ({ theme }) => css`
        align-items: flex-end;
        color: ${theme.colors.primary.main};
        display: flex;
        justify-content: space-between;
        ${createTextStyle(EFonts.Montserrat, EFontWeight.SemiBold, [12, 12, 12], [16, 16, 16])}
        text-transform: uppercase;
        ${fluidSizing([
            { prop: 'margin-bottom', values: [15, 15, 15] },
            { prop: 'padding-right', values: [35, 35, 35] }
        ])}
    `
);

export const AppointmentData = styled.div`
    ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [12, 12, 12], [16, 16, 16])}
    ${fluidSizing([{ prop: 'margin-bottom', values: [15, 15, 15] }])}
`;

export const Text = styled.div`
    ${createTextStyle(EFonts.Montserrat, EFontWeight.Light, [12, 12, 12], [16, 16, 16])}
    ${fluidSizing([{ prop: 'margin-bottom', values: [15, 15, 15] }])}
`;

export const PatientNotes = styled.div`
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    ${fluidSizing([
        { prop: 'height', values: [10, 10, 10] },
        { prop: 'width', values: [15, 10, 10] },
        { prop: 'right', values: [25, 25, 25] },
        { prop: 'top', values: [7, 7, 7] }
    ])};
`;

export const Archetype = styled.span<{ archetype: TArchetype }>(
    ({ theme, archetype }) => css`
        background: ${archetype in theme.colors.archetype && theme.colors.archetype[archetype]};
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 50%;
        ${fluidSizing([
            { prop: 'height', values: [10, 10, 10] },
            { prop: 'width', values: [15, 10, 10] },
            { prop: 'right', values: [10, 10, 10] },
            { prop: 'top', values: [7, 7, 7] }
        ])};
    `
);
