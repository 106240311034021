import { EOxLoginFlowType } from 'src/panel/components/OxLoginFlow';
import { ERoutes } from 'src/config/enums';
import { OxLoggedInRedirect } from 'src/components/OxLoggedInRedirect';
import { OxPanelLogin } from 'src/panel/components/OxPanelLogin';
import React from 'react';
import { RouteComponentProps } from '@reach/router';

export const PanelCustomerLoginPage = (props: RouteComponentProps<unknown>): JSX.Element => {
    const state = props.location?.state ?? {};

    return (
        <OxLoggedInRedirect
            component={OxPanelLogin}
            to={ERoutes.PanelCustomerDashboard}
            variant={EOxLoginFlowType.Customer}
            state={state}
            forceReLogin={state?.forceReLogin}
        />
    );
};
