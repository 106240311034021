import React, { ChangeEvent, useEffect, useState } from 'react';
import * as Styled from './OxSearchPatient.styled';
import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import { EOxInputType } from 'src/components/OxInput';
import { OxButton } from 'src/components/OxButton';
import { OxForm } from 'src/components/OxForm';
import { TPatientSearchResult, TUser } from 'src/services/api/api.types';
import { getSearchPatients } from 'src/services/api/reception';
import { OxSearchItem } from 'src/panel/components/OxWelcomeIntenalFlow/components/OxSearchPatient/components/OxSearchItem';
import { OnyxError } from 'src/helpers/OnyxError';
import { EAlertVariant } from 'src/context/AlertContext';
import { OxIcon } from 'src/components/OxIcon';
import { ERoutes } from 'src/config/enums';
import { isNumeric } from 'src/utils/isNumeric';
import { useValidateResponse } from 'src/hooks/useValidateResponse';
import { useStore } from 'react-redux';

type TSearchPatient = {
    onSelect: (patient: TPatientSearchResult, searchTerm?: string) => void;
    centerVertically?: boolean;
    hideReturn?: boolean;
    searchTerm?: string;
};

export const OxSearchPatient = ({
    centerVertically,
    hideReturn,
    onSelect,
    searchTerm
}: TSearchPatient): JSX.Element => {
    const store = useStore();
    const { user }: { user?: TUser } = store.getState();
    const { validateResponse } = useValidateResponse();
    const [searchedName, setSearchedName] = useState<string>(searchTerm ?? '');
    const [searchPatients, setSearchPatients] = useState<TPatientSearchResult[] | null>(null);

    const onFormSubmit = async (data: FormData): Promise<void> => {
        const name = data.get('name') as string,
            params = isNumeric(name) ? { patientId: name } : { name };

        try {
            const result = await getSearchPatients({ clinicId: user?.clinicId ?? '' }, params);

            try {
                validateResponse(result);
            } catch (e) {
                console.error(e.error.title);
                return;
            }

            if (result) {
                if (Array.isArray(result)) {
                    setSearchPatients(result);
                } else {
                    setSearchPatients(Object.keys(result).map((key) => result[key]));
                }
            } else {
                throw new OnyxError({
                    type: EAlertVariant.Error,
                    header: 'ERROR ALERT: OSP1',
                    title: 'Something went wrong',
                    message:
                        'Please check your details and try again, if the problem persists please contact us.'
                });
            }
        } catch (err) {
            throw new OnyxError({
                type: EAlertVariant.Error,
                header: 'ERROR ALERT: OSP2',
                title: 'Something went wrong',
                message:
                    'Please check your details and try again, if the problem persists please contact us.'
            });
        }
    };

    useEffect(() => {
        if (searchTerm) {
            const formData = new FormData();
            formData.set('name', searchTerm);
            setSearchedName(searchTerm);
            onFormSubmit(formData);
        }
    }, []);

    return (
        <>
            {!hideReturn && (
                <Styled.Back>
                    <OxIcon name="arrow-2" />
                    <Styled.Label to={ERoutes.PanelStaffLanding}>RETURN</Styled.Label>
                </Styled.Back>
            )}
            <Styled.SearchContainer
                centerVertically={centerVertically ?? false}
                searchResult={searchPatients !== null}
            >
                <Styled.Title>Patient Search</Styled.Title>
                <OxForm onFormSubmit={onFormSubmit}>
                    {({ submitButtonProps }) => (
                        <>
                            <OxThemedInput theme={EInputTheme.GoldTransparent}>
                                <Styled.SearchInput
                                    type={EOxInputType.Text}
                                    name="name"
                                    placeholder="Enter Patient's Name"
                                    value={searchedName}
                                    onChange={(e: ChangeEvent): void => {
                                        const target = e.target as HTMLInputElement;
                                        setSearchedName(target.value);
                                    }}
                                    required
                                />
                            </OxThemedInput>
                            <OxThemedInput theme={EInputTheme.BackgroundGold}>
                                <OxButton type="submit" {...submitButtonProps} icon>
                                    SEARCH
                                </OxButton>
                            </OxThemedInput>
                        </>
                    )}
                </OxForm>
            </Styled.SearchContainer>
            {searchPatients !== null && (
                <Styled.SearchWithResultContainer>
                    <Styled.SearchResult>
                        {searchPatients?.length !== 0 ? 'Search Results' : 'No Search Results'}
                    </Styled.SearchResult>
                    {searchPatients?.map((el, index) => (
                        <OxSearchItem
                            key={index}
                            fullName={`${el.title !== null ? el.title : ''} ${el.firstname} ${
                                el.lastname
                            }`}
                            id={el.patients?.[user.clinicId].patientId || ''}
                            onSelect={(): void => onSelect(el, searchedName)}
                        />
                    ))}
                </Styled.SearchWithResultContainer>
            )}
        </>
    );
};
