import { EFontWeight, EFonts } from 'src/config/enums';
import { fluidSizing, toVw } from 'src/utils';
import styled, { css } from 'styled-components';

import { OxDashboardContentWrapper } from '../../../OxDashboardContentWrapper';
import { createTextStyle } from 'src/utils/createTextStyle';
import { OxLink } from 'src/components/OxLink';
import { hoverUnderlineAnimation } from 'src/styles/animations';

export const Container = styled(OxDashboardContentWrapper)(
    ({ theme }) => css`
        ${theme.breakpoints.only('xs')} {
            padding-left: 0;
            padding-right: 0;
            > h2,
            > a {
                padding-left: ${toVw(28, 'xs')};
                padding-right: ${toVw(28, 'xs')};
            }
        }
    `
);

export const Appointment = styled.div<{ isPast: boolean }>(
    ({ theme, isPast }) => css`
        background-color: ${isPast ? theme.colors.basic.darkOatmeal : theme.colors.secondary.main};
        ${fluidSizing([
            { prop: 'padding-top', values: [32, 32, 32] },
            { prop: 'padding-right', values: [30, 30, 30] },
            { prop: 'padding-left', values: [30, 30, 30] },
            { prop: 'padding-bottom', values: [40, 30, 30] },
            { prop: 'margin-bottom', values: [0, 40, 40] }
        ])}
    `
);

export const Text = styled.div(
    ({ theme }) => css`
        ${theme.breakpoints.only('xs')} {
            ${fluidSizing([
                { prop: 'padding-left ', values: [28, null, null] },
                { prop: 'padding-right ', values: [28, null, null] }
            ])}
        }
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Light, [13, 13, 13], [19, 19, 19])}
    `
);

export const Header = styled.h3`
    ${createTextStyle(EFonts.Hatton, EFontWeight.SemiBold, [22, 22, 22], [34, 34, 34])}
`;

export const Link = styled(OxLink)<{ inGracePeriod: boolean }>(
    ({ theme, inGracePeriod }) => css`
        ${!inGracePeriod &&
        css`
            text-decoration: line-through;
            cursor: default;
        `}
        ${inGracePeriod &&
        css`
            ${hoverUnderlineAnimation({ theme })};
        `}
    ${fluidSizing([
            { prop: 'margin-left', values: [20, 20, 20] },
            { prop: 'margin-right', values: [20, 20, 20] }
        ])}
    `
);

export const Details = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        color: ${theme.colors.primary.main};
        ${createTextStyle(EFonts.Montserrat, EFontWeight.SemiBold, [12, 12, 12], [20, 20, 20])}
        ${theme.breakpoints.only('xs')} {
            flex-direction: column;
        }
        ${theme.breakpoints.up('s')} {
            span:nth-of-type(1) {
                width: 100%;
            }
        }
        ${fluidSizing([
            { prop: 'margin-top', values: [22, 22, 22] },
            { prop: 'padding-bottom', values: [15, 15, 15] },
            { prop: 'margin-bottom', values: [22, 22, 22] }
        ])}
    `
);

export const DetailHeader = styled.span(
    ({ theme }) => css`
        border-bottom: 2px solid ${theme.colors.basic.black};
        display: flex;
        justify-content: space-between;
        ${fluidSizing([
            { prop: 'margin-bottom', values: [15, 15, 15] },
            { prop: 'margin-right', values: [15, 15, 15] },
            { prop: 'padding-bottom', values: [15, 15, 15] }
        ])}
    `
);

export const DetailEdit = styled.div`
    display: flex;
    font-weight: ${EFontWeight.Normal};
    ${fluidSizing([
        { prop: 'margin-left', values: [-20, -20, -20] },
        { prop: 'margin-right', values: [-20, -20, -20] }
    ])}
    text-transform: uppercase;
`;

export const Detail = styled.span`
    ${fluidSizing([{ prop: 'line-height', values: [30, 30, 30] }])};
`;

export const DetailLabel = styled.span(
    ({ theme }) => css`
        color: ${theme.colors.basic.black};
        font-weight: ${EFontWeight.Normal};
    `
);

export const NotInGracePeriodNotice = styled.span(
    ({ theme }) => css`
        background: ${theme.colors.basic.alabaster};
        color: ${theme.colors.primary.main};
        font-weight: ${EFontWeight.SemiBold};

        ${fluidSizing([{ prop: 'padding', values: [20, 20, 20] }])}

        a {
            display: inline-block;
            text-decoration: underline;
        }
    `
);
