import React from 'react';
import * as Styled from './OxStaffDashboardPatients.styled';
import {
    EAppointmentsType,
    OxStaffDashboardAppointments
} from 'src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardAppointments';

type TProps = {
    id: string;
    type: EAppointmentsType;
};

export const OxStaffDashboardPatients = ({ id, type }: TProps): JSX.Element => {
    return (
        <Styled.Container id={id}>
            <Styled.Title>Today&apos;s Appointments</Styled.Title>
            <OxStaffDashboardAppointments type={type} />
        </Styled.Container>
    );
};
