import styled from 'styled-components';
import { OxButton } from 'src/components/OxButton';
import { fluidSizing } from 'src/utils';

export const Button = styled(OxButton)`
    margin-left: auto;
    text-transform: uppercase;
    width: unset;

    ${fluidSizing([{ prop: 'margin-top', values: [10, 10, 10] }])}
`;
