import * as Styled from './OxMakeup.styled';

import { AlertContext, EAlertVariant } from 'src/context/AlertContext';
import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import React, { useContext, useState } from 'react';

import { OxButton } from 'src/components/OxButton';
import { OxSelect } from 'src/components/OxSelect';
import { postMakeup } from 'src/services/api/reception';

type TRefreshmentsProps = {
    patientId: string;
    onNext: () => void;
};

export const OxMakeup = ({ onNext, patientId }: TRefreshmentsProps): JSX.Element => {
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState({});
    const { showAlert } = useContext(AlertContext);

    // 'options.value' values need to exactly match the 'Internal Value' in HubSpot:
    // This unique identifier will be used when syncing with integration partners.
    // Once created it can't be edited.
    const options = [
        {
            label: 'No Makeup',
            value: 'No Makeup'
        },
        {
            label: 'Touch-up',
            value: 'Touch-up'
        },
        {
            label: 'Natural Day Look',
            value: 'Natural Day Look'
        }
    ];

    const onProceed = async (): Promise<void> => {
        setLoading(true);
        if (!selectedItem) {
            setLoading(false);
            return;
        }

        try {
            const result = await postMakeup(
                { patientId: patientId },
                { makeup: selectedItem.value }
            );

            if (result.error) {
                showAlert({
                    type: EAlertVariant.Error,
                    header: 'Error',
                    title: 'Something went wrong'
                });
            } else {
                onNext();
            }
        } catch (err) {
            setLoading(false);
            showAlert({
                type: EAlertVariant.Error,
                header: 'Error',
                title: 'Something went wrong'
            });
        }
    };

    return (
        <Styled.ContentContainer>
            <Styled.Title>Make Up</Styled.Title>
            <Styled.Info>
                At Ouronyx we believe that beauty has power. Let us know if you would like one of
                our make-up artists to apply your make-up after your treatment.
            </Styled.Info>
            <OxThemedInput theme={EInputTheme.BackgroundWhite}>
                <Styled.Inputs>
                    <OxSelect
                        items={options}
                        label="Please Select"
                        title="Please Select"
                        labelName="label"
                        valueName="value"
                        required
                        onValueChange={setSelectedItem}
                    />

                    <OxThemedInput theme={EInputTheme.BackgroundGold}>
                        <OxButton
                            onClick={onProceed}
                            icon
                            loading={loading}
                            debounceClick
                            debounceClickDeps={[selectedItem, patientId, onNext]}
                        >
                            SUBMIT
                        </OxButton>
                    </OxThemedInput>
                </Styled.Inputs>
            </OxThemedInput>
        </Styled.ContentContainer>
    );
};
