import * as Styled from './OxPanelBaristaRefreshmentList.styled';

import React, { useEffect, useRef, useState } from 'react';
import useSound from 'use-sound';

import PubSubClient from 'src/services/api/PubSubClient';
import { getWSUrl } from 'src/services/api/api.core';
import { withPrefix } from 'gatsby';
import { acknowledgeRefreshment, getPendingRefreshments } from 'src/services/api/barista';
import { useStore } from 'react-redux';
import { navigate, RouteComponentProps } from '@reach/router';
import { ERefreshmentStatus, ERoles, ERoutes } from 'src/config/enums';
import { OxContainer } from 'src/components/OxContainer';
import { OxPanelBaristaRefreshmentListItem } from './components/OxPanelBaristaRefreshmentListItem';
import { TPendingRefreshment } from 'src/services/api/api.types';
import { useValidateResponse } from 'src/hooks/useValidateResponse';
import { OxPageHelmet } from 'src/components/OxPageHelmet';

export const OxPanelBaristaRefreshmentList: React.FC<RouteComponentProps> = () => {
    const { validateResponse } = useValidateResponse();
    const store = useStore();
    const [playAlert] = useSound(withPrefix('/sounds/definite-555.mp3'));
    const playTrigger = useRef();

    const [messages, setMessages] = useState<TPendingRefreshment[]>([]);

    const triggerAlert = (): void => {
        playAlert();
    };

    const acknowledgeMessage = (id: number, baristaComment?: string): void => {
        acknowledgeRefreshment({ id }, { baristaComment }).then(() => {
            setMessages((prev) => {
                return prev.map((message) => {
                    if (message.id === id) {
                        message.status = ERefreshmentStatus.Completed;
                        message.baristaComment = baristaComment;
                    }
                    return message;
                });
            });
        });
    };

    const getFilteredMessageList = (status: ERefreshmentStatus): TPendingRefreshment[] =>
        messages.filter((message) => message.status === status);

    useEffect(() => {
        const { user } = store.getState(),
            pollingInterval = 10 * 1000; // 10s * 1000ms
        let unmounted = false,
            polling: number | undefined;

        if (!user.roles.includes(ERoles.Barista)) {
            navigate(ERoutes.PanelStaffLanding);
            return;
        }

        const getAllMessages = async (): Promise<void> => {
            getPendingRefreshments({ clinicId: user.clinicId })
                .then((data) => validateResponse(data))
                .then((data) => {
                    if (!unmounted) {
                        setMessages(data);
                    }
                })
                .catch((e) => console.error(e.error.title));
        };

        getAllMessages();

        const startPolling = (): void => {
            if (!polling) {
                polling = setInterval(() => {
                    getAllMessages();
                }, pollingInterval);
            }
        };

        const stopPolling = (): void => {
            if (polling) {
                clearInterval(polling);
                polling = undefined;
            }
        };

        const topicName = 'barista/channel';

        const ws = new PubSubClient(getWSUrl(), {
            connect: true,
            reconnect: true,
            debug: false,
            onConnect: stopPolling,
            onDisconnect: startPolling,
            onConnectError: startPolling
        });

        ws.subscribe(topicName, (_uri, message): void => {
            if (typeof message === 'object') {
                getAllMessages();
                const current = playTrigger?.current as HTMLButtonElement | undefined;
                current?.click();
            }
        });

        return (): void => {
            unmounted = true;
            ws.disconnect();
        };
    }, []);

    return (
        <OxContainer>
            <OxPageHelmet title="Refreshments" />
            <Styled.HeaderWrapper>
                <Styled.BackButton
                    onClick={(): void => {
                        navigate(-1);
                    }}
                />
                <Styled.Header>Refreshments</Styled.Header>
            </Styled.HeaderWrapper>
            <Styled.MessageListContainer>
                <Styled.MessageListContainerHeader>
                    Today&apos;s Refreshment Requests
                </Styled.MessageListContainerHeader>
                <Styled.MessageList>
                    <Styled.MessageListTitle>
                        New ({getFilteredMessageList(ERefreshmentStatus.Pending).length})
                    </Styled.MessageListTitle>
                    <Styled.Messages>
                        {getFilteredMessageList(ERefreshmentStatus.Pending).map((message) => (
                            <OxPanelBaristaRefreshmentListItem
                                key={message.id}
                                message={message}
                                acknowledgeMessage={acknowledgeMessage}
                            />
                        ))}
                    </Styled.Messages>
                </Styled.MessageList>
                <Styled.MessageList completed={true}>
                    <Styled.MessageListTitle>Completed</Styled.MessageListTitle>
                    <Styled.Messages>
                        {getFilteredMessageList(ERefreshmentStatus.Completed)
                            .sort((a, b) =>
                                a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0
                            )
                            .map((message) => (
                                <OxPanelBaristaRefreshmentListItem
                                    key={message.id}
                                    message={message}
                                />
                            ))}
                    </Styled.Messages>
                </Styled.MessageList>
            </Styled.MessageListContainer>

            <Styled.SoundButton onClick={triggerAlert} ref={playTrigger} />
        </OxContainer>
    );
};
