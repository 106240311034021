import styled from 'styled-components';

import { OxContainer } from 'src/components/OxContainer';
import { fluidSizing } from 'src/utils';
import { ESizes } from 'src/config/enums';

export const Container = styled(OxContainer)`
    @media print {
        ${fluidSizing([
            {
                prop: 'padding-left',
                values: [ESizes.GutterXS, ESizes.GutterXS, ESizes.GutterXS]
            },
            {
                prop: 'padding-right',
                values: [ESizes.GutterXS, ESizes.GutterXS, ESizes.GutterXS]
            }
        ])}
    }
`;
