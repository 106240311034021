import * as Styled from './OxCustomerDashboardEditPaymentMethod.styled';

import React, { useContext, useState } from 'react';
import { TStripePaymentMethod } from 'src/services/api/api.types';
import { OxButton } from 'src/components/OxButton';
import { graphql, useStaticQuery } from 'gatsby';
import { CardElement, Elements } from '@stripe/react-stripe-js';
import { getStripePromiseInstance } from 'src/services/stripe';
import { OxCustomerDashboardEditPaymentCardForm } from 'src/panel/components/OxPanelCustomerDashboard/components/OxCustomerDashboardSavedPaymentDetails/components/OxCustomerDashboardEditPaymentMethod/components/OxCustomerDashboardEditPaymentCardForm';
import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import { deleteUserMethod, newUserIntent } from 'src/services/api/stripe';
import { AlertContext, EAlertVariant } from 'src/context/AlertContext';
import { OnyxError } from 'src/helpers/OnyxError';
import stripeJs from '@stripe/stripe-js';
import { useStore } from 'react-redux';
import { useValidateResponse } from 'src/hooks/useValidateResponse';
import { CONFIG_PATH } from 'src/services/api/config';
import { WebsiteDataContext } from 'src/context/WebsiteDataContext';

type TProps = {
    method?: TStripePaymentMethod;
    onComplete: () => void;
};

type TCountry = {
    text: string;
    dial_code: string;
    value: string;
};

export const OxCustomerDashboardEditPaymentMethod = ({
    method,
    onComplete
}: TProps): JSX.Element => {
    const { validateResponse } = useValidateResponse();
    const { getConfigValue } = useContext(WebsiteDataContext);
    const { showAlert } = useContext(AlertContext);
    const store = useStore();
    const { user } = store.getState();

    const [loading, setLoading] = useState<boolean>(false);
    const [stripe, setStripe] = useState<stripeJs.Stripe | null>(null);
    const [elements, setElements] = useState<stripeJs.StripeElements | null>(null);

    const data = useStaticQuery(
        graphql`
            query {
                countries: allCountriesJson {
                    nodes {
                        text
                        value
                    }
                }
            }
        `
    );
    const countries: TCountry[] = data.countries.nodes;
    const methodCountry = method?.billing_details.address.country;
    const stripePK = getConfigValue(CONFIG_PATH.STRIPE_PUBLISHABLE_KEY, user.clinic.id);
    const stripePromise = getStripePromiseInstance(stripePK);

    const createNewPaymentMethod = async (data: FormData): Promise<void> => {
        // Get new user intent from API
        const newIntent = await newUserIntent();

        try {
            // Check response is 200
            validateResponse(newIntent);
        } catch (e) {
            throw new OnyxError({
                type: EAlertVariant.Error,
                header: 'ERROR ALERT ID: CAS1',
                message:
                    'Sorry, there was an error while updating payment, if the problem persists please contact us.',
                additionalInfo: e.message ?? 'Payment intent failed'
            });
        }

        // Check it's returned a secret
        if (!newIntent?.secret) {
            throw new OnyxError({
                type: EAlertVariant.Error,

                header: 'ERROR ALERT ID: CAS2',
                message: 'Intent not found in returned data'
            });
        }

        // Using secret, and form data, create new payment
        const { setupIntent, error } = (await stripe?.confirmCardSetup(newIntent.secret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    address: {
                        country: data.get('country') as string
                    },
                    email: user.email,
                    name: `${user.firstname} ${user.lastname}`,
                    phone: user.mobile
                }
            }
        })) ?? { setupIntent: undefined };

        // Check for error and that new setupIntent has been returned
        if (error || !setupIntent) {
            // await deleteUserMethod({id: newIntent.secret});
            throw new OnyxError({
                type: EAlertVariant.Error,
                header: 'ERROR ALERT ID: CAS3',
                message:
                    'Sorry, there was an error while updating payment, if the problem persists please contact us.',
                additionalInfo: error?.message ?? 'Stripe confirmCardSetup failed'
            });
        }

        // Delete old intent
        const deleteIntent = await deleteUserMethod({ id: method?.id ?? '' });

        try {
            validateResponse(deleteIntent);
        } catch (e) {
            throw new OnyxError({
                type: EAlertVariant.Error,
                header: 'ERROR ALERT ID: CAS4',
                message:
                    'Sorry, there was an error while updating payment, if the problem persists please contact us.',
                additionalInfo: e.message ?? 'Old payment deletion failed'
            });
        }

        // Done!

        showAlert({
            type: EAlertVariant.Success,
            header: 'Successfully changed payment method'
        });

        onComplete && onComplete();
    };

    const onSubmit = async (data: FormData): Promise<void> => {
        if (!stripe || !elements || loading) return;
        setLoading(true);

        try {
            await createNewPaymentMethod(data);
        } catch (e) {
            showAlert(e.error);
        }

        setLoading(false);
    };

    return (
        <Styled.Form onFormSubmit={onSubmit}>
            <OxThemedInput theme={EInputTheme.GoldAlternative}>
                {stripePK && !!stripePromise && (
                    <Elements key={stripePK} stripe={stripePromise}>
                        <OxCustomerDashboardEditPaymentCardForm
                            setStripe={setStripe}
                            setElements={setElements}
                        />
                    </Elements>
                )}
                <Styled.Select
                    name="country"
                    title="Country"
                    items={countries}
                    initialValue={
                        (methodCountry &&
                            countries.find((country) => country.value === methodCountry)) ??
                        undefined
                    }
                    labelName="text"
                    valueName="value"
                    required
                />
                <OxThemedInput theme={EInputTheme.BackgroundGold}>
                    <OxButton type="submit" icon loading={loading} disabled={loading}>
                        Update Payment Details
                    </OxButton>
                </OxThemedInput>
            </OxThemedInput>
        </Styled.Form>
    );
};
