import styled, { css } from 'styled-components';
import { OxInput } from 'src/components/OxInput';
import { fluidSizing } from 'src/utils';

export const VolumeInput = styled(OxInput)<{ isFirst?: boolean }>(
    ({ isFirst }) => css`
        ${!isFirst && fluidSizing([{ prop: 'margin-top', values: [10, 10, 10] }])}
    `
);

export const BotoxVolume = styled.div`
    align-items: center;
    display: flex;
    ${fluidSizing([{ prop: 'height', values: [30, 30, 30] }])}
`;
