import styled, { css } from 'styled-components';
import { fluidSizing } from 'src/utils';
import { EFonts, EFontWeight } from 'src/config/enums';
import { createTextStyle } from 'src/utils/createTextStyle';
import { OxBackButton } from 'src/components/OxBackButton';

export const HeaderWrapper = styled.div(
    () => css`
        flex: 1 1 100%;
        position: relative;
        text-align: center;

        ${createTextStyle(EFonts.Hatton, EFontWeight.Medium, [16, 16, 16], [18, 18, 18])}
    `
);

export const BackButton = styled(OxBackButton)(
    ({ theme }) => css`
        color: ${theme.colors.primary.main};
        ${fluidSizing([
            { prop: 'padding-bottom', values: [20, 20, 20] },
            { prop: 'margin-bottom', values: [40, 40, 40] }
        ])}
    `
);

export const Header = styled.h1(
    ({ theme }) => css`
        border-bottom: 0.1rem solid ${theme.colors.basic.black};
        display: inline-block;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);

        ${fluidSizing([
            { prop: 'padding-left', values: [40, 40, 40] },
            { prop: 'padding-right', values: [40, 40, 40] },
            { prop: 'padding-bottom', values: [10, 10, 10] }
        ])}

        ${createTextStyle(EFonts.Hatton, EFontWeight.SemiBold, [18, 18, 18], [24, 30, 30])}
    `
);

export const MessageListContainer = styled.div(
    ({ theme }) => css`
        background: ${theme.colors.basic.lightPanel};
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;

        ${theme.breakpoints.up('s')} {
            flex-direction: row;
        }

        ${fluidSizing([
            { prop: 'padding-top', values: [15, 15, 15] },
            { prop: 'padding-bottom', values: [15, 15, 15] }
        ])}
    `
);

export const MessageListContainerHeader = styled.div`
    flex: 1 1 100%;
    text-align: center;
    ${createTextStyle(EFonts.Hatton, EFontWeight.Medium, [24, 24, 24], [24, 40, 50])}
`;

export const MessageList = styled.div<{ completed?: boolean }>(
    ({ completed }) => css`
        flex: 1 1 50%;
        text-align: center;
        ${completed &&
        css`
            opacity: 0.5;
        `}

        ${fluidSizing([
            { prop: 'max-width', values: [null, 400, 400] },
            { prop: 'padding-left', values: [0, 25, 25] },
            { prop: 'padding-right', values: [0, 25, 25] }
        ])}
    `
);

export const MessageListTitle = styled.div(
    ({ theme }) => css`
        border-bottom: 1px solid ${theme.colors.basic.black};

        ${createTextStyle(EFonts.Montserrat, EFontWeight.SemiBold, [13, 13, 13], [30, 30, 30])}

        ${fluidSizing([
            { prop: 'margin-bottom', values: [10, 10, 10] },
            { prop: 'margin-top', values: [10, 10, 10] },
            { prop: 'padding-bottom', values: [10, 10, 10] }
        ])}
    `
);

export const Messages = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SoundButton = styled.button`
    visibility: hidden;
`;
