import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { getAppointmentTimesByClinic, getAppointmentTimesByPractitioner } from 'src/services/api';

import { BookingFlowContext } from 'src/context/BookingFlowContext';
import { DateTime } from 'luxon';
import { OxBookingStage } from '../OxBookingStage';
import { OxCalendar } from 'src/components/OxCalendar';
import { OxSpinner } from 'src/components/OxSpinner';
import { TAppointmentState, TOxStageProps } from '../../OxBookingStep5';
import { TAppointmentDate, TError } from 'src/services/api/api.types';
import { useValidateResponse } from 'src/hooks/useValidateResponse';
import { fireEvent } from 'src/helpers/TagManager';

export type TOxBookingStage1Data = {
    appointmentDates?: TAppointmentDate[];
    selectedDate?: DateTime;
};

export type TOxBookingStage1Props = TOxStageProps & {
    appointmentsState: TAppointmentState[];
    setAppointmentsState: Dispatch<SetStateAction<TAppointmentState[]>>;
    appointmentStateUpdated: number;
    setAppointmentStateUpdated: Dispatch<SetStateAction<number>>;
    minDate?: DateTime;
    maxDate?: DateTime;
};

export const OxBookingStage1 = ({
    stepIsActive,
    stageIndex,
    currentStage,
    onDataUpdate,
    appointmentsState,
    setAppointmentsState,
    appointmentStateUpdated,
    setAppointmentStateUpdated,
    minDate,
    maxDate
}: TOxBookingStage1Props): JSX.Element => {
    const { validateResponse } = useValidateResponse();
    const bookingFlowContext = useContext(BookingFlowContext);
    const appointmentTypeId = bookingFlowContext.state.appointmentTypeId || '';
    const consultationDate = bookingFlowContext.state.consultationDate;
    const consultationLocationId = parseInt(bookingFlowContext.state.consultationLocationId || '0');
    const practitionerId = bookingFlowContext.state.practitioner?.practitionerId || '';

    const [defaultActiveStartDate, setDefaultActiveStartDate] = useState<Date>();
    const [loadingMonths, setLoadingMonths] = useState<string[]>([]);
    const [currentMonth, setCurrentMonth] = useState<DateTime>();

    const getAppointmentState = (
        appointmentTypeId: string,
        practitionerId: string,
        clinicId: number
    ): TAppointmentState | undefined => {
        const data =
            appointmentsState.find((item: TAppointmentState) => {
                return (
                    item.appointmentTypeId === appointmentTypeId &&
                    item.practitionerId === practitionerId &&
                    item.clinicId === clinicId
                );
            }) || undefined;

        return data;
    };

    const getCurrentAppointmentState = (): TAppointmentState | undefined => {
        return getAppointmentState(appointmentTypeId, practitionerId, consultationLocationId);
    };

    const addDatesToState = (
        date: string,
        appointmentTypeId: string,
        practitionerId: string,
        clinicId: number,
        dates: TAppointmentDate[]
    ): void => {
        setAppointmentsState((state) => {
            const stateIndex = state.findIndex(
                (obj) =>
                    obj.appointmentTypeId === appointmentTypeId &&
                    obj.practitionerId === practitionerId &&
                    obj.clinicId === clinicId
            );
            if (stateIndex !== -1) {
                state[stateIndex].downloadingMonths.add(date);
                const existingDates = dates.map((date) => date.startsAt);
                state[stateIndex].dates = [
                    ...state[stateIndex].dates.filter(
                        (date) => !existingDates.includes(date.startsAt)
                    ),
                    ...dates
                ];
            } else {
                state.push({
                    appointmentTypeId: appointmentTypeId,
                    practitionerId: practitionerId,
                    clinicId: clinicId,
                    downloadingMonths: new Set([date]),
                    dates: dates || []
                });
            }
            setAppointmentStateUpdated((prev) => prev + 1);
            return state;
        });
    };

    const getStartOfMonthFromDate = (date: DateTime): string => {
        return date.set({ day: 1 }).toISODate().toString();
    };

    const fetchAppointmentDates = async (date: DateTime): Promise<void> => {
        const dateValue = getStartOfMonthFromDate(date || DateTime.utc());

        const appointmentState: TAppointmentState | undefined = getAppointmentState(
            appointmentTypeId,
            practitionerId,
            consultationLocationId
        );

        if (!appointmentState?.downloadingMonths.has(dateValue)) {
            // To add the month being fetched to the downloadingMonths array
            // in order to stop months being fetched again before the
            // async functions below have finished
            addDatesToState(
                dateValue,
                appointmentTypeId,
                practitionerId,
                consultationLocationId,
                []
            );

            let dates: TAppointmentDate[] | TError | null = [];
            setLoadingMonths((prev) => [...new Set([...prev, dateValue])]);

            if (practitionerId) {
                dates = await getAppointmentTimesByPractitioner({
                    clinicId: consultationLocationId || '',
                    appointmentTypeId: appointmentTypeId || '',
                    practitionerId: practitionerId || '',
                    date: dateValue
                });
            } else {
                dates = await getAppointmentTimesByClinic({
                    clinicId: consultationLocationId,
                    appointmentTypeId: appointmentTypeId,
                    date: dateValue
                });
            }
            setLoadingMonths((prev) => [...prev.filter((val) => val !== dateValue)]);

            try {
                validateResponse(dates);
            } catch (e) {
                console.error(e.error.title);
                return;
            }

            addDatesToState(
                dateValue,
                appointmentTypeId,
                practitionerId,
                consultationLocationId,
                dates || []
            );

            if (
                dates?.length === 0 &&
                (date?.diff(currentMonth || DateTime.local(), 'months').months || 0) < 5
            ) {
                fetchAppointmentDates(date.plus({ month: 1 }));
            }
        }
    };

    const handleMonthChange = (month: DateTime): void => {
        if (stepIsActive) {
            setCurrentMonth(month);
            fetchAppointmentDates(month);
        }
    };

    const handleDateChange = async (date: DateTime): Promise<void> => {
        fireEvent({ event: 'DateClickedInDateSelect' });
        onDataUpdate({
            selectedDate: date
        });
    };

    const onStageStart = (): void => {
        fetchAppointmentDates(consultationDate || currentMonth || DateTime.utc());
    };

    useEffect(() => {
        if ((getCurrentAppointmentState()?.dates.length || 0) > 0) {
            const date = DateTime.fromISO(
                getCurrentAppointmentState()?.dates[0].startsAt
            ).toJSDate();
            setDefaultActiveStartDate(date);
        }

        onDataUpdate({
            appointmentDates: getCurrentAppointmentState()?.dates
        });
    }, [appointmentStateUpdated, appointmentTypeId, practitionerId, consultationLocationId]);

    return (
        <OxBookingStage
            stepIsActive={stepIsActive}
            currentStage={currentStage}
            stageIndex={stageIndex}
            onStageStart={onStageStart}
        >
            <>
                {(!defaultActiveStartDate ||
                    (currentMonth &&
                        loadingMonths.includes(getStartOfMonthFromDate(currentMonth)))) && (
                    <OxSpinner
                        addTranslate
                        style={{
                            left: '50%',
                            top: '50%',
                            position: 'absolute',
                            opacity: 0.8
                        }}
                    />
                )}
                {!!defaultActiveStartDate && (
                    <OxCalendar
                        selectedDate={consultationDate}
                        clickableDates={getCurrentAppointmentState()
                            ?.dates.filter((date) => date.available)
                            .map((date) => date.startsAt)}
                        onDateSelect={handleDateChange}
                        onMonthChange={handleMonthChange}
                        defaultActiveStartDate={defaultActiveStartDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        // canBack={false}
                    />
                )}
            </>
        </OxBookingStage>
    );
};
