import * as Styled from './OxStaffDashboardPsychologicalAttributesSocialGoals.styled';
import * as StyledCommon from '../../OxStaffDashboardPsychologicalAttributes.styled';

import React from 'react';

type TProps = {
    socialGoals: string[];
};

export const OxStaffDashboardPsychologicalAttributesSocialGoals = (props: TProps): JSX.Element => {
    return (
        <>
            {props.socialGoals.length > 0 && (
                <StyledCommon.ItemContainer>
                    <StyledCommon.Number>
                        <StyledCommon.NumberInner>
                            <svg
                                width="123"
                                height="123"
                                viewBox="0 0 123 123"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="61.5" cy="61.5" r="61.5" fill="#0B0600" />
                                <path
                                    d="M46 59C52.28 52.4 56.56 45.4 58.84 38H68.62C68.3 40.8 68.1 43.02 68.02 44.66C67.98 46.26 67.96 48.32 67.96 50.84V73.16C67.96 75.72 68 77.84 68.08 79.52C68.16 81.16 68.34 83.32 68.62 86H58.6C58.88 83.32 59.04 81.16 59.08 79.52C59.16 77.84 59.2 75.72 59.2 73.16V40.7C58.28 47.86 56.1 54.96 52.66 62L46 59Z"
                                    fill="white"
                                />
                            </svg>
                        </StyledCommon.NumberInner>
                    </StyledCommon.Number>
                    <StyledCommon.Header>Social Goals</StyledCommon.Header>
                    <StyledCommon.ItemContent>
                        <Styled.LeftBall />
                        <Styled.LeftNumber>1</Styled.LeftNumber>
                        <Styled.HBar />
                        <Styled.RightNumber>{props.socialGoals.length + 1}</Styled.RightNumber>
                        <Styled.RightBall />
                        <Styled.Gradient>
                            {props.socialGoals.map((goal, index) => (
                                <Styled.Goal
                                    key={index}
                                    index={index + 1}
                                    total={props.socialGoals.length + 1}
                                >
                                    <Styled.GoalText index={index + 1}>{goal}</Styled.GoalText>
                                </Styled.Goal>
                            ))}
                        </Styled.Gradient>
                    </StyledCommon.ItemContent>
                </StyledCommon.ItemContainer>
            )}
        </>
    );
};
