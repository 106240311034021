import * as Styled from './OxTable.styled';

import React from 'react';

type TProps = {
    columnTitles: (string | null)[];
    rows: (string | JSX.Element)[][];
    rowKeys?: string[];
    rowsToHideOnPrint?: number[];
};

/**
 * we're avoiding using thead/th as it messes up on print
 * @param columnTitles
 * @param rows
 * @param rowKeys
 * @param rowsToHideOnPrint
 * @constructor
 */
export const OxTable = ({
    columnTitles,
    rows,
    rowKeys,
    rowsToHideOnPrint
}: TProps): JSX.Element => {
    return (
        <Styled.Table>
            <tbody>
                <Styled.Head>
                    {columnTitles.map((title, index) => (
                        <Styled.HeadCell key={index} last={index + 1 === columnTitles.length}>
                            {title}
                        </Styled.HeadCell>
                    ))}
                </Styled.Head>
                {rows.map((row, index) => (
                    <Styled.Row
                        key={rowKeys ? rowKeys[index] : index}
                        hideOnPrint={(rowsToHideOnPrint ?? []).includes(index)}
                    >
                        {row.map((cell, index) => (
                            <Styled.Cell key={index}>{cell}</Styled.Cell>
                        ))}
                    </Styled.Row>
                ))}
            </tbody>
        </Styled.Table>
    );
};
