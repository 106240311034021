import React from 'react';

import { OxStaffDashboardFeedback } from 'src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardFeedback';
import { RouteComponentProps, WindowLocation } from '@reach/router';
import { OxStaffDashboardRoot } from '../OxStaffDashboardRoot';
import { OxPageHelmet } from 'src/components/OxPageHelmet';

type TProps<RouteComponentProps> = RouteComponentProps & {
    location: WindowLocation | undefined;
};

export const OxStaffDashboardAllFeedback = ({
    location
}: TProps<RouteComponentProps>): JSX.Element => {
    return (
        <OxStaffDashboardRoot location={location} noPadding={true}>
            <OxPageHelmet title="Feedback" />
            <OxStaffDashboardFeedback
                showAll={true}
                preFetchedFeedback={location?.state?.preFetchedFeedback}
            />
        </OxStaffDashboardRoot>
    );
};
