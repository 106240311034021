import { ERoles, ETreatmentNoteStatus } from 'src/config/enums';
import {
    TAppointment,
    TClinic,
    TDearProduct,
    TPractitioner,
    TTreatmentAestheticianNote,
    TTreatmentNote
} from 'src/services/api/api.types';

export const shouldHideTreatmentNotesButton = (
    roles: ERoles[],
    clinic: TClinic,
    patientId: string,
    appointment?: TAppointment,
    treatmentNote?: TTreatmentNote,
    treatmentAesthetician?: TTreatmentAestheticianNote,
    isPatientProfile?: boolean,
    doctors?: TPractitioner[]
): boolean => {
    /** Hide if:
     *   We are on the patient profile
     *   No clinic set
     *   Both appointmentId and patientId is unset
     *   Practitioner is not set
     *       (if practitioner is set then we're not in the pre-consultation appointment and notes can be made)
     *   User is not practitioner, nurse, or receptionist
     *   User is practitioner and treatment note has not yet been created
     *   COMMENTED OUT - User is receptionist and existing treatment notes are not yet finalised or locked
     */
    return (
        isPatientProfile ||
        !clinic ||
        (!appointment?.id && !patientId) ||
        clinic?.preTreatmentAppointmentTypeId === appointment?.appointmentTypeId ||
        clinic?.virtualAppointmentTypeId === appointment?.appointmentTypeId ||
        !(
            ((roles.includes(ERoles.Practitioner) && treatmentNote) || roles.includes(ERoles.Nurse))
            // ||
            // (roles.includes(ERoles.Receptionist) &&
            //   treatmentNotes.find(note =>
            //     [ETreatmentNoteStatus.Final, ETreatmentNoteStatus.Locked].includes(
            //       note.status
            //     )
            //   ))
        ) ||
        treatmentAesthetician ||
        isFacialAestheticianAppointmentType(appointment, doctors)
    );
};

export const shouldHideAestheticianNotesButton = (
    appointment?: TAppointment,
    doctors?: TPractitioner[]
): boolean => {
    return !isFacialAestheticianAppointmentType(appointment, doctors);
};

type TButtonMessageMapNoteStatus = ETreatmentNoteStatus | 'default';

type TButtonMessageMap = {
    [key in ERoles]?: { [key in TButtonMessageMapNoteStatus]: string };
};

const isFacialAestheticianAppointmentType = (
    appointment?: TAppointment,
    doctors?: TPractitioner[]
): boolean => {
    if (doctors) {
        if (doctors.find((doctor) => doctor.practitionerId === appointment?.practitionerId)) {
            return false;
        }
    }

    if (appointment?.appointmentType) {
        if (
            appointment.appointmentType === 'Medical Facial Treatment' ||
            appointment.appointmentType === 'Facial Resurfacing Treatment' ||
            appointment.appointmentType === 'Hair Loss Prevention (Tricopat)' ||
            appointment.appointmentType === 'Microneedling RF Face and Neck Treatment' ||
            appointment.appointmentType === 'Microneedling RF Facial Treatment' ||
            appointment.appointmentType === 'Resurfacing Treatment for Face and Neck' ||
            appointment.appointmentType === 'Marketing: FOC Treatment'
        ) {
            return true;
        }
    }
    return false;
};

const buttonMessageMap: TButtonMessageMap = {
    [ERoles.Practitioner]: {
        default: 'Enter',
        [ETreatmentNoteStatus.Draft]: 'Review',
        [ETreatmentNoteStatus.Locked]: 'View',
        [ETreatmentNoteStatus.Final]: 'View'
    },
    [ERoles.Nurse]: {
        default: 'Enter',
        [ETreatmentNoteStatus.Draft]: 'Edit',
        [ETreatmentNoteStatus.Locked]: 'View',
        [ETreatmentNoteStatus.Final]: 'View'
    },
    [ERoles.Receptionist]: {
        default: '',
        [ETreatmentNoteStatus.Draft]: 'View',
        [ETreatmentNoteStatus.Locked]: 'View',
        [ETreatmentNoteStatus.Final]: 'View'
    }
};

export const getTreatmentButtonMessage = ({
    roles,
    treatmentNote,
    displayId = false
}: {
    roles: ERoles[];
    treatmentNote?: TTreatmentNote;
    displayId?: boolean;
}): string => {
    const getTreatmentNotesTitleVerb = (): string => {
        for (const key in buttonMessageMap) {
            if (roles.includes(key as ERoles)) {
                return buttonMessageMap[key as ERoles]?.[treatmentNote?.status ?? 'default'] ?? '';
            }
        }

        return '';
    };

    const verb = getTreatmentNotesTitleVerb();

    return verb
        ? `${verb} Treatment Notes${
              displayId && treatmentNote ? ` (${treatmentNote.treatmentNumber})` : ''
          }`
        : '';
};

//treatment products
export const isBotox = (id?: number): boolean => id === 1;
export const isHyalase = (id?: number): boolean => id === 8;

//dear products
export const dearIsBotox = (product: TDearProduct | undefined): boolean =>
    product?.category.toLowerCase() === 'toxin';
export const dearIsHyalase = (product: TDearProduct | undefined): boolean =>
    product?.category.toLowerCase() === 'hyalase powder';
