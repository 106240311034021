import * as Styled from 'src/components/OxMultiSelect/OxMultiSelect.styled';

import React, { ReactNode, useContext, useEffect, useState } from 'react';

import { ThemedInputContext } from 'src/context/ThemedInputContext';
import { Option } from 'react-multi-select-component/src/lib/interfaces';
import { OxCheckbox } from 'src/components/OxCheckbox';

type TProps = {
    title?: string;
    name: string;
    labelName?: string;
    valueName?: string;
    items: (object | string)[];
    initialValues?: (object | string)[];
    onValueChange?: (values: any[] | undefined) => void;
    className?: string;
    required?: boolean;
    hasSelectAll?: boolean;
    disabled?: boolean;
    disableSearch?: boolean;
};

export const OxMultiSelect = ({
    title,
    name,
    initialValues,
    items,
    valueName,
    labelName,
    onValueChange,
    className,
    required,
    hasSelectAll,
    disabled,
    disableSearch
}: TProps): JSX.Element => {
    const themeContext = useContext(ThemedInputContext);

    const getValue = (item?: object | string): string | undefined => {
        if (typeof item === 'string') return item;
        if (typeof item === 'object') {
            if (!valueName) throw Error('valueName prop is required');
            return `${(item as { [key: string]: unknown })[valueName]}`;
        }
        return undefined;
    };

    const getLabel = (item?: object | string): string | undefined => {
        if (typeof item === 'string') return item;
        if (typeof item === 'object') {
            if (!labelName) throw Error('labelName prop is required');
            return `${(item as { [key: string]: unknown })[labelName]}`;
        }
        return undefined;
    };

    const labelValueMap = (item: object | string): Option => ({
        label: getLabel(item),
        value: getValue(item)
    });

    const [selectedValues, setSelectedValues] = useState<Option[]>(
        initialValues?.map(labelValueMap) ?? []
    );
    const [touched, setTouched] = useState(false);

    const handleChange = (selectedList: Option[]) => {
        setSelectedValues(selectedList);
        setTouched(true);
    };

    useEffect(() => {
        onValueChange &&
            onValueChange(
                selectedValues.map((selectedValue) =>
                    items.find((item: object | string) => getValue(item) === selectedValue.value)
                )
            );
    }, [selectedValues]);

    const ItemRenderer = ({
        checked,
        option,
        onClick,
        disabled
    }: {
        checked: boolean;
        option: Option;
        disabled?: boolean;
        onClick: (e: MouseEvent) => void;
    }): ReactNode => (
        <OxCheckbox
            className={`item-renderer ${disabled && 'disabled'}`}
            id={`multiselect-${name}-${option.value}`}
            checkStyle="alt"
            label={option.label}
            name={option.label}
            value={option.value}
            checked={checked}
            disabled={disabled}
            onValueChange={(val: string, e: MouseEvent): void => {
                onClick(e);
            }}
        />
    );

    return (
        <Styled.Container className={className}>
            <Styled.Multiselect
                customTheme={themeContext}
                options={items.map(labelValueMap)}
                value={selectedValues}
                labelledBy={labelName ?? ''}
                onChange={handleChange}
                touched={touched}
                invalid={required && selectedValues.length === 0}
                hasSelectAll={hasSelectAll}
                disabled={disabled}
                disableSearch={disableSearch}
                overrideStrings={
                    title
                        ? {
                              selectSomeItems: title
                          }
                        : {}
                }
                ItemRenderer={ItemRenderer}
            />
        </Styled.Container>
    );
};
