import styled, { css } from 'styled-components';
import { fluidSizing } from 'src/utils';
import { createTextStyle } from 'src/utils/createTextStyle';
import { EFonts, EFontWeight } from 'src/config/enums';
import { OxIcon } from 'src/components/OxIcon';

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        background-color: ${theme.colors.basic.lightPanel};
        ${fluidSizing([{ prop: 'padding', values: [40, 40, 40] }])}
    `
);

export const Header = styled.div`
    text-align: center;
    ${createTextStyle(EFonts.Hatton, EFontWeight.Normal, [35, 35, 35], [35, 35, 35])}
`;

export const Text = styled.div`
    ${createTextStyle(EFonts.Montserrat, EFontWeight.Light, [12, 12, 12], [20, 20, 20])}
    ${fluidSizing([{ prop: 'margin-top', values: [20, 20, 20] }])}
`;

export const DragDropContainer = styled.div`
    display: flex;
    flex-direction: row;
    ${fluidSizing([
        { prop: 'margin-top', values: [30, 30, 30] },
        { prop: 'margin-bottom', values: [15, 15, 15] }
    ])}
`;

export const PointsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const DragDropContentContainer = styled.div`
    border-radius: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    ${fluidSizing([{ prop: 'margin-left', values: [10, 10, 10] }])};
`;

export const PointContainer = styled.div(
    ({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: ${theme.colors.basic.white};
        ${fluidSizing([
            { prop: 'width', values: [40, 40, 40] },
            { prop: 'height', values: [40, 40, 40] },
            { prop: 'margin-bottom', values: [15, 15, 15] }
        ])}
    `
);

export const Point = styled.div(
    ({ theme }) => css`
        border-radius: 50%;
        background-color: ${theme.colors.primary.main};
        color: ${theme.colors.basic.white};
        text-align: center;
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Bold, [14, 14, 14], [35, 35, 35])}
        ${fluidSizing([
            { prop: 'width', values: [35, 35, 35] },
            { prop: 'height', values: [35, 35, 35] }
        ])}
    `
);

export const DraggableItem = styled.div(
    ({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: ${(props) =>
            props.isDragging ? `${theme.colors.primary.main}` : `${theme.colors.basic.white}`};
        color: ${(props) =>
            props.isDragging ? `${theme.colors.basic.white}` : `${theme.colors.basic.black}`};
        border-radius: 40px;
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [12, 12, 12], [12, 12, 12])}
        ${fluidSizing([
            { prop: 'height', values: [40, 40, 40] },
            { prop: 'padding-left', values: [20, 20, 20] },
            { prop: 'padding-right', values: [20, 20, 20] },
            { prop: 'margin-bottom', values: [15, 15, 15] }
        ])}
    `
);

export const DragIcon = styled(OxIcon)(
    ({ theme }) => css`
        color: ${theme.colors.primary.main};
        ${fluidSizing([
            { prop: 'width', values: [20, 20, 20] },
            { prop: 'height', values: [26, 26, 26] }
        ])}
    `
);

export const SpinnerContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    ${fluidSizing([
        { prop: 'margin-top', values: [20, 20, 20] },
        { prop: 'margin-bottom', values: [20, 20, 20] }
    ])}
`;
