import React, { useEffect, useState } from 'react';

import { ERoutes } from 'src/config/enums';
import { navigate } from 'gatsby';
import { setUser } from 'src/panel/store/user/userActions';
import { useStore } from 'react-redux';
import { userGetUser } from 'src/services/api';

export const OxPrivateRoute = ({ component: Component, location, ...rest }: any) => {
    const store = useStore();
    const state = store.getState();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const asyncUserFetch = async (): Promise<void> => {
            const user = await userGetUser();
            if (user && user.email) {
                store.dispatch(setUser(user));
            }
            setLoading(false);
        };

        if (!state.user) {
            asyncUserFetch();
        } else {
            setLoading(false);
        }
    });

    if (loading) return null;

    if (!state.user && window.location.pathname !== ERoutes.PanelCustomerLogin) {
        navigate(ERoutes.PanelCustomerLogin);
        return null;
    }
    return <Component {...rest} location={location} />;
};
