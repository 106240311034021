import * as Styled from './OxStaffDashboardViewTreatmentPlans.styled';

import React from 'react';

import { RouteComponentProps, WindowLocation } from '@reach/router';
import { OxPanelViewTreatmentPlans } from 'src/panel/components/OxPanelViewTreatmentPlans';
import { OxPageHelmet } from 'src/components/OxPageHelmet';

type TProps = {
    clinicId: number;
    patientId: string;
    location: WindowLocation | undefined;
};
export const OxStaffDashboardViewTreatmentPlans: React.FC<RouteComponentProps<TProps>> = ({
    clinicId,
    patientId,
    location
}) => {
    if (location?.state === null) {
        location.state = {
            allowConsent: false,
            showConsentDate: false
        };
    }

    const { allowConsent, showConsentDate } = location?.state as {
        allowConsent: boolean;
        showConsentDate: boolean;
    };

    return (
        <Styled.Container>
            <OxPageHelmet title="View Treatment Plans" />
            <OxPanelViewTreatmentPlans
                patientId={patientId}
                clinicId={clinicId}
                allowConsent={allowConsent}
                showConsentDate={showConsentDate}
            />
        </Styled.Container>
    );
};
