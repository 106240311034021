import { EFontWeight, EFonts } from 'src/config/enums';
import { fluidSizing, toVw } from 'src/utils';
import styled, { css } from 'styled-components';

import { OxForm } from 'src/components/OxForm';
import { OxInput } from 'src/components/OxInput';
import { OxTextSwitcher } from 'src/components/OxTextSwitcher';
import { createTextStyle } from 'src/utils/createTextStyle';
import { OxPhoneInput } from 'src/components/OxPhoneInput';

export const Form = styled(OxForm)(
    ({ theme }) => css`
        width: 100%;
        ${theme.breakpoints.up('m')} {
            max-width: ${toVw(400, 'm')};
        }
    `
);

export const InputBox = styled.div(
    ({ theme }) => css`
        display: flex;
        align-items: center;
        ${theme.breakpoints.only('xs')} {
            flex-direction: column;
        }
        ${fluidSizing([{ prop: 'margin-bottom', values: [5, 15, 15] }])}
    `
);

export const InputLabel = styled.span(
    ({ theme }) => css`
        flex-shrink: 0;
        color: ${theme.colors.primary.main};
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Normal, [13, 13, 13], [36, 17, 17])}
        ${fluidSizing([{ prop: 'width', values: [null, 90, 90] }])}
    `
);

export const Input = styled(OxInput)`
    flex: 1;
    width: 100%;
`;

export const PhoneInput = styled(OxPhoneInput)`
    flex: 1;
    width: 100%;
`;

export const SuccessText = styled(OxTextSwitcher)(
    ({ theme }) => css`
        color: ${theme.colors.primary.main};
        ${fluidSizing([{ prop: 'margin-top', values: [15, 15, 15] }])}
    `
);
