import * as Styled from './OxLoginFlowCodeLogin.styled';

import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import { TOxLoginFlowCodeLogin } from '../../OxLoginFlow';
import React from 'react';

import { EOxInputType } from 'src/components/OxInput';
import { userGetUser, userPostLoginCode } from 'src/services/api';
import { navigate } from 'gatsby';
import { ERoutes } from 'src/config/enums';
import { isUserAdmin } from 'src/helpers/user';
import { clearSecureCache } from 'src/services/api/api.cache';
import { setAuthProcessStatus } from 'src/panel/store/auth/authActions';
import { EAuthProcessStatus } from 'src/panel/store/types';

export const OxLoginFlowCodeLogin = ({ username }: TOxLoginFlowCodeLogin): JSX.Element => {
    let error = false;
    let success = false;
    const onFormSubmit = async (data: FormData): Promise<void> => {
        error = false;
        success = false;
        const result = await userPostLoginCode({
            user: username,
            passcode: data.get('code') as string
        });
        if (result) {
            if (result.login) {
                success = true;
                localStorage.setItem('ornx_user_token', result?.token as string);
                clearSecureCache();
                const user = await userGetUser();
                if (!user || !user.email) throw new Error('Something went wrong');
                setAuthProcessStatus(EAuthProcessStatus.Success);
                if (await isUserAdmin(user)) {
                    navigate(ERoutes.PanelStaffLanding);
                } else {
                    navigate(ERoutes.PanelCustomerDashboard);
                }
            } else {
                error = true;
            }
        } else {
            error = true;
        }
    };

    return (
        <Styled.Container onFormSubmit={onFormSubmit}>
            {({ submitButtonProps }) => (
                <div>
                    {!success ? (
                        <>
                            <Styled.Paragraph>
                                Alternatively, you can submit the login code from the email:
                            </Styled.Paragraph>
                            <OxThemedInput theme={EInputTheme.GoldTransparent}>
                                <Styled.Input
                                    type={EOxInputType.Text}
                                    name="code"
                                    placeholder="Code"
                                    required
                                />
                            </OxThemedInput>
                            {error && <Styled.Note>This code did not work</Styled.Note>}
                            <OxThemedInput theme={EInputTheme.BackgroundGold}>
                                <Styled.Button type="submit" icon {...submitButtonProps}>
                                    Login
                                </Styled.Button>
                            </OxThemedInput>
                        </>
                    ) : (
                        <div>
                            <br />
                            <Styled.Success>
                                You are now logged in. Please press the button below.
                            </Styled.Success>
                        </div>
                    )}
                </div>
            )}
        </Styled.Container>
    );
};
