import styled, { css } from 'styled-components';
import { EFonts, EFontWeight } from 'src/config/enums';
import { fluidSizing } from 'src/utils';
import theme from 'src/config/theme';
import { createTextStyle } from 'src/utils/createTextStyle';

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        min-height: 100%;
        position: relative;
        width: 100%;
    `
);

export const Number = styled.div(
    ({ theme }) => css`
        align-items: center;
        display: flex;
        flex: 1 1 100%;
        justify-content: center;
        ${createTextStyle(EFonts.Hatton, EFontWeight.Bold, [10, 10, 10], [10, 10, 10])}
    `
);

export const NumberInner = styled.div(
    ({ theme }) => css`
        align-items: center;
        background-color: ${theme.colors.basic.black};
        border-radius: 100%;
        color: ${theme.colors.basic.white};
        display: flex;
        justify-content: center;
        ${fluidSizing([
            { prop: 'height', values: [20, 20, 20] },
            { prop: 'width', values: [20, 20, 20] }
        ])}
    `
);

export const Header = styled.h2(
    ({ theme }) => css`
        color: ${theme.colors.primary.main};
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Bold, [14, 14, 14], [14, 14, 14])}
        ${fluidSizing([{ prop: 'margin', values: [10, 10, 10] }])}
    `
);

export const Content = styled.div`
    flex: 1 1 100%;
    height: 100%;
    position: relative;
    width: 100%;

    ${fluidSizing([{ prop: 'height', values: [300, 300, 300] }])}
`;

const Ball = styled.div`
    background-color: ${theme.colors.basic.black};
    border-radius: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;

    ${fluidSizing([
        { prop: 'height', values: [10, 10, 10] },
        { prop: 'width', values: [10, 10, 10] }
    ])}
`;

export const LeftBall = styled(Ball)`
    ${fluidSizing([{ prop: 'left', values: [25, 25, 25] }])}
`;

export const RightBall = styled(Ball)`
    ${fluidSizing([{ prop: 'right', values: [25, 25, 25] }])}
`;

const GenericNumber = styled.div`
    position: absolute;
    top: 30%;

    ${createTextStyle(EFonts.Hatton, EFontWeight.Normal, [10, 10, 10], [12, 12, 12])}
`;

export const LeftNumber = styled(GenericNumber)`
    ${fluidSizing([{ prop: 'left', values: [25, 25, 25] }])}
`;
export const RightNumber = styled(GenericNumber)`
    ${fluidSizing([{ prop: 'right', values: [25, 25, 25] }])}
`;

export const HBar = styled.div(
    ({ theme }) => css`
        background-color: ${theme.colors.basic.black};
        height: 1px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;

        ${fluidSizing([
            {
                prop: 'width',
                values: [50, 50, 50],
                formatter: (val): string => `calc(100% - ${val}vw)`
            }
        ])}
    `
);

export const Gradient = styled.div(
    ({ theme }) => css`
        background: linear-gradient(
            to right,
            ${theme.colors.basic.goldenLight},
            ${theme.colors.primary.main}
        );
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        z-index: 1;

        ${fluidSizing([
            {
                prop: 'width',
                values: [50, 50, 50],
                formatter: (val): string => `calc(100% - ${val * 2}vw)`
            },
            { prop: 'height', values: [50, 50, 50] },
            { prop: 'margin-left', values: [50, 50, 50] },
            { prop: 'margin-right', values: [50, 50, 50] }
        ])}
    `
);

export const Goal = styled.div(
    ({ theme, index, total }) => css`
        align-items: center;
        display: flex;
        flex-direction: ${index % 2 ? 'column' : 'column-reverse'};
        left: calc(100% * ${index / total});
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 5;

        ${fluidSizing([
            {
                prop: 'height',
                values: [50, 50, 50],
                formatter: (val): string => `${val + (3 * val * index) / total}vw`
            }
        ])}

        &::before,
    &::after {
            background-color: ${theme.colors.basic.black};
            content: '';
            display: block;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
        }

        &::before {
            border-radius: 100%;

            ${fluidSizing([
                { prop: 'height', values: [5, 5, 5] },
                { prop: 'width', values: [5, 5, 5] }
            ])}
        }

        &::after {
            height: 100%;
            width: 1px;
        }
    `
);

export const GoalText = styled.span(
    ({ index }) => css`
        display: flex;
        justify-content: center;
        margin: 0 auto;
        text-align: center;
        transform: translateY(${index % 2 ? '-100%' : '100%'});
        ${createTextStyle(EFonts.Hatton, EFontWeight.Normal, [10, 10, 10], [12, 12, 12])}

        ${fluidSizing([
            { prop: 'max-width', values: [50, 50, 50] },
            { prop: 'padding-bottom', values: [10, 10, 10] },
            { prop: 'padding-top', values: [10, 10, 10] }
        ])}
    `
);
