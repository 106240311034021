import * as Styled from './OxRatingStars.styled';

import React from 'react';

type TProps = {
    value: number;
};

export const OxRatingStars = ({ value, ...props }: SCProps<'div', TProps>): JSX.Element => {
    const stars = Array.from(new Array(6)).map((_, index) => {
        const d = value * 2;
        if (index * 2 < d && (index + 1) * 2 > d) return 'half';
        if (index * 2 < d) return 'filled';
        if (index * 2 > d || index * 2 === d) return 'empty';
    });

    return (
        <Styled.Container {...props}>
            {stars.map((item, index) => {
                switch (item) {
                    case 'half':
                        return (
                            <Styled.StarHalf key={index}>
                                <Styled.StartFilled name="starFilled" doClip />
                                <Styled.StarEmpty name="starEmpty" doClip />
                            </Styled.StarHalf>
                        );
                    case 'filled':
                        return <Styled.StartFilled key={index} name="starFilled" />;
                    case 'empty':
                        return <Styled.StarEmpty key={index} name="starEmpty" />;
                }
            })}
        </Styled.Container>
    );
};
