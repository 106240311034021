import * as Styled from './OxLoginFlowPasswordReset.styled';

import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import { EOxLoginFlowVariant, TOxLoginFlowStageProps } from '../../OxLoginFlow';

import { EOxInputType } from 'src/components/OxInput';
import { ERoutes } from 'src/config/enums';
import React from 'react';
import { navigate } from '@reach/router';
import { userPasswordReset } from 'src/services/api';
import { EAlertVariant } from 'src/context/AlertContext';
import { OnyxError } from 'src/helpers/OnyxError';

export const OxLoginFlowPasswordReset = (props: TOxLoginFlowStageProps): JSX.Element => {
    const onFormSubmit = async (data: FormData): Promise<void> => {
        const password = data.get('password') as string;
        const passwordRepeat = data.get('password_repeat') as string;
        if (passwordRepeat !== password) {
            throw new OnyxError({
                type: EAlertVariant.Error,
                sentryIgnore: true,
                header: 'ERROR ALERT: OLFPR1',
                title: 'Password Mismatch',
                message:
                    'Please check your have entered matching passwords and try again, if the problem persists please contact us.'
            });
        }
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if (token) {
            const result = await userPasswordReset({
                password,
                token
            });
            if (result.message) {
                //we have an error
                throw new OnyxError({
                    type: EAlertVariant.Error,
                    sentryIgnore: true,
                    header: 'ERROR ALERT: OLFPR2',
                    title: 'Error',
                    message: result.message
                });
            } else {
                const type = urlParams.get('type');
                if (type === 'user') {
                    navigate(ERoutes.PanelCustomerLogin);
                } else {
                    navigate(ERoutes.PanelStaffLogin);
                }
            }
        }
    };

    return (
        <Styled.Container onFormSubmit={onFormSubmit}>
            {({ submitButtonProps }) => (
                <>
                    <Styled.Title>Password Reset</Styled.Title>
                    <div>
                        <OxThemedInput theme={EInputTheme.GoldTransparent}>
                            <Styled.Input
                                type={EOxInputType.Password}
                                name="password"
                                placeholder="New Password"
                                required
                            />
                            <Styled.Input
                                type={EOxInputType.Password}
                                name="password_repeat"
                                placeholder="Repeat New Password"
                                required
                            />
                        </OxThemedInput>
                        <OxThemedInput theme={EInputTheme.BackgroundGold}>
                            <Styled.Button type="submit" icon {...submitButtonProps}>
                                UPDATE PASSWORD
                            </Styled.Button>
                        </OxThemedInput>
                        <Styled.BackToLogin
                            onClick={(): void =>
                                props.setCurrentFlow(EOxLoginFlowVariant.LoginFlow)
                            }
                        >
                            Login
                        </Styled.BackToLogin>
                    </div>
                </>
            )}
        </Styled.Container>
    );
};
