import { EOxLoginFlowType } from 'src/panel/components/OxLoginFlow';
import { OxPanelLogin } from 'src/panel/components/OxPanelLogin';
import React from 'react';
import { RouteComponentProps } from '@reach/router';

export const PanelResetPassword = (props: RouteComponentProps): JSX.Element => {
    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get('type');
    return (
        <OxPanelLogin
            variant={type === 'USER' ? EOxLoginFlowType.Customer : EOxLoginFlowType.Staff}
        />
    );
};
