import * as Styled from './OxLoginPasswordlessStage1.styled';

import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import { TOxLoginFlowStageProps } from '../../OxLoginFlow';
import { passwordlessAuthFlow } from 'src/panel/store/auth/authActions';
import { useDispatch } from 'react-redux';

import { EOxInputType } from 'src/components/OxInput';
import React from 'react';
import { OnyxError } from 'src/helpers/OnyxError';
import { EAlertVariant } from 'src/context/AlertContext';
import { OxLoginFlowCodeLogin } from '../OxLoginFlowCodeLogin';

export const OxLoginPasswordlessStage1 = ({
    stage: { title, email }
}: TOxLoginFlowStageProps): JSX.Element => {
    const dispatch = useDispatch();

    const onFormSubmit = async (data: FormData): Promise<void> => {
        const result = await dispatch(
            passwordlessAuthFlow({
                username: data.get('email') as string
            })
        );

        if (result) {
            throw new OnyxError({
                type: EAlertVariant.Success,
                header: '',
                title: 'Login Email Sent',
                message: (
                    <div>
                        <p>
                            If an account is associated with this email address, a login link has
                            been sent to your inbox. The link is valid for 10 minutes.
                        </p>
                        <OxLoginFlowCodeLogin username={data.get('email') as string} />
                    </div>
                )
            });
        }
    };

    return (
        <Styled.Container className="login1" onFormSubmit={onFormSubmit}>
            {({ submitButtonProps }) => (
                <>
                    <Styled.Title>{title}</Styled.Title>
                    <div>
                        <OxThemedInput theme={EInputTheme.GoldTransparent}>
                            <Styled.Input
                                initialValue={email}
                                type={EOxInputType.Email}
                                name="email"
                                placeholder="Email Address*"
                                required
                            />
                        </OxThemedInput>
                        <Styled.Note>
                            {'We will send a magic link to your inbox to log in.'}
                        </Styled.Note>
                        <OxThemedInput theme={EInputTheme.BackgroundGold}>
                            <Styled.Button type="submit" icon {...submitButtonProps}>
                                LOGIN
                            </Styled.Button>
                        </OxThemedInput>
                    </div>
                </>
            )}
        </Styled.Container>
    );
};
