import { TMessage } from 'src/types/message.types';

const localStorageKey = 'ouronyx_practitioner_read_message_ids';

export const getReadMessageIds = (): string[] => {
    return JSON.parse(localStorage.getItem(localStorageKey) || '[]');
};

export const addMessageToReadMessages = (message: TMessage): void => {
    const messageIds = [...new Set([...getReadMessageIds(), message.id])];
    localStorage.setItem(localStorageKey, JSON.stringify(messageIds));
};
