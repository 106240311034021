import React, { useEffect, useState } from 'react';

import { ERoutes } from 'src/config/enums';
import { isUserLoggedIn } from 'src/helpers/user';
import { navigate } from '@reach/router';
import { useDispatch } from 'react-redux';
import { logoutFlow } from 'src/panel/store/auth/authActions';

export const OxLoggedInRedirect = ({
    component: Component,
    to,
    forceReLogin = false,
    ...rest
}: {
    component: () => JSX.Element;
    to: ERoutes;
    forceReLogin?: boolean;
}): JSX.Element | null => {
    const [checked, setChecked] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        const asyncAction = async (): Promise<void> => {
            if (forceReLogin) {
                dispatch(logoutFlow());
            } else {
                const loggedIn = await isUserLoggedIn();

                if (loggedIn) {
                    navigate(to);
                }
            }

            setChecked(true);
        };
        asyncAction();
    }, []);

    if (!checked) return null;

    return <Component {...rest} />;
};
