import { OxSelect } from 'src/components/OxSelect';
import styled from 'styled-components';
import { fluidSizing } from 'src/utils';
import { OxForm } from 'src/components/OxForm';

export const Form = styled(OxForm)`
    ${fluidSizing([
        { prop: 'margin-top', values: [10, 10, 10] },
        { prop: 'max-width', values: [400, 400, 400] }
    ])}
`;

export const Select = styled(OxSelect)`
    ${fluidSizing([
        { prop: 'margin-bottom', values: [20, 20, 20] },
        { prop: 'margin-top', values: [20, 20, 20] }
    ])}
`;
