import { EventEmitter, EventSubscription } from 'fbemitter';

interface LooseObject {
    [key: string]: unknown | LooseObject;
}

type TMessage = {
    type: string;
    payload: LooseObject;
};

type TSubscription = {
    topic: string;
    callback: ((uri: string, message: LooseObject) => void) | null;
    listener: EventSubscription;
};

enum EMessageType {
    WELCOME = 0,
    PREFIX = 1,
    CALL = 2,
    CALL_RESULT = 3,
    CALL_ERROR = 4,
    SUBSCRIBE = 5,
    UNSUBSCRIBE = 6,
    PUBLISH = 7,
    EVENT = 8
}

/**
 * Originally taken from
 * https://github.com/tabvn/pubsub
 *
 * It has been converted to typescript and cleaned up
 */
export default class PubSubClient {
    emitter: EventEmitter;
    _connected: boolean;
    _ws: WebSocket | null;
    _queue: TMessage[];
    _id: number | null;
    _listeners: EventSubscription[];
    _subscriptions: TSubscription[];
    _isReconnecting: boolean;
    _url: string;
    _options: {
        connect?: boolean;
        reconnect?: boolean;
        debug?: boolean;
        onConnect?: () => void;
        onDisconnect?: () => void;
        onConnectError?: () => void;
    };
    _reconnectTimeout: number | undefined;
    onConnect: () => void;
    onDisconnect: () => void;
    onConnectError: () => void;

    constructor(
        url: string,
        options = {
            connect: true,
            reconnect: true,
            debug: false,
            onConnect: (): void => {
                /* placeholder */
            },
            onDisconnect: (): void => {
                /* placeholder */
            },
            onConnectError: (): void => {
                /* placeholder */
            }
        }
    ) {
        // Binding
        this.reconnect = this.reconnect.bind(this);
        this.connect = this.connect.bind(this);
        this.runSubscriptionQueue = this.runSubscriptionQueue.bind(this);
        this.runQueue = this.runQueue.bind(this);

        this.unsubscribe = this.unsubscribe.bind(this);
        this.subscribe = this.subscribe.bind(this);
        this.publish = this.publish.bind(this);

        // status of client connection
        this.emitter = new EventEmitter();
        this._connected = false;
        this._ws = null;
        this._queue = [];
        this._id = null;

        // store listeners
        this._listeners = [];

        //All subscriptions
        this._subscriptions = [];

        // store settings
        this._isReconnecting = false;
        this._url = url;
        this._options = options;

        // Hooks
        this.onConnect = options.onConnect;
        this.onDisconnect = options.onDisconnect;
        this.onConnectError = options.onConnectError;

        if (this._options && this._options.connect) {
            // auto connect
            this.connect();
        }
    }

    /**
     * Un Subscribe a topic, no longer receive new message of the topic
     * @param topic
     */
    unsubscribe(topic: string): void {
        this.debug(`Unsubscribing ${topic}`);
        const subscription = this._subscriptions.find((sub) => sub.topic === topic);

        if (subscription && subscription.listener) {
            // first need to remove local listener
            subscription.listener.remove();
        }

        // need to tell to the server side that i dont want to receive message from this topic
        this.send(EMessageType.UNSUBSCRIBE, topic);
    }

    /**
     * Subscribe client to a topic
     * @param topic
     * @param cb
     */
    subscribe(topic: string, cb: (uri: string, message: LooseObject) => void): void {
        this.debug(`Subscribing ${topic}`);
        const listener = this.emitter.addListener(`subscribe_topic_${topic}`, cb);
        // add listener to array
        this._listeners.push(listener);

        // send server with message

        this.send(EMessageType.SUBSCRIBE, topic);

        // let store this into subscriptions for later when use reconnect and we need to run queque to subscribe again
        this._subscriptions.push({
            topic: topic,
            callback: cb,
            listener: listener
        });
    }

    /**
     * Publish a message to topic, send to everyone and me
     * @param topic
     * @param message
     */
    publish(topic: string, message: LooseObject): void {
        this.debug(`Publishing ${topic}`);
        this.send(EMessageType.PUBLISH, {
            topic: topic,
            message: message
        });
    }

    /**
     * Publish a message to the topic and send to everyone, not me
     * @param topic
     * @param message
     */
    broadcast(topic: string, message: LooseObject): void {
        this.debug(`Broadcasting ${topic}`);
        this.send(EMessageType.PUBLISH, {
            topic: topic,
            message: message
        });
    }

    /**
     * Return client connection ID
     */
    id(): number | null {
        return this._id;
    }

    /**
     * Convert string to JSON
     * @param message
     * @returns {*}
     */
    stringToJson(message: string): LooseObject | string {
        try {
            return JSON.parse(message);
        } catch (e) {
            //Not JSON
            return message;
        }
    }

    parseMessage(message: LooseObject | string): LooseObject | string {
        if (typeof message === 'string') {
            return this.stringToJson(message);
        } else if (typeof message === 'object' && message.msg) {
            return this.stringToJson(message.msg as string);
        }

        return message;
    }

    /**
     * Send a message to the server
     * @param type
     * @param message
     */
    send(type: EMessageType, message: LooseObject | string): void {
        this.debug(`Sending message... ${message}`, message);
        if (this._connected && this._ws?.readyState === 1) {
            this._ws.send(JSON.stringify([type, message]));
        } else {
            // let keep it in queue
            this._queue.push({
                type: message.type,
                payload: message.payload
            });
        }
    }

    /**
     * Run Queue after connecting successful
     */
    runQueue(): void {
        this.debug('Running Queue...');
        if (this._queue.length) {
            this._queue.forEach((q, index) => {
                switch (q.type) {
                    case 'message':
                        this.send(EMessageType.PUBLISH, q.payload);

                        break;

                    default:
                        break;
                }

                // remove queue

                delete this._queue[index];
            });
        }
    }

    /**
     * Let auto subscribe again
     */
    runSubscriptionQueue(): void {
        this.debug('Running subscription queue...');

        if (this._subscriptions.length) {
            this._subscriptions.forEach((subscription) => {
                this.send(EMessageType.SUBSCRIBE, subscription.topic);
            });
        }
    }

    /**
     * Implement reconnect
     */
    reconnect(): void {
        // if is reconnecting so do nothing
        if (this._isReconnecting || this._connected) {
            return;
        }
        // Set timeout
        this._isReconnecting = true;
        this._reconnectTimeout = setTimeout(() => {
            this.debug('Reconnecting....');
            this.connect();
        }, 2000);
    }

    /**
     * Begin connect to the server
     */
    connect(): void {
        this.debug('Connecting...');
        const ws = new WebSocket(this._url);
        this._ws = ws;

        // clear timeout of reconnect
        if (this._reconnectTimeout) {
            clearTimeout(this._reconnectTimeout);
        }

        ws.onopen = (): void => {
            // change status of connected
            this._connected = true;
            this._isReconnecting = false;

            this.debug('Connected to the server');
            // this.send({ action: "me" });
            // run queue
            this.onConnect();
            this.runQueue();

            this.runSubscriptionQueue();
        };
        // listen a message from the server
        ws.onmessage = (message): void => {
            this.debug(`Received message ${message.data}`);
            const jsonMessage = this.stringToJson(message.data);

            // Messages are of format [type, topic, message], but this seems
            // to vary depending on type of message
            switch (jsonMessage[0]) {
                // ToDo: handle other message types
                case EMessageType.EVENT:
                    this.emitter.emit(
                        `subscribe_topic_${jsonMessage[1]}`,
                        this.parseMessage(jsonMessage[2])
                    );
                    break;

                default:
                    break;
            }
        };
        ws.onerror = (err): void => {
            this.debug('unable connect to the server', err);

            this._connected = false;
            this._isReconnecting = false;
            this.onConnectError();
            this.reconnect();
        };
        ws.onclose = (): void => {
            this.debug('Connection is closed');

            this._connected = false;
            this._isReconnecting = false;
            this.onDisconnect();
            this.reconnect();
        };
    }

    /**
     * Disconnect client
     */
    disconnect(): void {
        if (this._listeners.length) {
            this._listeners.forEach((listener) => {
                listener.remove();
            });
        }
    }

    debug(message: string, ...data: unknown[]): void {
        this._options.debug && console.log(message, ...data);
    }
}
