import * as Styled from './OxTreatmentNotesInfo.styled';

import React, { useContext, useState } from 'react';

import { DateTime } from 'luxon';
import { numberSuffix } from 'src/utils/numberSuffix';
import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import { TreatmentNoteContext } from 'src/context/TreatmentNoteContext';
import { TTreatmentOutcome } from 'src/services/api/api.types';
import { useStore } from 'react-redux';

type TProps = {
    loading: boolean;
};

type TDetail = {
    label: string;
    value: string | undefined | false;
};

export const OxTreatmentNotesInfo = ({ loading }: TProps): JSX.Element => {
    const store = useStore();
    const { user } = store.getState() ?? {};
    const treatmentNoteContext = useContext(TreatmentNoteContext);
    const { appointmentSummary, outcomeId, setOutcomeId, treatmentGoals } =
        treatmentNoteContext ?? {};
    const { appointmentTime, patientName, practitionerName, goal } = appointmentSummary ?? {};

    const [selectedOutcomeId, setSelectedOutcomeId] = useState<number>();
    const appointmentTimeObj = DateTime.fromISO(appointmentTime ?? '').setZone(
        user?.clinic?.timezone ?? 'Europe/London'
    );

    const outcomes = goal?.id
        ? treatmentGoals?.find((goal) => goal.id === appointmentSummary?.goal?.id)?.outcomes
        : treatmentGoals.reduce(
              (acc: TTreatmentOutcome[], curr) => [...acc, ...(curr.outcomes ?? [])],
              []
          );

    const outcomeItems: { label: string; outcomeTitle: string; value: number }[] =
        outcomes?.map((outcome: TTreatmentOutcome) => ({
            label: `${
                treatmentGoals.find((goal) =>
                    goal.outcomes?.find((goalOutcome) => goalOutcome.id === outcome.id)
                )?.title
            } - ${outcome.title}`,
            outcomeTitle: outcome.title,
            value: outcome.id
        })) ?? [];

    const details: TDetail[] = [
        {
            label: 'Patient Name',
            value: patientName
        },
        {
            label: 'Doctor Name',
            value: practitionerName
        },
        {
            label: 'Appointment Date',
            value: appointmentTime
                ? appointmentTimeObj.toFormat(
                      `d'${numberSuffix(appointmentTimeObj.day)}' MMMM yyyy`
                  )
                : ''
        },
        {
            label: 'Appointment Time',
            value: appointmentTime ? appointmentTimeObj.toFormat(`HH:mm`) : ''
        },
        {
            label: 'Goal',
            value:
                goal?.title ??
                treatmentGoals.find((goal) =>
                    goal.outcomes?.map((outcome) => outcome.id).includes(outcomeId ?? 0)
                )?.title
        },
        {
            label: 'Outcome',
            value:
                outcomeItems.length > 0
                    ? outcomeItems.find((item) => item.value === outcomeId)?.outcomeTitle
                    : ''
        }
    ];

    return (
        <>
            {details
                .filter((detail) => !!detail.value)
                .map((detail, index) => (
                    <Styled.Detail key={index}>
                        {detail.label}: <Styled.DetailValue>{detail.value}</Styled.DetailValue>
                    </Styled.Detail>
                ))}
            {!outcomeId && !loading && (
                <OxThemedInput theme={EInputTheme.Gold}>
                    <Styled.OutcomeText>Outcomes</Styled.OutcomeText>
                    <Styled.Select
                        title="Select Outcome"
                        items={outcomeItems}
                        onValueChange={(val: { value: number; label: string }): void => {
                            val && setSelectedOutcomeId(val.value);
                        }}
                        labelName="label"
                        valueName="value"
                    />
                    {!!selectedOutcomeId && (
                        <OxThemedInput theme={EInputTheme.BackgroundGold}>
                            <Styled.Button
                                type="button"
                                icon
                                onClick={(): void => {
                                    setOutcomeId(selectedOutcomeId);
                                }}
                            >
                                Save Outcome
                            </Styled.Button>
                        </OxThemedInput>
                    )}
                </OxThemedInput>
            )}
        </>
    );
};
