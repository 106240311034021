import { EApiEndpoint, ERefreshmentStatus } from 'src/config/enums';
import { apiGetCall, apiPatchCall } from 'src/services/api/api.core';
import { applyValues } from 'src/utils/applyValues';
import { TPendingRefreshment } from 'src/services/api/api.types';

export const getPendingRefreshments = async (values: {
    clinicId: string;
}): Promise<TPendingRefreshment[] | null> => {
    return await apiGetCall(applyValues(EApiEndpoint.BaristaGetRefreshments, values), {
        credentials: true
    });
};

export const acknowledgeRefreshment = async (
    values: {
        id: number;
    },
    params: {
        baristaComment?: string;
    }
): Promise<null> => {
    return await apiPatchCall(
        applyValues(EApiEndpoint.BaristaAcknowledgeRefreshments, values),
        JSON.stringify(Object.assign({ status: ERefreshmentStatus.Completed }, params)),
        {
            credentials: true
        }
    );
};
