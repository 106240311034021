import { EFontWeight, EFonts } from 'src/config/enums';
import styled from 'styled-components';

import { createTextStyle } from 'src/utils/createTextStyle';
import { fluidSizing } from 'src/utils';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    ${fluidSizing([
        { prop: 'padding-left ', values: [25, 50, 60] },
        { prop: 'padding-right ', values: [25, 50, 60] }
    ])}
`;

export const Title = styled.div`
    ${createTextStyle(EFonts.Hatton, EFontWeight.SemiBold, [20, 22, 22], [34, 34, 34])}
    ${fluidSizing([{ prop: 'margin-bottom', values: [10, 10, 10] }])}
`;
