import * as Styled from './OxCustomerDashboardMyDetails.styled';

import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import React, { useState } from 'react';
import { getPatient, putPatient } from 'src/services/api/patient';

import { EAnimatedWrapperAnimation } from 'src/components/OxAnimatedWrapper';
import { EOxInputType } from 'src/components/OxInput';
import { OxButton } from 'src/components/OxButton';
import { OxDashboardContentWrapper } from '../../../OxDashboardContentWrapper';
import { RouteComponentProps } from '@reach/router';
import { TPatient } from 'src/services/api/api.types';
import { OxPageHelmet } from 'src/components/OxPageHelmet';

type TProps = {
    patientId?: string;
};

export const OxCustomerDashboardMyDetails: React.FC<RouteComponentProps<TProps>> = ({
    patientId
}) => {
    const [fetched, setFetched] = useState<boolean>(false);
    const [updated, setUpdated] = useState(false);
    const [details, setDetails] = useState<TPatient>();
    const onFormSubmit = async (data: FormData): Promise<void> => {
        const result = await putPatient({
            ...details,
            patientPhoneNumbers: [{ number: data.get('mobile') as string, phone_type: 'Mobile' }]
        });
        if (result) {
            setDetails(result);
            setUpdated(true);
            setTimeout(() => {
                setUpdated(false);
            }, 2000);
        }
    };

    const fetchData = async (): Promise<void> => {
        const data = await getPatient();

        if (data) {
            let patient: TPatient = null;

            data.map((patientData: TPatient) => {
                if (patientData.patientId == patientId) {
                    patient = patientData;
                }
            });

            if (patient) setDetails(patient);

            setFetched(true);
        }
    };

    const initialPhoneValue =
        details?.patientPhoneNumbers &&
        details?.patientPhoneNumbers[0] &&
        details?.patientPhoneNumbers[0].number;

    const formInputs = [
        {
            name: 'mobile',
            label: 'Mobile:',
            component: Styled.PhoneInput,
            initialValue: initialPhoneValue,
            preventRender: !details && !fetched
        },
        {
            name: 'email',
            label: 'Email:',
            type: EOxInputType.Email,
            component: Styled.Input,
            initialValue: details?.email,
            disabled: true
        }
    ];

    const renderInput = (item) => {
        return React.createElement(item.component, {
            type: item.type,
            name: item.name,
            initialValue: item.initialValue,
            disabled: item.disabled,
            canEditCountryCode: !!item.initialValue
        });
    };

    return (
        <OxDashboardContentWrapper title="My Details" onInit={fetchData}>
            <OxPageHelmet title="My Details" />
            {fetched && (
                <Styled.Form onFormSubmit={onFormSubmit}>
                    {({ submitButtonProps }) => (
                        <>
                            <OxThemedInput theme={EInputTheme.GoldTransparent}>
                                {formInputs.map((item) => (
                                    <React.Fragment key={item.name}>
                                        {!item.preventRender && (
                                            <Styled.InputBox>
                                                <Styled.InputLabel>{item.label}</Styled.InputLabel>
                                                {renderInput(item)}
                                            </Styled.InputBox>
                                        )}
                                    </React.Fragment>
                                ))}
                            </OxThemedInput>
                            <OxThemedInput theme={EInputTheme.BackgroundGold}>
                                <OxButton type="submit" icon {...submitButtonProps}>
                                    UPDATE DETAILS
                                </OxButton>
                            </OxThemedInput>
                            <Styled.SuccessText
                                items={['', 'Details Update Successful']}
                                activeIndex={updated ? 1 : 0}
                                animationType={EAnimatedWrapperAnimation.FadeIn}
                            />
                        </>
                    )}
                </Styled.Form>
            )}
        </OxDashboardContentWrapper>
    );
};
