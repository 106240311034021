import * as Styled from './OxRefreshments.styled';

import { AlertContext, EAlertVariant } from 'src/context/AlertContext';
import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import React, { useContext, useMemo, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { OxButton } from 'src/components/OxButton';
import {
    OxRefreshmentsSelect,
    TRefreshmentsSelectItem
} from 'src/panel/components/OxWelcomeIntenalFlow/components/OxRefreshments/components';
import { postRefreshments } from 'src/services/api/reception';
import { OxTextArea } from 'src/components/OxTextArea';

type TProps = {
    patientId: string;
    onNext: () => void;
};

type TRefreshment = {
    name: string;
    grouping: string;
    refreshmentImage: {
        asset: any;
    };
};

type TRefreshmentGroup = {
    name: string;
    refreshments: TRefreshment[];
};

export const OxRefreshments = (props: TProps): JSX.Element => {
    const { showAlert } = useContext(AlertContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [selectedRefreshments, setSelectedRefreshments] = useState<{
        [key: string]: string;
    }>({});
    const [notes, setNotes] = useState<string>('');

    const data = useStaticQuery(graphql`
        query RefreshmentQuery {
            refreshments: allSanityRefreshment(filter: { isAvailable: { eq: true } }) {
                nodes {
                    name
                    grouping
                    refreshmentImage {
                        asset {
                            gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                }
            }
        }
    `);

    const refreshmentGroups: TRefreshmentGroup[] = useMemo(() => {
        const groups: TRefreshmentGroup[] = [];

        data.refreshments.nodes.forEach((refreshment: TRefreshment) => {
            const groupIndex = groups.findIndex((group) => group.name === refreshment.grouping);

            if (groupIndex >= 0) {
                groups[groupIndex].refreshments.push(refreshment);
            } else {
                groups.push({
                    name: refreshment.grouping,
                    refreshments: [refreshment]
                });
            }
        });

        return groups;
    }, [data]);

    const onRefreshmentChange = ({ group, name }: { group: string; name: string }): void => {
        setSelectedRefreshments((groups) => ({ ...groups, [group]: name }));
    };

    const onSelect = async (): Promise<void> => {
        setLoading(true);
        const refreshments = Object.values(selectedRefreshments)
            .filter((item) => !!item)
            .join(',');

        if (!refreshments || refreshments === '') {
            props.onNext();
            return;
        }

        try {
            const result = await postRefreshments(
                { patientId: props.patientId },
                { refreshments, notes }
            );

            if (result?.error) {
                setLoading(false);
                showAlert({
                    type: EAlertVariant.Error,
                    header: 'Error',
                    title: 'Something went wrong'
                });
            } else {
                props.onNext();
            }
        } catch (err) {
            setLoading(false);
            showAlert({
                type: EAlertVariant.Error,
                header: 'Error',
                title: 'Something went wrong'
            });
        }
    };

    return (
        <Styled.ContentContainer>
            <Styled.Title>Refreshments Menu</Styled.Title>
            <Styled.Info>
                At Ouronyx we believe that transformation is a whole-body equation. Our
                complimentary menu is full of nutrient-dense, beauty boosting ingredients geared
                towards your optimal health and wellbeing. Enjoy!
            </Styled.Info>
            <Styled.Inputs>
                {refreshmentGroups.map((group, index) => (
                    <OxRefreshmentsSelect
                        key={index}
                        items={group.refreshments.map(
                            (refreshment) =>
                                ({
                                    label: refreshment.name,
                                    fluidImages: refreshment?.refreshmentImage?.asset?.fluid
                                } as TRefreshmentsSelectItem)
                        )}
                        label={group.name}
                        grid={3}
                        onItemSelect={(value: string) =>
                            onRefreshmentChange({ group: group.name, name: value })
                        }
                    />
                ))}
                <OxThemedInput theme={EInputTheme.BackgroundWhite}>
                    <OxTextArea
                        name="notes"
                        placeholder="Requests e.g. Oat Milk"
                        onBlur={(e: React.FocusEvent<HTMLTextAreaElement>): void => {
                            const target = e.target as HTMLTextAreaElement | undefined;
                            target && setNotes(target.value);
                        }}
                    />
                </OxThemedInput>
                <OxThemedInput theme={EInputTheme.BackgroundGold}>
                    <OxButton
                        onClick={onSelect}
                        icon
                        loading={loading}
                        debounceClick
                        debounceClickDeps={[
                            selectedRefreshments,
                            notes,
                            props.onNext,
                            props.patientId
                        ]}
                    >
                        SELECT
                    </OxButton>
                </OxThemedInput>
            </Styled.Inputs>
        </Styled.ContentContainer>
    );
};
