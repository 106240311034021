import * as actions from './userActions';

import { TUserState } from '../types';
import { createReducer } from 'redux-act';

const INIT_STATE = null;

export const userReducer = createReducer<TUserState>(
    {
        [actions.setUser]: (_, payload) => ({ ...payload }),
        [actions.removeUser]: () => null
    },
    INIT_STATE
);
