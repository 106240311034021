import styled, { css } from 'styled-components';
import { OxBackButton } from 'src/components/OxBackButton';
import { fluidSizing } from 'src/utils';

export const BackButton = styled(OxBackButton)(
    ({ theme }) => css`
        color: ${theme.colors.primary.main};

        ${fluidSizing([
            { prop: 'margin-left', values: [10, 20, 40] },
            { prop: 'margin-right', values: [10, 20, 40] }
        ])}

        @media print {
            display: none;
        }
    `
);

export const Container = styled.div`
    ${fluidSizing([
        { prop: 'margin-bottom', values: [10, 20, 40] },
        { prop: 'margin-left', values: [10, 20, 40] },
        { prop: 'margin-right', values: [10, 20, 40] }
    ])}
`;
