import * as Styled from './OxStaffDashboardAppointmentCalendar.styled';

import React, { useContext, useState } from 'react';

import { DateTime } from 'luxon';
import { EType, OxStaffTimeline } from './components/OxStaffTimeline';
import { WebsiteDataContext } from 'src/context/WebsiteDataContext';
import { useStore } from 'react-redux';

export type TLocationFilter = {
    id: string;
    name: string;
};

type TProps = {
    id: string;
    type: EType;
    showLocations?: boolean;
};

export const OxStaffDashboardAppointmentCalendar = ({
    id,
    type,
    showLocations = false
}: TProps): JSX.Element => {
    const store = useStore();
    const { user } = store.getState() ?? {};
    const websiteDataContext = useContext(WebsiteDataContext);
    const filters = websiteDataContext.locations.map((item) => ({
        id: item.businessId as string,
        name: item.displayName as string
    }));

    const [activeFilter, setActiveFilter] = useState<TLocationFilter | null>(
        filters.length === 1 ? filters[0] : null
    );

    const todayDate = DateTime.local().setZone(user?.clinic?.timezone ?? 'Europe/London');

    const [selectedDate, setSelectedDate] = useState<DateTime>(
        todayDate.set({
            day: todayDate.day + 1,
            hour: 0,
            millisecond: 0,
            minute: 0,
            second: 0
        })
    );

    return (
        <Styled.Container id={id}>
            <Styled.Heading>Appointment Calendar</Styled.Heading>
            {showLocations && (
                <Styled.Filters>
                    <div>
                        {filters.map((filter) => (
                            <Styled.FilterItem
                                key={filter.id}
                                active={filters.length > 1 ? filter.id === activeFilter?.id : true}
                                onClick={(): void => setActiveFilter(filter)}
                            >
                                {filter.name}
                            </Styled.FilterItem>
                        ))}
                    </div>
                    {filters.length > 1 && (
                        <Styled.FilterViewAll onClick={(): void => setActiveFilter(null)}>
                            View All
                        </Styled.FilterViewAll>
                    )}
                </Styled.Filters>
            )}
            <Styled.Content>
                <Styled.Calendar
                    selectedDate={selectedDate}
                    onDateSelect={(date: DateTime): void => {
                        const newDate = date.set({ hour: 12 });
                        setSelectedDate(newDate.setZone(user?.clinic?.timezone ?? 'Europe/London'));
                    }}
                    allFutureDaysAllowed
                    allPastDaysAllowed
                />
                <OxStaffTimeline
                    selectedDate={selectedDate}
                    timezone={user?.clinic?.timezone}
                    location={activeFilter}
                    startTime={selectedDate
                        ?.set({ hour: 9, minute: 0 })
                        .setZone(user?.clinic?.timezone)}
                    endTime={selectedDate
                        ?.set({ hour: 18, minute: 0 })
                        .setZone(user?.clinic?.timezone)}
                    duration={30}
                    type={type}
                />
            </Styled.Content>
        </Styled.Container>
    );
};
