import styled, { css } from 'styled-components';
import { fluidSizing } from 'src/utils';
import { createTextStyle } from 'src/utils/createTextStyle';
import { EFonts, EFontWeight } from 'src/config/enums';
import { OxButton } from 'src/components/OxButton';
import { OxTextArea } from 'src/components/OxTextArea';
import { OxIcon } from 'src/components/OxIcon';

export const Message = styled.div<{ completed?: boolean }>(
    ({ theme, completed }) => css`
        align-items: center;
        border-bottom: 1px solid ${theme.colors.basic.black};
        display: grid;
        grid-template:
            'Content Acknowledge'
            'Notes Notes';
        text-align: left;

        ${fluidSizing([
            { prop: 'margin-bottom', values: [10, 10, 10] },
            { prop: 'padding-bottom', values: [10, 10, 10] }
        ])}

        ${completed &&
        css`
            grid-template:
                'Content Content'
                'Content Content';
        `}
    `
);

export const Content = styled.div`
    display: flex;
    grid-area: Content;
    flex: 1;
    flex-direction: column;
`;

export const Detail = styled.div`
    display: flex;
    ${fluidSizing([{ prop: 'margin-bottom', values: [5, 5, 5] }])}
`;

export const DetailLabel = styled.div`
    flex: 0 0 12ch;

    ${fluidSizing([{ prop: 'margin-right', values: [5, 5, 5] }])}
    ${createTextStyle(EFonts.Montserrat, EFontWeight.Light, [10, 10, 10], [12, 12, 12])}
`;

export const DetailItem = styled.div<{ capitalize?: boolean }>(
    ({ capitalize }) => css`
        flex: 1;

        ${capitalize
            ? css`
                  text-transform: capitalize;
              `
            : ''}

        ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [10, 10, 10], [12, 12, 12])}
    `
);

export const Acknowledge = styled(OxButton)`
    align-items: center;
    border-radius: 100%;
    grid-area: Acknowledge;
    display: flex;
    justify-content: center;
    margin-left: auto;
    min-width: unset;
    padding-left: unset;
    padding-right: unset;

    ${fluidSizing([
        { prop: 'height', values: [35, 35, 35] },
        { prop: 'width', values: [35, 35, 35] }
    ])}
`;

export const AcknowledgeIcon = styled(OxIcon)`
    ${fluidSizing([
        { prop: 'height', values: [15, 15, 15] },
        { prop: 'width', values: [15, 15, 15] }
    ])}
`;

export const Notes = styled(OxTextArea)`
    grid-area: Notes;

    textarea {
        ${fluidSizing([{ prop: 'height', values: [68, 68, 68] }])}
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Light, [10, 10, 10], [18, 18, 18])}
    }
`;
