import * as Styled from './OxCustomerDashboardCommunicationPreferences.styled';

import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import React, { useContext, useState } from 'react';
import { getPatient, putPatient } from 'src/services/api/patient';

import { OxButton } from 'src/components/OxButton';
import { OxCheckmarkCheckbox } from 'src/panel/components/OxPanelCustomerDashboard/components/OxCustomerDashboardCommunicationPreferences/components';
import { RouteComponentProps } from '@reach/router';
import { TPatient } from 'src/services/api/api.types';
import { AlertContext, EAlertVariant } from 'src/context/AlertContext';
import { OnyxError } from 'src/helpers/OnyxError';
import { OxPageHelmet } from 'src/components/OxPageHelmet';

export const OxCustomerDashboardCommunicationPreferences: React.FC<RouteComponentProps> = () => {
    const [patient, setPatient] = useState<TPatient>();
    const { showAlert } = useContext(AlertContext);
    const [checkboxes, setCheckboxes] = useState({
        newsletter: {
            label: 'Subscribe me to marketing notifications',
            value: 'newsletter',
            defaultChecked: false
        }
    });
    const [contactCheckboxes, setContactCheckboxes] = useState({
        email: {
            label: 'Email',
            value: 'email',
            defaultChecked: false
        },
        phone: {
            label: 'Phone',
            value: 'phone',
            defaultChecked: false
        },
        sms: {
            label: 'SMS',
            value: 'sms',
            defaultChecked: false
        },
        whatsapp: {
            label: 'WhatsApp',
            value: 'whatsapp',
            defaultChecked: false
        }
    });

    const fetchData = async (): Promise<void> => {
        const patient = await getPatient();
        if (patient) {
            setPatient(patient[0]);
            setCheckboxes({
                ...checkboxes,
                newsletter: {
                    ...checkboxes.newsletter,
                    defaultChecked: patient[0].newsletter === true
                }
            });
            setContactCheckboxes({
                ...contactCheckboxes,
                sms: {
                    ...contactCheckboxes.sms,
                    defaultChecked: patient[0].communicationPreferences?.includes('sms') === true
                },
                phone: {
                    ...contactCheckboxes.phone,
                    defaultChecked: patient[0].communicationPreferences?.includes('phone') === true
                },
                whatsapp: {
                    ...contactCheckboxes.whatsapp,
                    defaultChecked:
                        patient[0].communicationPreferences?.includes('whatsapp') === true
                },
                email: {
                    ...contactCheckboxes.email,
                    defaultChecked: patient[0].communicationPreferences?.includes('email') === true
                }
            });
        }
    };

    const onFormSubmit = async (data: FormData): Promise<void> => {
        const contactOption: string[] = [
            data.get('sms') ? (data.get('sms') as string) : '',
            data.get('phone') ? (data.get('phone') as string) : '',
            data.get('post') ? (data.get('post') as string) : '',
            data.get('email') ? (data.get('email') as string) : '',
            data.get('whatsapp') ? (data.get('whatsapp') as string) : ''
        ].filter((x) => x != '');
        const result = await putPatient({
            ...patient,
            newsletter: !!data.get('newsletter'),
            communicationPreferences: contactOption
        });
        if (result) {
            if (result.errors || result.status === 'error') {
                throw new OnyxError({
                    type: EAlertVariant.Error,
                    header: 'ERROR ALERT: OCDCP1',
                    title: 'Something went wrong',
                    message:
                        'Please check your details and try again, if the problem persists please contact us.'
                });
            } else {
                showAlert({
                    type: EAlertVariant.Success,
                    header: 'UPDATE CONFIRMED',
                    title: 'Success',
                    message: 'Your Communication preferences have been updated successfully.'
                });
            }
        } else {
            throw new OnyxError({
                type: EAlertVariant.Error,
                header: 'ERROR ALERT: OCDCP2',
                title: 'Something went wrong',
                message:
                    'Please check your details and try again, if the problem persists please contact us.'
            });
        }
    };

    const checkboxesInputs = Object.keys(checkboxes).map((key) => checkboxes[key]);
    const contactCheckboxesInputs = Object.keys(contactCheckboxes).map(
        (key) => contactCheckboxes[key]
    );

    return (
        <Styled.Container title="Communication Preferences" onInit={fetchData}>
            <OxPageHelmet title="Communication Preferences" />
            <Styled.Text>Manage your communication preferences below.</Styled.Text>
            <Styled.FormWithMargin onFormSubmit={onFormSubmit}>
                {({ submitButtonProps }) => (
                    <>
                        {checkboxesInputs.map((checkbox) => (
                            <OxCheckmarkCheckbox
                                key={checkbox.value}
                                label={checkbox.label}
                                value={checkbox.value}
                                id={checkbox.value}
                                name={checkbox.value}
                                defaultChecked={checkbox.defaultChecked}
                            />
                        ))}
                        <Styled.SecondText>
                            Please select the options you would like to be contacted by
                        </Styled.SecondText>
                        {contactCheckboxesInputs.map((checkbox) => (
                            <OxCheckmarkCheckbox
                                key={checkbox.value}
                                label={checkbox.label}
                                value={checkbox.value}
                                id={checkbox.value}
                                name={checkbox.value}
                                defaultChecked={checkbox.defaultChecked}
                            />
                        ))}
                        <OxThemedInput theme={EInputTheme.BackgroundGoldAlternative}>
                            <OxButton type="submit" icon {...submitButtonProps}>
                                UPDATE PREFERENCES
                            </OxButton>
                        </OxThemedInput>
                    </>
                )}
            </Styled.FormWithMargin>
        </Styled.Container>
    );
};
