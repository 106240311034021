import * as Styled from './OxStaffTimeline.styled';

import React, { useEffect, useState } from 'react';

import { DateTime } from 'luxon';
import { TAppointment, TClinicAppointments, TUser } from 'src/services/api/api.types';
import { TLocationFilter } from '../../OxStaffDashboardAppointmentCalendar';
import { getPractitionerAppointment } from 'src/services/api/practitioner';
import { getAppointments } from 'src/services/api/clinicDashboard';
import { useStore } from 'react-redux';
import { OxLink } from 'src/components/OxLink';
import { ERoutes } from 'src/config/enums';
import { applyValues } from 'src/utils/applyValues';
import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import { useValidateResponse } from 'src/hooks/useValidateResponse';

export enum EType {
    LOCATION = 'TYPE_LOCATION',
    USER = 'TYPE_USER'
}

type TProps = {
    startTime: DateTime;
    endTime: DateTime;
    duration: number;
    location: TLocationFilter | null;
    timezone: string;
    selectedDate: DateTime;
    type: EType;
};

type TTile = {
    time: DateTime;
    appointment?: TAppointment;
};

export const OxStaffTimeline = (props: TProps): JSX.Element => {
    const { validateResponse } = useValidateResponse();
    const store = useStore();
    const { user }: { user?: TUser } = store.getState();
    const [appointmentList, setAppointmentList] = useState<TClinicAppointments>({});
    const fetchAppointmentList = async (): Promise<TClinicAppointments> => {
        const date = props.selectedDate.toFormat('yyyy-MM-dd');
        const list =
            props.type === EType.USER
                ? await getPractitionerAppointment(date, date)
                : await getAppointments(user?.clinicId ?? '');

        try {
            validateResponse(list);
        } catch (e) {
            console.error(e.error.title);
            return {};
        }

        return list || {};
    };

    const formatTime = (time: DateTime): string => {
        return time.setLocale('en').toFormat('t').toString();
    };

    const getLocationAppointments = (): TAppointment[] => {
        if (!props.location)
            return Object.keys(appointmentList).reduce((acc: TAppointment[], currKey) => {
                return acc.concat(appointmentList[currKey]?.appointments ?? []);
            }, []);

        if (appointmentList[props.location.id])
            return appointmentList[props.location.id].appointments;

        return [] as TAppointment[];
    };

    const getAppointmentType = (appointment?: TAppointment): string => {
        if (!appointment) return '';
        return appointment.appointmentType ?? '';
    };

    const getTiles = (): TTile[] => {
        if (!props.startTime || !props.endTime) return [];
        const diff = Math.abs(props.endTime.diff(props.startTime, 'minutes').minutes);
        const count = Math.floor(diff / props.duration) + 1;
        const tiles: TTile[] = [];

        const appointments = getLocationAppointments();

        for (let i = 0; i < count; i++) {
            const time = props.startTime.plus({ minutes: props.duration * i });
            tiles.push({
                time: time,
                appointment: appointments.find((item) => {
                    const startTimestamp = DateTime.fromISO(item.startsAt ?? '', {
                        zone: props.timezone
                    }).toMillis();
                    const endTimestamp = DateTime.fromISO(item.endsAt ?? '', {
                        zone: props.timezone
                    }).toMillis();
                    const tileStartTimestamp = time.toMillis();
                    const tileEndTimestamp = time.plus({ minutes: props.duration }).toMillis();

                    return (
                        (startTimestamp >= tileStartTimestamp &&
                            startTimestamp < tileEndTimestamp) ||
                        (endTimestamp > tileStartTimestamp && endTimestamp <= tileEndTimestamp) ||
                        (startTimestamp <= tileStartTimestamp && endTimestamp >= tileEndTimestamp)
                    );
                })
            });
        }

        for (let i = 0; i < tiles.length; i++) {
            if (tiles[i].appointment !== undefined) {
                if (tiles[i].appointment?.id === tiles[i + 1]?.appointment?.id) {
                    tiles.splice(i + 1, 1);
                }
            }
        }

        return tiles;
    };

    const now = DateTime.now();

    useEffect(() => {
        let unmounted = false;
        const asyncInitAction = async (): Promise<void> => {
            const list = await fetchAppointmentList();
            if (!unmounted) {
                setAppointmentList(list);
            }
        };
        asyncInitAction();
        return (): void => {
            unmounted = true;
        };
    }, [props.selectedDate]);

    useEffect(() => {
        let unmounted = false;
        const asyncInitAction = async (): Promise<void> => {
            const list = await fetchAppointmentList();
            if (!unmounted) {
                setAppointmentList(list);
            }
        };
        asyncInitAction();
        return (): void => {
            unmounted = true;
        };
    }, []);

    const tiles = getTiles();

    return (
        <Styled.Container>
            <Styled.CurrentDate>
                {props.selectedDate.setLocale('en').toFormat('ccc dd LLL').toString()}
            </Styled.CurrentDate>
            {tiles.map((tile, index) => (
                <Styled.Tile
                    key={index}
                    vertical={!!tile.appointment}
                    type={getAppointmentType(tile.appointment)}
                >
                    <Styled.Time>{formatTime(tile.time)}</Styled.Time>
                    {!tile.appointment && (
                        <Styled.Availability>
                            {tile.time < now ? 'AVAILABLE' : 'AVAILABLE'}
                        </Styled.Availability>
                    )}
                    {!!tile.appointment && (
                        <>
                            <Styled.Detail>
                                <OxThemedInput theme={EInputTheme.BackgroundWhiteAlternative}>
                                    <OxLink
                                        to={applyValues(ERoutes.PanelStaffDashboardPatientById, {
                                            patientId: tile.appointment?.patientId ?? ''
                                        })}
                                        withUnderlineAnimation
                                    >
                                        {tile.appointment.patientName}
                                    </OxLink>
                                </OxThemedInput>
                            </Styled.Detail>
                            <Styled.Detail>{getAppointmentType(tile.appointment)}</Styled.Detail>
                        </>
                    )}
                </Styled.Tile>
            ))}
        </Styled.Container>
    );
};
