import React, { useEffect } from 'react';
import { Router, navigate, WindowLocation } from '@reach/router';

import { ERoutes } from 'src/config/enums';
import { useStore } from 'react-redux';
import { OxStaffDashboardMyAccount } from './components/OxStaffDashboardMyAccount';
import { OxStaffDashboardPsychologicalAttributes } from './components/OxStaffDashboardPsychologicalAttributes';
import { OxStaffDashboardCreateEditTreatmentPlan } from './components/OxStaffDashboardCreateEditTreatmentPlan';
import { Panel404 } from 'src/panel/routes/Panel404';
import { OxStaffDashboardAllFeedback } from './components/OxStaffDashboardAllFeedback';
import { OxStaffDashboardViewTreatmentPlans } from './components/OxStaffDashboardViewTreatmentPlans';
import { OxStaffDashboardRefreshments } from './components/OxStaffDashboardRefreshments';
import { OxStaffDashboardPatientSearch } from './components/OxStaffDashboardPatientSearch';
import { OxStaffDashboardPatient } from './components/OxStaffDashboardPatient';
import { isUserCustomer } from 'src/helpers/user';
import { OxPanelBaristaRefreshmentList } from 'src/panel/components/OxPanelStaffDashboard/components/OxPanelBaristaRefreshmentList';
import { OxStaffDashboardTreatmentNotes } from 'src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardTreatmentNotes';
import { OxStaffDashboardDamagedProducts } from './components/OxStaffDashboardDamagedProducts';
import { OxStaffDashboardNoShow } from 'src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardNoShow';
import { OxPageHelmet } from 'src/components/OxPageHelmet';
import { OxStaffDashboardTreatmentObservations } from './components/OxStaffDashboardTreatmentObservations';
import { OxStaffDashboardTreatmentAesthetician } from './components/OxStaffDashboardTreatmentAesthetician';
import { OxStaffDashboardShareConsent } from './components/OxStaffDashboardShareConsent';

type TProps = {
    location?: WindowLocation;
};

export const OxPanelStaffDashboard = ({ location }: TProps): JSX.Element => {
    const store = useStore();
    const { user } = store.getState();

    useEffect(() => {
        const asyncInit = async (): Promise<void> => {
            (await isUserCustomer(user)) && (await navigate(ERoutes.PanelCustomerDashboard));
        };
        asyncInit();
    }, []);

    return (
        <>
            <OxPageHelmet title="Staff Dashboard" />
            <Router>
                <OxStaffDashboardMyAccount path="/" location={location} />
                {/*<OxStaffDashboardAllMessages path="/messages" location={location} />*/}
                <OxStaffDashboardRefreshments path="/refreshments" location={location} />
                <OxStaffDashboardPatient path="/patient/:patientId" location={location} />
                <OxStaffDashboardPatientSearch
                    clinicId={user.clinicId}
                    path="/patient-search"
                    location={location}
                />
                <OxStaffDashboardAllFeedback path="/feedback" location={location} />
                <OxStaffDashboardViewTreatmentPlans
                    clinicId={user.clinicId}
                    path="/treatment-plans/:patientId"
                    location={location}
                />
                <OxStaffDashboardCreateEditTreatmentPlan
                    path="/create-edit-treatment-plan"
                    location={location}
                />
                <OxStaffDashboardPsychologicalAttributes
                    path="/psychological-attributes/:patientId"
                    location={location}
                />
                <OxPanelBaristaRefreshmentList
                    path="/barista-refreshments-list"
                    location={location}
                />
                <OxStaffDashboardTreatmentNotes path="/treatment-notes" location={location} />
                <OxStaffDashboardDamagedProducts path="/damaged-products" location={location} />
                <OxStaffDashboardTreatmentObservations
                    path="/treatment-observations/:patientId"
                    location={location}
                />
                <OxStaffDashboardTreatmentAesthetician
                    path="/treatment-aesthetician/:patientId"
                    location={location}
                    practitionerId={user.id}
                />
                <OxStaffDashboardShareConsent
                    path="/share-consent/:patientId"
                    location={location}
                />
                <OxStaffDashboardNoShow path="/no-show" location={location} />
                <Panel404 default />
            </Router>
        </>
    );
};
