import { EFontWeight, EFonts } from 'src/config/enums';
import styled, { css } from 'styled-components';

import { OxContainer } from 'src/components/OxContainer';
import { createTextStyle } from 'src/utils/createTextStyle';
import { fluidSizing } from 'src/utils';

export const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;

export const Header = styled(OxContainer)`
    display: flex;
    flex-direction: column;
    ${fluidSizing([{ prop: 'margin-bottom', values: [50, 45, 40] }])}
`;

export const Logout = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: row;
        color: ${theme.colors.primary.main};
        svg {
            transform: rotate(180deg);
            ${fluidSizing([
                { prop: 'width', values: [15, 15, 15] },
                { prop: 'margin-right', values: [9, 9, 9] }
            ])}
        }
        ${fluidSizing([{ prop: 'margin-bottom', values: [33, 30, 30] }])}
    `
);

export const WelcomeText = styled.div`
    letter-spacing: 0.03em;
    ${createTextStyle(EFonts.Hatton, EFontWeight.SemiBold, [27, 27, 27], [34, 34, 34])}
`;

export const ContentContainer = styled(OxContainer)<{ count: number }>(
    ({ theme, count }) => css`
        display: flex;
        flex-wrap: wrap;
        ${count === 1 &&
        css`
            justify-content: center;
        `}
        ${count > 1 &&
        css`
            justify-content: space-between;
        `}

    ${fluidSizing([
            { prop: 'padding-bottom', values: [32, 70, 80] },
            { prop: 'padding-top', values: [24, 50, 60] },
            { prop: 'margin-bottom', values: [50, 60, 65] }
        ])}
    ${theme.breakpoints.only('xs')} {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        background-color: ${theme.colors.basic.ouronyxLogo};
    `
);

export const Label = styled.button`
    ${createTextStyle(EFonts.Montserrat, EFontWeight.Bold, [10, 10, 10], [14, 14, 14])}
`;
