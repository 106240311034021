import { EFontWeight, EFonts } from 'src/config/enums';
import styled, { css } from 'styled-components';

import { OxLink } from 'src/components/OxLink';
import { createTextStyle } from 'src/utils/createTextStyle';
import { fluidSizing } from 'src/utils';

export const Container = styled.div`
    width: 100%;
    ${fluidSizing([
        { prop: 'padding-top', values: [20, 35, 35] },
        { prop: 'padding-bottom', values: [35, 50, 60] },
        { prop: 'padding-left ', values: [28, 50, 60] },
        { prop: 'padding-right ', values: [28, 50, 60] }
    ])}
`;

export const Return = styled(OxLink)(
    ({ theme }) => css`
        display: flex;
        align-items: center;
        color: ${theme.colors.primary.main};
        ${fluidSizing([{ prop: 'margin-bottom', values: [25, 25, 25] }])}
        svg {
            ${fluidSizing([
                { prop: 'height', values: [5, 5, 5] },
                { prop: 'margin-right', values: [5, 5, 5] },
                { prop: 'margin-bottom', values: [2, 2, 2] }
            ])}
            transform: rotate(180deg);
        }
        span {
            text-transform: uppercase;
            ${createTextStyle(EFonts.Montserrat, EFontWeight.SemiBold, [10, 10, 10], [20, 20, 20])}
        }
    `
);

export const Header = styled.h2`
    ${fluidSizing([{ prop: 'margin-bottom', values: [20, 20, 20] }])}
    ${createTextStyle(EFonts.Hatton, EFontWeight.SemiBold, [27, 27, 27], [34, 34, 34])}
`;

export const Content = styled.div``;
