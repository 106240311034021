import { EApiEndpoint } from 'src/config/enums';
import {
    TTreatmentConsent,
    TTreatmentGoal,
    TTreatmentPlan,
    TTreatmentPlanCreateRequest
} from './api.types';
import { apiDeleteCall, apiGetCall, apiPostCall, apiPutCall } from './api.core';
import { applyValues } from 'src/utils/applyValues';

export const getTreatmentPlanGoals = async (): Promise<TTreatmentGoal[] | null> => {
    return await apiGetCall(EApiEndpoint.TreatmentPlanGoals, {
        credentials: true
    });
};

export const postTreatmentPlan = async (
    patientId: string,
    values: TTreatmentPlanCreateRequest
): Promise<TTreatmentPlan | null> => {
    return await apiPostCall(
        applyValues(EApiEndpoint.TreatmentPlanPlan, { patientId }),
        JSON.stringify(values),
        {
            credentials: true
        }
    );
};

export const getTreatmentPlan = async (values: {
    patientId: string;
}): Promise<{ plans: TTreatmentPlan[]; downloadUrl?: string } | null> => {
    return await apiGetCall(applyValues(EApiEndpoint.TreatmentPlanPlan, values), {
        credentials: true
    });
};

export const transferTreatmentPlan = async (values: {
    patientId: string;
}): Promise<{ plans: TTreatmentPlan[]; downloadUrl?: string } | null> => {
    return await apiPostCall(applyValues(EApiEndpoint.TreatmentPlanPlanTransfer, values), '', {
        credentials: true
    });
};

export const archiveTreatmentPlan = async (values: {
    patientId: string;
    planId: number;
}): Promise<null> => {
    return await apiPutCall(applyValues(EApiEndpoint.TreatmentPlanPlanArchive, values), '', {
        credentials: true
    });
};

export const deleteTreatmentPlan = async (values: {
    patientId: string;
    planId: number;
}): Promise<null> => {
    return await apiDeleteCall(applyValues(EApiEndpoint.TreatmentPlanPlanDelete, values), {
        credentials: true
    });
};

export const getTreatmentConsent = async (values: {
    patientId: string;
}): Promise<TTreatmentConsent[] | null> => {
    return await apiGetCall(applyValues(EApiEndpoint.TreatmentConsent, values), {
        credentials: true
    });
};

export const postTreatmentConsent = async (values: {
    patientId: string;
    imageData: string;
}): Promise<TTreatmentPlan[] | null> => {
    return await apiPostCall(
        applyValues(EApiEndpoint.TreatmentConsent, values),
        JSON.stringify({
            filename: 'signature.png',
            imageData: values.imageData
        }),
        {
            credentials: true
        }
    );
};

export const postShareConsent = async (values: {
    patientId: string;
    imageData: string;
    type: string;
}): Promise<TTreatmentPlan[] | null> => {
    return await apiPostCall(
        EApiEndpoint.ShareConsent,
        JSON.stringify({
            filename: 'signature.png',
            imageData: values.imageData,
            type: values.type,
            patientId: values.patientId
        }),
        {
            credentials: true
        }
    );
};

export const getShareConsent = async (values: {
    patientId: string;
}): Promise<TTreatmentConsent[] | null> => {
    return await apiGetCall(applyValues(EApiEndpoint.ShareConsentGet, values), {
        credentials: true
    });
};
