import { TStripeClientSecret, TStripeUserMethods } from './api.types';
import { apiDeleteCall, apiGetCall, apiPostCall } from 'src/services/api/api.core';

import { EApiEndpoint } from 'src/config/enums';
import { applyValues } from 'src/utils/applyValues';

export const newUserIntent = async (): Promise<TStripeClientSecret | null> => {
    return await apiPostCall(EApiEndpoint.StripeUserIntent, '', {
        credentials: true
    });
};

export const getUserMethods = async (): Promise<TStripeUserMethods | null> => {
    return await apiGetCall(EApiEndpoint.StripeUserMethods, {
        credentials: true
    });
};

/**
 * Returns 201 if successful, 400 if not.  Use with useValidateResponse hook
 * @param values
 */
export const deleteUserMethod = async (values: { id: string }): Promise<null> => {
    return await apiDeleteCall(applyValues(EApiEndpoint.StripeUserMethod, values), {
        credentials: true
    });
};
