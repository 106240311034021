import * as Styled from './OxPanelBaristaRefreshmentListItem.styled';

import React, { useState } from 'react';
import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import { TPendingRefreshment } from 'src/services/api/api.types';
import { DateTime } from 'luxon';

type TProps = {
    message: TPendingRefreshment;
    acknowledgeMessage?: (id: number, baristaMessage: string) => void;
};

export const OxPanelBaristaRefreshmentListItem = ({
    message,
    acknowledgeMessage
}: TProps): JSX.Element => {
    const completed = !acknowledgeMessage;
    const [baristaComment, setBaristaComment] = useState<string>(message.baristaComment ?? '');
    return (
        <Styled.Message completed={completed}>
            <Styled.Content>
                <Styled.Detail>
                    <Styled.DetailLabel>Staff Member:</Styled.DetailLabel>
                    <Styled.DetailItem>
                        {message.creator.firstname} {message.creator.lastname}
                    </Styled.DetailItem>
                </Styled.Detail>

                <Styled.Detail>
                    <Styled.DetailLabel>Requested At:</Styled.DetailLabel>
                    <Styled.DetailItem>
                        {DateTime.fromISO(message.createdAt).toFormat('HH:mm')}
                    </Styled.DetailItem>
                </Styled.Detail>

                <Styled.Detail>
                    <Styled.DetailLabel>Client Name:</Styled.DetailLabel>
                    <Styled.DetailItem>
                        {message.patient.firstname} {message.patient.lastname}
                    </Styled.DetailItem>
                </Styled.Detail>

                <Styled.Detail>
                    <Styled.DetailLabel>Refreshment:</Styled.DetailLabel>
                    <Styled.DetailItem capitalize={true}>
                        {message.refreshments.split(',').join(', ')}
                    </Styled.DetailItem>
                </Styled.Detail>

                {message.notes && (
                    <Styled.Detail>
                        <Styled.DetailLabel>Request Note:</Styled.DetailLabel>
                        <Styled.DetailItem>{message.notes}</Styled.DetailItem>
                    </Styled.Detail>
                )}

                {completed && (
                    <Styled.Detail>
                        <Styled.DetailLabel>Host Comment:</Styled.DetailLabel>
                        <Styled.DetailItem>
                            {message.baristaComment ? message.baristaComment : 'n/a'}
                        </Styled.DetailItem>
                    </Styled.Detail>
                )}
            </Styled.Content>

            {!completed && (
                <OxThemedInput theme={EInputTheme.BackgroundGold}>
                    <Styled.Acknowledge
                        type="button"
                        onClick={(): void => {
                            acknowledgeMessage && acknowledgeMessage(message.id, baristaComment);
                        }}
                    >
                        <Styled.AcknowledgeIcon name="tick" size={15} />
                    </Styled.Acknowledge>
                </OxThemedInput>
            )}

            {!completed && (
                <OxThemedInput theme={EInputTheme.BackgroundWhiteAlternative}>
                    <Styled.Notes
                        placeholder="Host Comment"
                        name="notes"
                        value={baristaComment}
                        onValueChange={setBaristaComment}
                        disabled={!acknowledgeMessage}
                    />
                </OxThemedInput>
            )}
        </Styled.Message>
    );
};
