import * as Styled from './OxStaffDashboardPageSectionHeader.styled';

import React from 'react';

type TProps = {
    children: string | React.ReactFragment;
    removeBottomBorder?: boolean;
};

export const OxStaffDashboardPageSectionHeader = ({
    children,
    removeBottomBorder
}: TProps): JSX.Element => {
    return (
        <Styled.SectionHeader removeBottomBorder={removeBottomBorder}>
            {children}
        </Styled.SectionHeader>
    );
};
