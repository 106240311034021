import styled, { css } from 'styled-components';
import { Input as StyledInput } from 'src/components/OxInput/OxInput.styled';
import { createTextStyle } from 'src/utils/createTextStyle';
import { EFonts, EFontWeight } from 'src/config/enums';
import { fluidSizing } from 'src/utils';
import { OxButton } from 'src/components/OxButton';
import { OxInput } from 'src/components/OxInput';

export const Container = styled.div(
    ({ theme }) => css`
        align-items: center;
        background-color: ${theme.colors.basic.lightPanel};
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        ${fluidSizing([{ prop: 'height', values: [416, 416, 416] }])}
    `
);

export const Title = styled.div`
    letter-spacing: -0.116667px;
    ${createTextStyle(EFonts.Hatton, EFontWeight.Medium, [35, 35, 35], [37, 37, 37])}
    ${fluidSizing([{ prop: 'margin-bottom', values: [25, 25, 25] }])}
`;

export const Description = styled.div`
    ${createTextStyle(EFonts.Montserrat, EFontWeight.Normal, [12, 12, 12], [15, 15, 15])}
`;

export const Input = styled(OxInput)`
    text-align: center;

    ${fluidSizing([
        { prop: 'margin-top', values: [30, 30, 30] },
        { prop: 'width', values: [200, 200, 200] }
    ])}

    ${StyledInput} {
        text-align: center;
    }
`;

export const Button = styled(OxButton)`
    ${fluidSizing([{ prop: 'margin-top', values: [30, 30, 30] }])}
`;

export const Error = styled.span(
    ({ theme }) => css`
        color: ${theme.colors.basic.darkRed};
        ${fluidSizing([{ prop: 'margin-top', values: [10, 10, 10] }])}
    `
);
