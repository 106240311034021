import { OxPanelLayout } from '../OxPanelLayout';
import { PanelCreateAccount } from 'src/panel/routes/PanelCreateAccount';
import { PanelCustomerDashboard } from 'src/panel/routes/PanelCustomerDashboard';
import { PanelCustomerLoginPage } from 'src/panel/routes/PanelCustomerLoginPage';
import { PanelHomePage } from 'src/panel/routes/PanelHomePage';
import { PanelResetPassword } from 'src/panel/routes/PanelResetPassword';
import { PanelStaffDashboard } from 'src/panel/routes/PanelStaffDashboard';
import { PanelStaffLanding } from 'src/panel/routes/PanelStaffLanding';
import { PanelStaffLoginPage } from 'src/panel/routes/PanelStaffLoginPage';
import React from 'react';
import { Router } from '@reach/router';
import { withPrefix } from 'gatsby';
import { PanelWelcomeInternal } from 'src/panel/routes/PanelWelcomeInternal/PanelWelcomeInternal';
import { PanelStaffPin } from 'src/panel/routes/PanelStaffPin';
import { PanelPasswordlessLoginCheck } from 'src/panel/routes/PanelPasswordlessLoginCheck';

export const OxPanelRoot = (): JSX.Element => {
    return (
        <OxPanelLayout>
            <Router basepath={withPrefix('/panel')}>
                <PanelHomePage path={withPrefix('/')} />
                <PanelStaffLoginPage path={withPrefix('/staff/login')} />
                <PanelStaffLoginPage path={withPrefix('/staff/login')} />
                <PanelPasswordlessLoginCheck path={withPrefix('/customer/login/check')} />
                <PanelCustomerLoginPage path={withPrefix('/customer/login')} />
                <PanelStaffLanding path={withPrefix('/staff/landing')} />
                <PanelCustomerDashboard path={withPrefix('/customer/dashboard/*')} />
                <PanelResetPassword path={withPrefix('/resetPassword')} />
                <PanelStaffDashboard path={withPrefix('/staff/dashboard/*')} />
                <PanelCreateAccount path={withPrefix('/create-account')} />
                <PanelWelcomeInternal path={withPrefix('/welcome-internal')} />
                <PanelStaffPin path={withPrefix('/staff/pin')} />
            </Router>
        </OxPanelLayout>
    );
};
