import * as Styled from './OxStaffDashboardCreateEditTreatmentPlan.styled';

import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
    TTreatmentGoal,
    TTreatmentOutcome,
    TTreatmentPlan as TExistingTreatmentPlan,
    TTreatmentPlanCreateRequest
} from 'src/services/api/api.types';
import {
    archiveTreatmentPlan,
    deleteTreatmentPlan,
    getTreatmentPlanGoals,
    postTreatmentPlan
} from 'src/services/api/treatmentplan';
import { stringifyNumber } from 'src/utils/stringifyNumber';
import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import { OxButton } from 'src/components/OxButton';
import { DateTime } from 'luxon';
import { navigate } from 'gatsby';
import { ERoutes } from 'src/config/enums';
import { OnyxError } from 'src/helpers/OnyxError';
import { AlertContext, EAlertVariant } from 'src/context/AlertContext';
import { randomString } from 'src/utils/randomString';
import { useValidateResponse } from 'src/hooks/useValidateResponse';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useStore } from 'react-redux';
import isClinicIdDubai from 'src/services/clinic/isClinicIdDubai';
import { OxPageHelmet } from 'src/components/OxPageHelmet';
import { Editor } from '@tinymce/tinymce-react';

type TProps<RouteComponentProps> = RouteComponentProps & Record<string, unknown>;

type TTreatmentPlan = TTreatmentPlanCreateRequest & {
    elementIdentifier?: string;
    existing?: boolean;
    planId?: number;
    clinic_id?: number;
};

type TState = {
    patientId?: string | number;
    clinicCurrency?: string;
    patientName?: string;
    activeTreatmentPlans?: TExistingTreatmentPlan[];
};

export const OxStaffDashboardCreateEditTreatmentPlan: React.FC<TProps<RouteComponentProps>> = ({
    location
}) => {
    const { validateResponse } = useValidateResponse();
    const store = useStore();
    const { user } = store.getState();
    const { showAlert } = useContext(AlertContext);
    const clinicId = user?.clinic?.id ?? user?.clinicId ?? 0;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [goals, setGoals] = useState<TTreatmentGoal[]>([]);
    const [treatmentDates, setTreatmentDates] = useState<string[]>([]);

    const makeIdentifier = (): string => {
        return `${Date.now()}${randomString(5)}`;
    };

    const selectedPlanTemplate: TTreatmentPlan = {
        goal_id: 0,
        outcome_id: 0,
        clinic_id: user.clinicId,
        appointmentDate: '',
        isMedical: !isClinicIdDubai(clinicId), // Dubai
        elementIdentifier: makeIdentifier()
    };

    const [selectedPlans, setSelectedPlans] = useState<TTreatmentPlan[]>([selectedPlanTemplate]);

    const {
        patientId,
        patientName,
        activeTreatmentPlans,
        clinicCurrency = 'GBP'
    } = (location?.state as TState) ??
    ({
        patientId: location?.hash,
        patientName: 'Test Name',
        activeTreatmentPlans: [],
        clinicCurrency: 'GBP'
    } as TState);

    const addBlankTreatment = (): void => {
        selectedPlanTemplate.elementIdentifier = makeIdentifier();
        setSelectedPlans((plans) => [...plans, selectedPlanTemplate]);
    };

    const removePlan = async (key: number, archive?: boolean): Promise<void> => {
        if (selectedPlans[key].existing) {
            let data;

            if (archive) {
                data = await archiveTreatmentPlan({
                    patientId,
                    planId: selectedPlans[key].planId
                });
            } else {
                data = await deleteTreatmentPlan({
                    patientId,
                    planId: selectedPlans[key].planId
                });
            }

            const newState = { ...location?.state } as TState,
                removedIndex = activeTreatmentPlans.findIndex(
                    (item) => item.planId === selectedPlans[key].planId
                );

            newState?.activeTreatmentPlans?.splice(removedIndex, 1);

            navigate('.', { state: newState, replace: true });

            if (Array.isArray(data) && data.length === 0) {
                throw new OnyxError({
                    type: EAlertVariant.Error,
                    header: 'ERROR ALERT: OSDETP1',
                    title: 'Removal Issue',
                    message:
                        'Something went wrong while trying to remove this plan, if the problem persists please contact us.'
                });
            }
        }
        setSelectedPlans((current) => {
            current.splice(key, 1);
            return [...current];
        });
    };

    const getTreatmentDates = (): void => {
        const dates: string[] = [];

        for (let i = 0; i <= 18; i++) {
            const date = DateTime.local().plus({ month: i });
            dates.push(date.toFormat('LLLL yyyy'));
        }

        setTreatmentDates(dates);
    };

    const onCustomDefinedElementChange = (planKey: number, e: SyntheticEvent): void => {
        const target = e.target as HTMLTextAreaElement;
        const { value } = target;
        setSelectedPlans((plans) => {
            plans[planKey].customOutcome = value;
            return [...plans];
        });
    };

    const customDefinedElement = (planKey: number, outcomeId: number): JSX.Element => {
        return (
            <OxThemedInput theme={EInputTheme.GoldTransparentAlternative3}>
                <Styled.CustomInput
                    placeholder={'[Enter custom outcome here]'}
                    value={selectedPlans[planKey].customOutcome}
                    required={selectedPlans[planKey].outcome_id === outcomeId}
                    onChange={(e: SyntheticEvent): void => onCustomDefinedElementChange(planKey, e)}
                />
            </OxThemedInput>
        );
    };

    const handleBackButtonClick = (): void => {
        navigate(-1);
    };

    const finaliseSelection = (): void => {
        let error = false;
        let count = 0;
        const filteredPlans = selectedPlans.filter(
            (plan) => plan.goal_id > 0 && plan.outcome_id > 0 && !plan.existing
        );

        filteredPlans.length === 0 && navigate(ERoutes.PanelStaffDashboard);

        filteredPlans.forEach((plan, index) => {
            setIsLoading(true);
            postTreatmentPlan(patientId as string, {
                ...plan,
                sortOrder: index
            })
                .then(validateResponse)
                .then(() => {
                    if (++count === filteredPlans.length) {
                        setIsLoading(false);
                        navigate(ERoutes.PanelStaffDashboard);
                    }
                })
                .catch(() => {
                    if (!error) {
                        error = true;

                        showAlert({
                            type: EAlertVariant.Error,
                            header: 'ERROR ALERT: OSDETP2',
                            title: 'Save Issue',
                            message:
                                'Please check your details and try again, if the problem persists please contact us.'
                        });
                    }
                })
                .finally(() => {
                    setIsLoading(count === filteredPlans.length);
                });
        });
    };

    useEffect(() => {
        selectedPlans.length === 0 && addBlankTreatment();
    }, [selectedPlans]);

    useEffect(() => {
        let unmounted = false;
        const asyncInit = async (): Promise<void> => {
            getTreatmentPlanGoals().then((data) => !unmounted && setGoals(data ?? []));
            getTreatmentDates();
        };

        asyncInit();

        if ((activeTreatmentPlans?.length ?? 0) > 0) {
            setSelectedPlans([
                ...(activeTreatmentPlans ?? []).map((plan) => ({
                    existing: true,
                    planId: plan.id,
                    goal_id: plan.goal.id ?? 0,
                    outcome_id: plan?.planOutcome?.outcome?.id ?? 0,
                    price: plan.price,
                    customOutcome: plan?.planOutcome?.customOutcome ?? '',
                    comment: plan?.comment,
                    clinic_id: plan?.clinicId,
                    elementIdentifier: makeIdentifier(),
                    appointmentDate: plan.appointmentDate
                        ? DateTime.fromISO(plan.appointmentDate ?? '').toFormat('LLLL yyyy')
                        : undefined,
                    isMedical: plan.isMedical
                }))
            ]);
        }
        return (): void => {
            unmounted = true;
        };
    }, []);

    const filterOutcomesByClinic = (outcomes: TTreatmentOutcome[] = []): TTreatmentOutcome[] => {
        return outcomes?.filter((outcome) => {
            return clinicId in (outcome?.clinicStatus ?? {}) && outcome.clinicStatus[clinicId];
        });
    };

    return (
        <Styled.Container>
            <OxPageHelmet title="Create / Edit Treatment Plans" />
            <Styled.HeaderWrapper>
                <Styled.BackButton onClick={handleBackButtonClick} />
                {!!patientName && <Styled.Header>{patientName}</Styled.Header>}
            </Styled.HeaderWrapper>
            <Styled.SubHeader>Treatment Plan Selection</Styled.SubHeader>
            <OxThemedInput theme={EInputTheme.Gold}>
                {selectedPlans.map((plan, planKey) => {
                    const planGoal = goals.find((goal) => goal.id === plan.goal_id);
                    const availablePlanGoalOutcomes = filterOutcomesByClinic(planGoal?.outcomes);
                    const planOutcome = planGoal?.outcomes?.find(
                        (outcome) => outcome.id === plan.outcome_id
                    );

                    return (
                        <Styled.Plan
                            key={`plan.${plan.elementIdentifier}.goalId.${plan.goal_id}.outcomeId.${plan.outcome_id}`}
                        >
                            <Styled.PlanHeader>
                                {stringifyNumber(planKey + 1)} Treatment
                                {!!plan.appointmentDate && `: ${plan.appointmentDate}`}
                                {plan.existing && (
                                    <OxButton
                                        onClick={(): Promise<void> => removePlan(planKey, true)}
                                    >
                                        Archive This Treatment
                                    </OxButton>
                                )}
                                {(selectedPlans.length > 1 || plan.existing) && (
                                    <OxButton onClick={(): Promise<void> => removePlan(planKey)}>
                                        Remove This Treatment
                                    </OxButton>
                                )}
                            </Styled.PlanHeader>
                            <Styled.PlanSection>
                                <Styled.PlanSectionHeader>Select Goal</Styled.PlanSectionHeader>
                                <OxThemedInput theme={EInputTheme.BackgroundWhite}>
                                    <Styled.RadioList
                                        name={`plan.${plan.elementIdentifier}.goals`}
                                        radioShape="circle"
                                        checkStyle="alt"
                                        disabled={!!plan.existing}
                                        onValueChange={(val) => {
                                            setSelectedPlans((plans) => {
                                                if (!plans[planKey].existing) {
                                                    plans[planKey].goal_id = parseInt(val);
                                                    plans[planKey].outcome_id = 0;
                                                }
                                                return [...plans];
                                            });
                                        }}
                                        count={goals.length}
                                        items={
                                            goals
                                                .filter(
                                                    (goal) =>
                                                        filterOutcomesByClinic(goal.outcomes ?? [])
                                                            .length > 0
                                                )
                                                .map((goal) => ({
                                                    value: goal.id?.toString(),
                                                    name: goal.title
                                                })) as {
                                                value: string;
                                                name: string;
                                            }[]
                                        }
                                        initialValue={plan.goal_id.toString()}
                                    />
                                </OxThemedInput>
                            </Styled.PlanSection>
                            {plan.goal_id > 0 && availablePlanGoalOutcomes.length > 0 && (
                                <Styled.PlanSection>
                                    <Styled.PlanSectionHeader>
                                        Select Outcome
                                    </Styled.PlanSectionHeader>
                                    <OxThemedInput theme={EInputTheme.BackgroundWhite}>
                                        <Styled.RadioList
                                            name={`plan.${plan.elementIdentifier}.goal.${plan.goal_id}`}
                                            radioShape="circle"
                                            checkStyle="alt"
                                            disabled={!!plan.existing}
                                            onValueChange={(val) => {
                                                setSelectedPlans((plans) => {
                                                    plans[planKey].outcome_id = parseInt(val);
                                                    return [...plans];
                                                });
                                            }}
                                            count={availablePlanGoalOutcomes.length ?? 0}
                                            items={
                                                availablePlanGoalOutcomes.map((outcome) => ({
                                                    value: outcome.id?.toString(),
                                                    name: outcome.title,
                                                    child:
                                                        outcome.title?.toLowerCase() ===
                                                        'dr defined outcome'
                                                            ? customDefinedElement(
                                                                  planKey,
                                                                  outcome.id
                                                              )
                                                            : null
                                                })) as {
                                                    value: string;
                                                    name: string;
                                                    child: JSX.Element | null;
                                                }[]
                                            }
                                            initialValue={plan.outcome_id.toString()}
                                        />
                                    </OxThemedInput>
                                </Styled.PlanSection>
                            )}
                            {!!planGoal && !!planOutcome && (
                                <Styled.PlanDetails>
                                    <OxThemedInput theme={EInputTheme.BackgroundWhite}>
                                        <Styled.TextArea
                                            name={`plan.${plan.elementIdentifier}.comment`}
                                            placeholder="Doctor's Notes (Optional)"
                                            value={plan.comment}
                                            disabled={!!plan.existing}
                                            showCount={(plan?.comment?.length ?? 0) > 1000}
                                            onValueChange={(val: string, editor: Editor): void => {
                                                !plan.existing &&
                                                    setSelectedPlans((plans) => {
                                                        plans[planKey].comment = val;
                                                        return [...plans];
                                                    });
                                            }}
                                        />
                                    </OxThemedInput>

                                    <Styled.PlanDetail>
                                        {planOutcome?.clinicIsFromPrice[clinicId] ? (
                                            <>Price Starts From: </>
                                        ) : (
                                            <>Price: </>
                                        )}
                                        <Styled.Bold>
                                            {getSymbolFromCurrency(clinicCurrency)}
                                            {plan.price?.toLocaleString() ??
                                                (planOutcome?.clinicPrice ?? {})[clinicId ?? '']}
                                        </Styled.Bold>
                                    </Styled.PlanDetail>
                                    <Styled.PlanAdditional>
                                        {!isClinicIdDubai(clinicId) && (
                                            <OxThemedInput theme={EInputTheme.BackgroundWhite}>
                                                <Styled.CheckboxWrapper>
                                                    <Styled.Checkbox
                                                        name={`plan.${plan.elementIdentifier}.isMedical`}
                                                        label="Medical Treatment"
                                                        shape="circle"
                                                        disabled={!!plan.existing}
                                                        checkStyle="alt"
                                                        value={
                                                            selectedPlans[planKey].isMedical ? 1 : 0
                                                        }
                                                        checked={!!selectedPlans[planKey].isMedical}
                                                        onClick={(): void => {
                                                            !plan.existing &&
                                                                setSelectedPlans((plans) => {
                                                                    plans[planKey].isMedical =
                                                                        !plans[planKey].isMedical;
                                                                    return [...plans];
                                                                });
                                                        }}
                                                    />
                                                </Styled.CheckboxWrapper>
                                            </OxThemedInput>
                                        )}

                                        <Styled.Select
                                            onValueChange={(val) => {
                                                !plan.existing &&
                                                    setSelectedPlans((plans) => {
                                                        plans[planKey].appointmentDate = val;
                                                        return [...plans];
                                                    });
                                            }}
                                            initialValue={plan.appointmentDate}
                                            items={treatmentDates}
                                            title="Treatment Date"
                                            disabled={!!plan.existing}
                                        />
                                    </Styled.PlanAdditional>
                                    {planKey ===
                                        parseInt(Object.keys(selectedPlans).pop() ?? '0') && (
                                        <Styled.Actions>
                                            <Styled.Action onClick={addBlankTreatment}>
                                                Add Another Treatment
                                            </Styled.Action>
                                            <OxThemedInput
                                                theme={EInputTheme.BackgroundGoldAlternative}
                                            >
                                                <Styled.Action
                                                    icon
                                                    loading={isLoading}
                                                    onClick={finaliseSelection}
                                                >
                                                    Finalise Selection
                                                </Styled.Action>
                                            </OxThemedInput>
                                        </Styled.Actions>
                                    )}
                                </Styled.PlanDetails>
                            )}
                        </Styled.Plan>
                    );
                })}
            </OxThemedInput>
        </Styled.Container>
    );
};
