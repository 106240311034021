import * as Styled from './OxStaffDashboardContainer.styled';

import React, { ReactNode } from 'react';

type TProps = {
    children: ReactNode;
};

export const OxStaffDashboardContainer = ({ children }: TProps) => {
    return <Styled.Container>{children}</Styled.Container>;
};
