import * as Styled from './OxLoginFlowStage2.styled';

import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import {
    clearAuthError,
    codeVrificationAuthFlow,
    setAuthProcessStatus
} from 'src/panel/store/auth/authActions';
import { useDispatch, useStore } from 'react-redux';

import { EAuthProcessStatus } from 'src/panel/store/types';
import { EOxInputType } from 'src/components/OxInput';
import { ERoutes } from 'src/config/enums';
import React from 'react';
import { getAuthError } from 'src/panel/store/auth/authSelectors';
import { navigate } from '@reach/router';
import { EAlertVariant } from 'src/context/AlertContext';
import { OnyxError } from 'src/helpers/OnyxError';
import { isUserAdmin } from 'src/helpers/user';
import { TOxLoginFlowStageProps } from 'src/panel/components/OxLoginFlow/OxLoginFlow';

export const OxLoginFlowStage2 = ({
    stage: { returnUrl, returnState }
}: TOxLoginFlowStageProps): JSX.Element => {
    const dispatch = useDispatch();
    const store = useStore();

    const onFormSubmit = async (data: FormData): Promise<void> => {
        const result = await dispatch(codeVrificationAuthFlow(data));

        if (result.success) {
            if (returnUrl) {
                navigate(returnUrl, { state: returnState });
            } else {
                const { user } = store.getState();
                if (await isUserAdmin(user)) {
                    navigate(ERoutes.PanelStaffLanding);
                } else {
                    navigate(ERoutes.PanelCustomerDashboard);
                }
            }

            dispatch(setAuthProcessStatus(EAuthProcessStatus.Inaction));
            return;
        }
        const error = getAuthError(store.getState());
        if (error.length > 0) {
            dispatch(clearAuthError());
            dispatch(setAuthProcessStatus(EAuthProcessStatus.Verification));
            throw new OnyxError({
                type: EAlertVariant.Error,
                header: 'ERROR ALERT: OLFS2PA',
                title: 'Something went wrong',
                message:
                    'Please check your details and try again, if the problem persists please contact us.'
            });
        }
    };

    return (
        <Styled.Container className="login2" onFormSubmit={onFormSubmit}>
            {({ submitButtonProps }) => (
                <>
                    <Styled.Title>Verification Code</Styled.Title>
                    <Styled.Paragraph>
                        A verification code has been sent to your email. This code will be valid for
                        15mins
                    </Styled.Paragraph>
                    <div>
                        <OxThemedInput theme={EInputTheme.GoldTransparent}>
                            <Styled.Input
                                type={EOxInputType.Text}
                                name="_auth_code"
                                placeholder="Verification Code"
                                required
                            />
                        </OxThemedInput>
                        <OxThemedInput theme={EInputTheme.BackgroundGold}>
                            <Styled.Button type="submit" icon {...submitButtonProps}>
                                LOGIN
                            </Styled.Button>
                        </OxThemedInput>
                    </div>
                </>
            )}
        </Styled.Container>
    );
};
