import {
    TAppointment,
    TAttachments,
    TClinic,
    TPatient,
    TPractitioner,
    TTreatmentPlan
} from './api.types';
import {
    apiGetCall,
    apiPostCall,
    apiPutCall,
    apiPatchCall,
    apiDeleteCall
} from 'src/services/api/api.core';

import { EApiEndpoint } from 'src/config/enums';
import { applyValues } from 'src/utils/applyValues';

export const getPatient = async (): Promise<[TPatient] | null> => {
    return await apiGetCall(EApiEndpoint.Patient, { credentials: true });
};

export const getPatientAppointments = async (): Promise<TAppointment[] | null> => {
    return await apiGetCall(EApiEndpoint.PatientAppointments, {
        credentials: true
    });
};

export const getPatientPractitioner = async (): Promise<TPractitioner | null> => {
    return await apiGetCall(EApiEndpoint.PatientPractitioner, {
        credentials: true
    });
};

export const getPatientClinic = async (): Promise<TClinic | null> => {
    return await apiGetCall(EApiEndpoint.PatientClinic, {
        credentials: true
    });
};

export const getPatientTreatmentplan = async (): Promise<TTreatmentPlan[] | null> => {
    return await apiGetCall(EApiEndpoint.PatientTreatmentplan, {
        credentials: true
    });
};

export const getPatientPersonalityArchetype = async (): Promise<string | null> => {
    return await apiGetCall(EApiEndpoint.PatientPersonalityArchetype, {
        credentials: true
    });
};

export const postPatientImageUrl = async (values: {
    filename: string | undefined;
    imageData: string | undefined;
}): Promise<[] | null> => {
    return await apiPostCall(EApiEndpoint.PatientImageUrl, JSON.stringify(values), {
        credentials: true
    });
};

export const deletePatientImage = async (): Promise<any> => {
    return await apiDeleteCall(EApiEndpoint.PatientImageUrl, {
        credentials: true
    });
};

export const putPatient = async (values: TPatient): Promise<TPatient | null> => {
    return await apiPutCall(EApiEndpoint.Patient, JSON.stringify(values), {
        credentials: true
    });
};

export const patchCancelAppointment = async (values: {
    clinicId: string;
    id: string;
}): Promise<[] | null> => {
    return await apiPatchCall(
        applyValues(EApiEndpoint.PatientAppointmentsCancel, values),
        JSON.stringify({
            reasonCode: 50,
            note: `Cancellation from Account`
        }),
        {
            credentials: true
        }
    );
};

export const putEditAppointment = async (values: {
    id: string;
    clinicId: string;
    appointment: TAppointment;
}): Promise<[] | null> => {
    return await apiPutCall(
        applyValues(EApiEndpoint.PatientAppointmentsById, {
            clinicId: values.clinicId,
            id: values.id
        }),
        JSON.stringify(values.appointment),
        {
            credentials: true
        }
    );
};

export const getAttachments = async (): Promise<TAttachments | null> => {
    return await apiGetCall(EApiEndpoint.PatientAttachments, {
        credentials: true
    });
};
