import * as Styled from 'src/components/OxAddRemoveButton/OxAddRemoveButton.styled';

import React from 'react';

type TProps = {
    onClick: () => void;
    hideText?: boolean;
    remove?: boolean;
    size?: number;
    children?: React.ReactFragment;
};

export const OxAddRemoveButton = ({
    onClick,
    hideText,
    remove,
    size,
    children
}: TProps): JSX.Element => {
    return (
        <Styled.Button type="button" onClick={onClick} hideText={hideText}>
            <Styled.Icon
                name="plus-in-circle"
                title={remove ? 'Remove' : 'Add'}
                size={size ?? 25}
                remove={remove}
                hideText={hideText}
            />
            {!hideText && (children ?? (remove ? 'Remove' : 'Add'))}
        </Styled.Button>
    );
};
