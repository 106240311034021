import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { EFonts, EFontWeight } from 'src/config/enums';
import { createTextStyle } from 'src/utils/createTextStyle';
import { fluidSizing } from 'src/utils';
import { OxRadioList } from 'src/components/OxRadioList';
import { OxButton } from 'src/components/OxButton';
import { OxSelect } from 'src/components/OxSelect';
import { OxInput } from 'src/components/OxInput';
import { OxBackButton } from 'src/components/OxBackButton';
import { OxCheckbox } from 'src/components/OxCheckbox';
import { TBasicTheme } from 'src/types/theme.types';
import { OxTextAreaWysiwyg } from 'src/components/OxTextAreaWysiwyg';

export const Container = styled.div(
    () => css`
        display: flex;
        flex-wrap: wrap;
        min-height: 100%;
        position: relative;
        width: 100%;

        ${fluidSizing([
            { prop: 'padding-left', values: [22, 50, 70] },
            { prop: 'padding-right', values: [22, 50, 70] },
            { prop: 'padding-top ', values: [40, 60, 60] },
            { prop: 'padding-bottom', values: [40, 60, 60] }
        ])}
    `
);

export const HeaderWrapper = styled.div(
    () => css`
        flex: 1 1 100%;
        position: relative;
        text-align: center;

        ${createTextStyle(EFonts.Hatton, EFontWeight.Medium, [16, 16, 16], [18, 18, 18])}
    `
);

export const BackButton = styled(OxBackButton)(
    ({ theme }) => css`
        color: ${theme.colors.primary.main};
        ${fluidSizing([
            { prop: 'padding-bottom', values: [10, 10, 10] },
            { prop: 'margin-bottom', values: [20, 20, 20] }
        ])}
    `
);

export const Header = styled.div(
    ({ theme }) => css`
        border-bottom: 0.1rem solid ${theme.colors.basic.black};
        display: inline-block;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);

        ${fluidSizing([
            { prop: 'padding-left', values: [20, 20, 20] },
            { prop: 'padding-right', values: [20, 20, 20] },
            { prop: 'padding-bottom', values: [10, 10, 10] },
            { prop: 'margin-bottom', values: [20, 20, 20] }
        ])}
    `
);

export const SubHeader = styled.div(
    () => css`
        flex: 1 1 100%;

        ${createTextStyle(EFonts.Hatton, EFontWeight.Medium, [18, 18, 18], [20, 20, 20])}

        ${fluidSizing([{ prop: 'margin-bottom', values: [20, 20, 20] }])}
    `
);

export const Plan = styled.div(
    ({ theme }) => css`
        background: ${theme.colors.basic.lightPanel};
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        ${fluidSizing([
            { prop: 'padding', values: [20, 20, 20] },
            { prop: 'margin-bottom', values: [20, 20, 20] }
        ])}
    `
);

export const PlanHeader = styled.div(
    ({ theme }) => css`
        align-items: center;
        display: flex;
        flex: 1 1 100%;
        flex-direction: column;
        justify-content: space-between;
        text-align: left;
        text-transform: capitalize;

        ${createTextStyle(EFonts.Hatton, EFontWeight.Medium, [16, 16, 16], [18, 18, 18])}

        ${fluidSizing([{ prop: 'margin-bottom', values: [10, 10, 10] }])}

    ${theme.breakpoints.up('s')} {
            flex-direction: row;
        }
    `
);

export const PlanSection = styled.div(
    () => css`
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 100%;
        justify-content: center;

        ${fluidSizing([{ prop: 'margin-bottom', values: [10, 10, 10] }])}
    `
);

export const PlanSectionHeader = styled.div(
    () => css`
        flex: 1 1 100%;
        text-align: center;

        ${createTextStyle(EFonts.Hatton, EFontWeight.Medium, [14, 14, 14], [16, 16, 16])}

        ${fluidSizing([
            { prop: 'margin-bottom', values: [10, 10, 10] },
            { prop: 'margin-top', values: [5, 5, 5] }
        ])}
    `
);

const RadioStyling = (theme: TBasicTheme): FlattenSimpleInterpolation => css`
    > * {
        background: ${theme.colors.basic.white};

        ${fluidSizing([
            { prop: 'border-radius', values: [50, 50, 50] },
            { prop: 'margin', values: [10, 10, 10] },
            { prop: 'margin-right', values: [0, 0, 0] },
            { prop: 'margin-left', values: [0, 0, 0] },
            { prop: 'padding-top', values: [10, 10, 10] },
            { prop: 'padding-right', values: [5, 5, 5] },
            { prop: 'padding-bottom', values: [10, 10, 10] }
        ])}

        label {
            color: ${theme.colors.basic.black};

            ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [8, 8, 8], [10, 10, 10])};
        }
    }
`;

export const RadioList = styled(OxRadioList)<{ count: number }>(
    ({ theme, count }) => css`
        align-items: center;
        display: grid;
        grid-template-columns: 100%;
        justify-content: center;

        ${fluidSizing([{ prop: 'min-width', values: [null, 500, 500] }])}

        ${RadioStyling(theme)}

    ${theme.breakpoints.up('s')} {
            grid-template-columns: ${count === 1 ? '50%' : '50% 50%'};
        }
    `
);

export const CustomInput = styled(OxInput)(
    () => css`
        width: 100%;

        ${fluidSizing([
            { prop: 'margin-bottom', values: [-5, -5, -5] },
            { prop: 'margin-top', values: [-5, -5, -5] }
        ])}

        & > input {
            width: 100%;

            ${fluidSizing([{ prop: 'height', values: [20, 20, 20] }])}

            ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [8, 8, 8], [8, 8, 8])}
        }
    `
);

export const PlanDetails = styled.div(
    () => css`
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 100%;
        justify-content: center;
    `
);

export const TextArea = styled(OxTextAreaWysiwyg)(
    ({ disabled }) => css`
        ${disabled &&
        css`
            opacity: 0.5;
        `}
        ${fluidSizing([{ prop: 'width', values: [200, 300, 300] }])}
    `
);

export const TextAreaCount = styled.span``;

export const Bold = styled.span`
    font-weight: ${EFontWeight.Bold};
`;

export const PlanDetail = styled.div(
    () => css`
        flex: 1 1 100%;
        text-align: center;

        ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [8, 8, 8], [10, 10, 10])}

        ${fluidSizing([{ prop: 'margin-top', values: [20, 20, 20] }])}
    `
);

export const PlanAdditional = styled.div(
    ({ theme }) => css`
        align-items: center;
        display: flex;
        flex: 1 1 100%;
        flex-direction: column;
        justify-content: center;

        ${fluidSizing([{ prop: 'margin-top', values: [20, 20, 20] }])}

        ${theme.breakpoints.up('s')} {
            flex-direction: row;
        }
    `
);

export const CheckboxWrapper = styled.div(
    ({ theme }) => css`
        flex-basis: unset;
        justify-self: center;
        width: 100%;

        ${fluidSizing([{ prop: 'flex-basis', values: [null, 200, 200] }])}

        ${RadioStyling(theme)}
    `
);

export const Checkbox = styled(OxCheckbox)(
    () => css`
        flex-basis: unset;
        justify-self: center;
    `
);

export const Select = styled(OxSelect)(
    ({ theme }) => css`
        flex-basis: unset;
        justify-self: center;
        width: 100%;

        ${fluidSizing([{ prop: 'flex-basis', values: [null, 200, 200] }])}

        ${theme.breakpoints.up('s')} {
            width: unset;
        }
    `
);

export const Actions = styled.div(
    ({ theme }) => css`
        display: flex;
        flex: 1 1 100%;
        flex-direction: column;
        justify-content: space-between;

        ${fluidSizing([{ prop: 'margin-top', values: [20, 20, 20] }])}

        ${theme.breakpoints.up('s')} {
            flex-direction: row;
        }
    `
);

export const Action = styled(OxButton)(
    () => css`
        flex-basis: 48%;
    `
);
