import { OxButton } from 'src/components/OxButton';
import styled from 'styled-components';
import { fluidSizing } from 'src/utils';
import { OxInput } from 'src/components/OxInput';

export const SubmitButton = styled(OxButton)`
    margin-left: auto;
    margin-right: 0;
    width: initial;

    ${fluidSizing([{ prop: 'margin-top', values: [10, 10, 10] }])}
`;

export const QtyInput = styled(OxInput)`
    ${fluidSizing([{ prop: 'max-width', values: [75, 75, 75] }])}
`;
