import styled, { css } from 'styled-components';
import { fluidSizing } from 'src/utils';
import { createTextStyle } from 'src/utils/createTextStyle';
import { EFonts, EFontWeight } from 'src/config/enums';

export const SectionHeader = styled.h2<{ removeBottomBorder?: boolean }>(
    ({ theme, removeBottomBorder }) => css`
        ${!removeBottomBorder &&
        css`
            border-bottom: 1px solid ${theme.colors.basic.black};
            ${fluidSizing([{ prop: 'margin-bottom', values: [10, 10, 20] }])}
        `}

        ${fluidSizing([
            { prop: 'padding-bottom', values: [10, 10, 20] },
            { prop: 'padding-top', values: [10, 10, 20] }
        ])}

    ${createTextStyle(EFonts.Hatton, EFontWeight.SemiBold, [16, 16, 16], [18, 18, 18])}

    @media print {
            font-size: 2rem;
            margin-bottom: 1rem;
            padding-bottom: 0.5rem;
        }
    `
);
