import * as Styled from './OxStaffDashboardFinance.styled';

import { Area, AreaChart, ResponsiveContainer, XAxis } from 'recharts';
import React, { useMemo, useState } from 'react';
import {
    getPractitionerEarnings,
    getPractitionerInvoices,
    getPractitionerRevenue
} from 'src/services/api/practitioner';

import { DateTime } from 'luxon';
import { EColors } from 'src/config/enums';
import { TPractitionerGraphData } from 'src/services/api/api.types';
import { useInView } from 'src/hooks/useInViewport';
import { useValidateResponse } from 'src/hooks/useValidateResponse';
import { getClinikoUrlByClinic } from 'src/services/api/api.core';
import { useStore } from 'react-redux';

type TProps = {
    id: string;
};

export const OxStaffDashboardFinance = (props: TProps): JSX.Element => {
    const { validateResponse } = useValidateResponse();
    const store = useStore();
    const { user } = store.getState();
    const [activeFilter, setActiveFilter] = useState<string>('revenue');
    const [summaryValue, setSummaryValue] = useState('');
    const [invoices, setInvoices] = useState<TPractitionerGraphData>({});
    const [revenue, setRevenue] = useState<TPractitionerGraphData>({});
    const [earnings, setEarnings] = useState<TPractitionerGraphData>({});
    const chartData = useMemo(() => {
        const dateFormat = 'LLL y';
        const data = [];
        const selectedData = ((): TPractitionerGraphData => {
            switch (activeFilter) {
                case 'revenue':
                    return revenue;
                case 'earnings':
                    return earnings;
                case 'invoices':
                    return invoices;
                default:
                    return revenue;
            }
        })();

        for (const key of Object.keys(selectedData)) {
            const date = DateTime.fromISO(key).toFormat(dateFormat);
            const dataItem = data.find((item) => item.date === date);
            if (dataItem) {
                dataItem.value += selectedData[key];
            } else {
                data.push({
                    date: date,
                    value: selectedData[key]
                });
            }
        }

        let summary: string | number = data.reduce((prev, { value }) => prev + value, 0);

        if (['revenue', 'earnings'].includes(activeFilter)) {
            summary = new Intl.NumberFormat('en-UK', {
                style: 'currency',
                currency: 'GBP'
            }).format(summary);
        }

        setSummaryValue(`${summary}`);

        return data
            .sort((a, b) => {
                const timestampA = DateTime.fromFormat(a.date, dateFormat);
                const timestampB = DateTime.fromFormat(b.date, dateFormat);
                return timestampA.toMillis() - timestampB.toMillis();
            })
            .map((item) => ({
                ...item,
                date: DateTime.fromFormat(item.date, dateFormat).toFormat('LLL')
            }));
    }, [invoices, revenue, earnings, activeFilter]);

    const filters = [
        { name: 'Revenue Generated', type: 'revenue' },
        { name: 'Total Earnings', type: 'earnings' },
        { name: 'Invoices Created', type: 'invoices' }
    ];

    const [containerRef] = useInView({
        unobserveOnEnter: true,
        onEnter: () => {
            const asyncInit = async (): Promise<void> => {
                const dataRange = {
                    startDate: DateTime.local().minus({ month: 12 }).toUTC().toString(),
                    endDate: DateTime.local().toUTC().toString()
                };

                getPractitionerInvoices(dataRange)
                    .then(validateResponse)
                    .then(setInvoices)
                    .catch(console.error);
                getPractitionerRevenue(dataRange)
                    .then(validateResponse)
                    .then(setRevenue)
                    .catch(console.error);
                getPractitionerEarnings(dataRange)
                    .then(validateResponse)
                    .then(setEarnings)
                    .catch(console.error);
            };
            asyncInit();
        }
    });

    const Tick = (props: any): JSX.Element => (
        <Styled.TickWrapper>
            <circle cx={props.x} cy={props.y - 8} r={4} />
            <text x={props.x} y={props.y + 20} textAnchor="middle">
                {props.payload.value}
            </text>
        </Styled.TickWrapper>
    );

    return (
        <Styled.Container id={props.id} ref={containerRef}>
            <Styled.Heading>Finance</Styled.Heading>
            <Styled.Filters>
                <div>
                    {filters.map((filter) => (
                        <Styled.FilterItem
                            key={filter.type}
                            active={filter.type === activeFilter}
                            onClick={(): void => setActiveFilter(filter.type)}
                        >
                            {filter.name}
                        </Styled.FilterItem>
                    ))}
                </div>
                <Styled.FilterViewAll
                    to={getClinikoUrlByClinic({
                        endpoint: 'reports',
                        clinicId: user?.clinicId ?? 0
                    })}
                    target="_blank"
                >
                    View All Invoices
                </Styled.FilterViewAll>
            </Styled.Filters>
            <Styled.ChartContainer>
                <Styled.TotalInfoBox variant={activeFilter}>
                    <span>TOTAL: {summaryValue}</span>
                </Styled.TotalInfoBox>
                <ResponsiveContainer>
                    <AreaChart
                        data={chartData}
                        stackOffset="wiggle"
                        margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                    >
                        <defs>
                            <linearGradient id="color_earning" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="40%" stopColor={EColors.Gold} stopOpacity={1} />
                                <stop offset="100%" stopColor={EColors.Gold} stopOpacity={0} />
                            </linearGradient>
                            <linearGradient id="color_invoices" x1="0" y1="0" x2="0" y2="1">
                                <stop
                                    offset="40%"
                                    stopColor={EColors.DisabledTile}
                                    stopOpacity={1}
                                />
                                <stop
                                    offset="100%"
                                    stopColor={EColors.DisabledTile}
                                    stopOpacity={0}
                                />
                            </linearGradient>
                        </defs>
                        <XAxis
                            dataKey="date"
                            tick={Tick}
                            padding={{ left: 30, right: 30 }}
                            tickLine={false}
                        />
                        <Area
                            type="monotone"
                            dataKey="value"
                            stroke={EColors.DisabledTile}
                            fillOpacity={1}
                            fill={`url(#color_${activeFilter})`}
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </Styled.ChartContainer>
        </Styled.Container>
    );
};
