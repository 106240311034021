import * as Styled from './OxPanelLogin.styled';

import { EOxLoginFlowType, OxLoginFlow } from '../OxLoginFlow';
import { graphql, useStaticQuery } from 'gatsby';

import React from 'react';
import { OxPageHelmet } from 'src/components/OxPageHelmet';

type TProps = {
    variant: EOxLoginFlowType;
    state?: {
        returnUrl?: string;
        returnState?: any;
        email?: string;
    };
};

export const OxPanelLogin = ({ variant, state }: TProps): JSX.Element => {
    const data = useStaticQuery(graphql`
        query {
            backgroundImage: file(relativePath: { eq: "panel/login_bg.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
    `);

    return (
        <Styled.Container
            fluidImages={data.backgroundImage.childImageSharp.gatsbyImageData}
            Tag="section"
            asBackground
        >
            <OxPageHelmet
                title={`${variant === EOxLoginFlowType.Customer ? `Customer` : 'Staff'} Login`}
            />
            <OxLoginFlow variant={variant} state={state} />
        </Styled.Container>
    );
};
