import { TClinicAppointments } from './api.types';

import { EApiEndpoint } from 'src/config/enums';
import { apiGetCall } from './api.core';
import { applyValues } from 'src/utils/applyValues';

export const getAppointments = async (clinicId: string): Promise<TClinicAppointments | null> => {
    return await apiGetCall(applyValues(EApiEndpoint.ClinicDashboardAppointments, { clinicId }), {
        credentials: true
    });
};
