import * as Styled from './OxStaffDashboardDamagedProductsTable.styled';

import React, { useContext, useEffect, useState } from 'react';
import { OxTable } from 'src/components/OxTable';
import { randomString } from 'src/utils/randomString';
import { OxSelect } from 'src/components/OxSelect';
import { EOxInputType, OxInput } from 'src/components/OxInput';
import { OxAddRemoveButton } from 'src/components/OxAddRemoveButton';
import { TDamageReportItem, TDearProduct, TDearReason } from 'src/services/api/api.types';
import { getProduct, getReasons } from 'src/services/api/dear';
import { AlertContext } from 'src/context/AlertContext';
import { OxForm } from 'src/components/OxForm';
import { formDataToObject } from 'src/utils/formDataToObject';
import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import { dearIsBotox } from 'src/utils/treatmentNoteUtils';
import { ProductIdentifier } from 'src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardDamagedProductsTable/components/ProductIdentifier';
import { useValidateResponse } from 'src/hooks/useValidateResponse';

type TProps = {
    onSubmit: (data: TDamageReportItem[]) => void;
    triggerDataClear?: number;
    clinicId: string;
};

type TSelectedProducts = {
    [key: string]: TDearProduct;
};

export const OxStaffDashboardDamagedProductsTable = ({
    onSubmit,
    triggerDataClear,
    clinicId
}: TProps): JSX.Element => {
    const { validateResponse } = useValidateResponse();
    const { showAlert } = useContext(AlertContext);
    const generateRowKey = (): string => `${Date.now()}${randomString(5)}`;
    const [rowKeys, setRowKeys] = useState<string[]>([generateRowKey()]);
    const [reasons, setReasons] = useState<TDearReason[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<TSelectedProducts>({});

    const columnTitles = ['Unit Numbers', 'Products', 'Qty/Units', 'Reason', '', ''];

    const validateBatch = (barcode: string, key: string): void => {
        if (!barcode) {
            setSelectedProducts((prev) => {
                delete prev[key];
                return { ...prev };
            });

            return;
        }

        getProduct({ barcode, clinicId })
            .then((data) => validateResponse(data))
            .then((data) =>
                setSelectedProducts((prev) => {
                    prev[key] = data;
                    return { ...prev };
                })
            )
            .catch((e) => {
                showAlert(e.error);
            });
    };

    const onFormSubmit = (formData: FormData): void => {
        // item.reason is coming through as string but should be number
        // We're casing to string to stop TS complaining
        const data = Object.values(formDataToObject(formData)).map((item) => ({
            ...item,
            reason: parseInt(`${item.reason}`)
        }));
        onSubmit(data);
    };

    useEffect(() => {
        setRowKeys([generateRowKey()]);
        setSelectedProducts({});
    }, [triggerDataClear]);

    useEffect(() => {
        if (rowKeys.length < Object.keys(selectedProducts).length) {
            setSelectedProducts((prev) => {
                const newData: TSelectedProducts = {};

                Object.keys(selectedProducts).forEach((key, index) => {
                    if (index < rowKeys.length) {
                        newData[key] = prev[key];
                    }
                });
                return newData;
            });
        }
    }, [rowKeys]);

    useEffect(() => {
        let unmounted = false;
        const asyncInit = async (): Promise<void> => {
            //get products
            getReasons()
                .then((data) => validateResponse(data))
                .then((data) => !unmounted && setReasons(data))
                .catch((e) => {
                    showAlert(e.error);
                });
        };
        asyncInit();

        return (): void => {
            unmounted = true;
        };
    }, []);

    const generateRow = (
        key: string,
        index: number
    ): [JSX.Element, JSX.Element, JSX.Element, JSX.Element, JSX.Element, JSX.Element] => {
        return [
            <ProductIdentifier
                key={key}
                lineKey={key}
                index={index}
                validateBatch={validateBatch}
            />,
            <span key={`${key}${selectedProducts[key]?.id}`}>
                <OxInput type={EOxInputType.Hidden} value={selectedProducts[key]?.id} />
                {selectedProducts[key]?.name}
            </span>,
            <span key={key}>
                <Styled.QtyInput
                    required
                    type={EOxInputType.Number}
                    initialValue={1}
                    min={1}
                    key={key}
                    name={`${index}.qty`}
                />
                {dearIsBotox(selectedProducts[key]) ? 'units' : 'syringes'}
            </span>,
            <OxSelect
                required
                key={key}
                name={`${index}.reason`}
                items={reasons}
                valueName="id"
                labelName="title"
                title="Reason"
            />,
            <>
                {rowKeys.length > 1 && (
                    <OxAddRemoveButton
                        hideText
                        remove
                        onClick={(): void =>
                            setRowKeys((prev) => prev.filter((val) => val !== key))
                        }
                    />
                )}
            </>,
            <>
                {index === rowKeys.length - 1 && (
                    <OxAddRemoveButton
                        hideText
                        onClick={(): void => setRowKeys((prev) => [...prev, generateRowKey()])}
                    />
                )}
            </>
        ];
    };

    return (
        <OxForm onFormSubmit={onFormSubmit}>
            <OxThemedInput theme={EInputTheme.Gold}>
                <OxTable
                    columnTitles={columnTitles}
                    rows={rowKeys.map(generateRow)}
                    rowKeys={rowKeys}
                />
            </OxThemedInput>
            <OxThemedInput theme={EInputTheme.BackgroundGold}>
                <Styled.SubmitButton type="submit" icon>
                    Submit
                </Styled.SubmitButton>
            </OxThemedInput>
        </OxForm>
    );
};
