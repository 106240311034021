import React from 'react';
import * as Styled from './OxTextAreaWysiwyg.styled';
import { Editor } from '@tinymce/tinymce-react';
import { TINY_MCE_API_KEY } from 'src/config/constants';

type TProps = {
    className?: string;
    value?: string;
    name: string;
    disabled?: boolean;
    onValueChange?: (a: string, editor: Editor) => void;
};

export const OxTextAreaWysiwyg = ({
    className,
    value,
    name,
    disabled,
    onValueChange
}: TProps): JSX.Element => {
    return (
        <Styled.Container className={className}>
            <Editor
                apiKey={TINY_MCE_API_KEY}
                textareaName={name}
                disabled={disabled}
                value={value}
                onEditorChange={onValueChange}
            />
        </Styled.Container>
    );
};
