import { EFontWeight, EFonts } from 'src/config/enums';
import styled, { css } from 'styled-components';

import { OxImage } from 'src/components/OxImage';
import { OxLink } from 'src/components/OxLink';
import { createTextStyle } from 'src/utils/createTextStyle';
import { fluidSizing } from 'src/utils';

export const Container = styled.div`
    ${fluidSizing([
        { prop: 'margin-left ', values: [25, 50, 60] },
        { prop: 'margin-right ', values: [25, 50, 60] }
    ])}
`;

export const InfoContainer = styled.div`
    grid-area: MyDetailInfo;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    ${fluidSizing([{ prop: 'padding-left', values: [null, 32, 29] }])}
`;

export const Image = styled(OxImage)`
    grid-area: MyDetailImage;
    object-fit: cover;
    ${fluidSizing([
        { prop: 'width', values: [60, 200, 200] },
        { prop: 'height', values: [60, 200, 200] }
    ])}
`;

export const FullName = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;

    ${createTextStyle(EFonts.Hatton, EFontWeight.SemiBold, [22, 27, 27], [34, 34, 34])}
    ${fluidSizing([{ prop: 'margin-top', values: [20, 35, 40] }])}
`;

export const HeaderContainer = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid ${theme.colors.basic.black};
        ${fluidSizing([
            { prop: 'padding-bottom', values: [14, 16, 16] },
            { prop: 'margin-top', values: [16, 17, 21] },
            { prop: 'margin-bottom', values: [26, 23, 24] }
        ])}
    `
);

export const TitleText = styled.div(
    ({ theme }) => css`
        ${createTextStyle(EFonts.Montserrat, EFontWeight.SemiBold, [12, 12, 12], [20, 20, 20])}
        color: ${theme.colors.primary.main};
    `
);

export const EditText = styled(OxLink)(
    ({ theme }) => css`
        ${createTextStyle(EFonts.Montserrat, EFontWeight.SemiBold, [10, 10, 10], [20, 20, 20])}
        color: ${theme.colors.primary.main};
    `
);

export const LabelWithDataContainer = styled.div<{ margin: boolean }>(
    ({ margin }) => css`
        position: relative;
        display: flex;
        flex-direction: row;
        ${margin &&
        css`
            ${fluidSizing([{ prop: 'margin-bottom', values: [8, 8, 8] }])}
        `}
        ${fluidSizing([{ prop: 'height', values: [14, 14, 14] }])}
    `
);

export const Label = styled.div`
    position: absolute;
    ${createTextStyle(EFonts.Montserrat, EFontWeight.Light, [13, 13, 13], [13, 13, 13])}
`;

export const LabelData = styled.div`
    position: absolute;
    left: 25%;
    ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [13, 13, 13], [13, 13, 13])}
`;

export const PatientNotes = styled.div`
    display: block;
    right: 0;
    position: absolute;
    top: 0;

    ${fluidSizing([
        { prop: 'height', values: [20, 20, 20] },
        { prop: 'right', values: [40, 40, 40] },
        { prop: 'width', values: [20, 20, 20] }
    ])};

    svg {
        position: absolute;
        top: 0;
        width: 100%;
    }
`;
