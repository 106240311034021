import { EOxLoginFlowType } from 'src/panel/components/OxLoginFlow';
import { ERoutes } from 'src/config/enums';
import { OxLoggedInRedirect } from 'src/components/OxLoggedInRedirect';
import { OxPanelLogin } from 'src/panel/components/OxPanelLogin';
import React from 'react';
import { RouteComponentProps } from '@reach/router';

export const PanelStaffLoginPage = (props: RouteComponentProps<unknown>): JSX.Element => {
    return (
        <OxLoggedInRedirect
            component={OxPanelLogin}
            to={ERoutes.PanelStaffDashboard}
            variant={EOxLoginFlowType.Staff}
        />
    );
};
