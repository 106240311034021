import styled, { css } from 'styled-components';

import { ESizes } from 'src/config/enums';
import { OxImage } from 'src/components/OxImage';
import { fluidSizing } from 'src/utils';

export const Container = styled(OxImage)(
    ({ theme }) => css`
        display: grid;
        place-items: center;
        ${theme.breakpoints.up('s')} {
            ${fluidSizing([
                {
                    // set height to page height minus navigation
                    prop: 'min-height',
                    values: [ESizes.NavbarHeight, ESizes.NavbarHeight, ESizes.NavbarHeight],
                    // The height of the nav is in vw so we deduct in vw
                    formatter: (val: number): string => `calc(100vh - ${val}vw)`
                }
            ])}
        }
    `
);
