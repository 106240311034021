import React from 'react';
import { RouteComponentProps } from '@reach/router';
import {
    OxCustomerDashboardAppointments,
    AppointmentListType
} from 'src/panel/components/OxPanelCustomerDashboard/components/OxCustomerDashboardAppointments';
import { OxPageHelmet } from 'src/components/OxPageHelmet';

export const OxCustomerDashboardPastAppointments: React.FC<RouteComponentProps> = () => {
    return (
        <>
            <OxPageHelmet title="Past Appointments" />
            <OxCustomerDashboardAppointments
                title="Past Appointments"
                type={AppointmentListType.PAST}
            />
        </>
    );
};
