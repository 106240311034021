import { useContext, useEffect } from 'react';
import { BookingFlowContext } from 'src/context/BookingFlowContext';

type TProps = {
    stepIsActive: boolean;
    currentStage: number;
    stageIndex: number;
    children: JSX.Element;
    onStageStart?: () => void;
};

export const OxBookingStage = ({
    stepIsActive,
    currentStage,
    stageIndex,
    children,
    onStageStart
}: TProps): JSX.Element => {
    const bookingFlowContext = useContext(BookingFlowContext);

    useEffect(() => {
        if (!stepIsActive) return;
        if (bookingFlowContext.activeStepId === 5 && stageIndex === currentStage) {
            onStageStart && onStageStart();
        }
    }, [bookingFlowContext.activeStepId, currentStage, stepIsActive]);

    return children;
};
