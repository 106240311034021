import React from 'react';

import { OxComponentContainer } from 'src/components/OxComponentContainer';
import { OxStaffDashboardAppointmentCalendar } from '../../../OxStaffDashboardAppointmentCalendar';
import { OxStaffDashboardFinance } from '../../../OxStaffDashboardFinance';
import { OxStaffDashboardPatients } from '../../../OxStaffDashboardPatients';
import { OxStaffDashboardFeedback } from '../../../OxStaffDashboardFeedback';
import { OxStaffDashboardMessages } from '../../../OxStaffDashboardMessages';
import { EAppointmentsType } from 'src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardAppointments';
import { EType } from 'src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardAppointmentCalendar/components/OxStaffTimeline';

export const OxPractitionerDashboard = (): JSX.Element => {
    return (
        <>
            <OxComponentContainer marginTop={[40, 40, 40]} marginBottom={[40, 34, 50]}>
                <OxStaffDashboardPatients type={EAppointmentsType.USER} id="patients" />
            </OxComponentContainer>
            <OxStaffDashboardAppointmentCalendar type={EType.USER} id="appointments" />
            <OxComponentContainer marginTop={[45, 45, 45]} marginBottom={[50, 50, 50]}>
                <OxStaffDashboardFinance id="finance" />
            </OxComponentContainer>
            <OxComponentContainer marginBottom={[50, 50, 50]}>
                <OxStaffDashboardFeedback id="feedback" />
            </OxComponentContainer>
            <OxComponentContainer marginBottom={[50, 50, 50]}>
                {/*<OxStaffDashboardMessages />*/}
            </OxComponentContainer>
        </>
    );
};
