import * as Styled from './OxStaffDashboardTreatmentAesthetician.styled';

import React, { useContext, useEffect, useRef, useState } from 'react';

import { navigate, RouteComponentProps, WindowLocation } from '@reach/router';
import { OxStaffDashboardRoot } from 'src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardRoot';
import { OxPageHelmet } from 'src/components/OxPageHelmet';
import { OxStaffDashboardPageSectionHeader } from 'src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardPageSectionHeader';
import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import { getTreatmentAesthetician, postTreatmentAesthetician } from 'src/services/api/treatment';
import { useValidateResponse } from 'src/hooks/useValidateResponse';
import {
    TTreatmentAestheticianNote,
    TTreatmentAestheticianNotes
} from 'src/services/api/api.types';
import { AlertContext, EAlertVariant } from 'src/context/AlertContext';
import { OxForm } from 'src/components/OxForm';
import { formDataToObject } from 'src/utils/formDataToObject';
import { OxTextArea } from 'src/components/OxTextArea';
import { OxButton } from 'src/components/OxButton';

type TState = {
    appointmentId: number;
    readOnly?: boolean;
};

type TProps<RouteComponentProps> = RouteComponentProps & {
    location: WindowLocation & {
        state: TState;
    };
    patientId: number;
};

export const OxStaffDashboardTreatmentAesthetician: React.FC<
    RouteComponentProps<TProps<RouteComponentProps>>
> = ({ location, patientId, practitionerId }) => {
    const { validateResponse } = useValidateResponse();
    const { showAlert } = useContext(AlertContext);
    const { appointmentId, readOnly } = location?.state ?? {
        appointmentId: 0,
        readOnly: false
    };

    console.log(appointmentId, readOnly);

    const formRef = useRef();

    const [existingContent, setExistingContent] = useState<[TTreatmentAestheticianNote]>();

    const onTreatmentAestheticianSubmit = async (): Promise<void> => {
        const formData = new FormData(formRef.current);
        const dataObj = formDataToObject(formData) as TTreatmentAestheticianNotes;

        dataObj.appointmentId = appointmentId;
        dataObj.patientId = patientId;
        dataObj.practitionerId = practitionerId;
        const response = await postTreatmentAesthetician(dataObj);

        if (response && response['error']) {
            showAlert({
                type: EAlertVariant.Error,
                header: 'Error',
                title: 'Something went wrong',
                message: `Unable to create or update Facial Aesthetician Treatment note: ${response['error']}`
            });
            return;
        } else {
            showAlert({
                type: EAlertVariant.Success,
                header: 'Success',
                title: 'Facial Aesthetician Treatment Notes submitted successfully'
            });
        }
    };

    useEffect(() => {
        let unmounted = false;

        getTreatmentAesthetician({ appointmentId, patientId })
            .then(validateResponse)
            .then((data) => {
                if (!unmounted) {
                    data.sort(function (
                        a: TTreatmentAestheticianNote,
                        b: TTreatmentAestheticianNote
                    ) {
                        // Turn your strings into dates, and then subtract them
                        // to get a value that is either negative, positive, or zero.
                        return new Date(b.createdAt) - new Date(a.createdAt);
                    });
                    setExistingContent(data[0]);
                }
            })
            .catch((e) => console.error(e.error.message));

        return (): void => {
            unmounted = true;
        };
    }, []);

    return (
        <OxStaffDashboardRoot location={location}>
            <OxPageHelmet title="Facial Aesthetician Treatment Notes" />
            <Styled.BackButton
                onClick={(): void => {
                    navigate(-1);
                }}
            />
            <Styled.Container>
                <OxStaffDashboardPageSectionHeader>
                    Facial Aesthetician Treatment Notes
                </OxStaffDashboardPageSectionHeader>
                <OxForm onFormSubmit={onTreatmentAestheticianSubmit} ref={formRef}>
                    <OxThemedInput theme={EInputTheme.BackgroundWhite}>
                        <OxTextArea
                            name="content"
                            placeholder="Enter content here"
                            value={existingContent?.content}
                            disabled={readOnly}
                            onValueChange={(val: string) => {
                                setExistingContent((prevState) => {
                                    return {
                                        ...prevState,
                                        content: val
                                    };
                                });
                                return val;
                            }}
                        />
                    </OxThemedInput>
                    <div>{readOnly}</div>
                    {!readOnly && (
                        <OxThemedInput theme={EInputTheme.BackgroundGoldAlternative}>
                            <OxButton icon type="submit">
                                Submit Facial Aesthetician Treatment Notes
                            </OxButton>
                        </OxThemedInput>
                    )}
                </OxForm>
            </Styled.Container>
        </OxStaffDashboardRoot>
    );
};
